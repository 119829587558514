import './PieChart.css';

import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { Pie, G2 } from '@ant-design/plots';
import { Card, Select } from 'antd';

const { Option } = Select;

function PieChart(props) {
  const G = G2.getEngine('canvas');
  const context = useOutletContext();
  const [selectedPolicy, setSelectedPolicy] = useState('all-items');

  let availablePolicies = context?.selectedFramework?.policies.map(pol => context.policies.find(p => p.id == pol)).filter(p => p != undefined);
  availablePolicies = availablePolicies ? availablePolicies : [];
  let currentIsClient = context?.user.roles.indexOf('client') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;
  if(currentIsClient){
    availablePolicies = availablePolicies?.filter(f => f.owner?.id == context.user.id || 
      f.approvers?.filter(a => a.id == context.user.id)?.length > 0 || 
      f.auditors?.filter(a => a.id == context.user.id)?.length > 0 || 
      f.reviewers?.filter(a => a.id == context.user.id)?.length > 0 || 
      f.testers?.filter(a => a.id == context.user.id)?.length > 0)
      availablePolicies = availablePolicies?.filter(f => f.template == false)
  }
  if(currentIsBusiness){
    let clients = context.clients?.filter(c => c.business == context.user.id);
    if(clients){
      availablePolicies = availablePolicies.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == context.user.id);
    }
  }

  useEffect(() => {
    setSelectedPolicy('all-items');     
  }, [context?.selectedFramework]);

  const onPolicyChange = (value) => {
    if(value === 'all-items'){
      setSelectedPolicy(value);      
    }
    else{
      setSelectedPolicy(context.policies.find(p => p.id == value));
    }
  };

  let id = selectedPolicy?.id ? selectedPolicy.id : selectedPolicy;
  let data = [];
  let policyLoaded = null;
  let notStartedAmount = [];
  let startedAmount = [];
  let issueAmount = [];
  let exceptionAmount = [];
  let noExceptionAmount = [];
  let noStatusAmount = [];

  if(id == 'all-items'){  

    availablePolicies?.forEach((policyLoaded,index) => {
      let internalControlsLoaded = policyLoaded?.internalControls?.map(currentIc => context.internalControls.find(ic => ic.id == currentIc));
      notStartedAmount = [...notStartedAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Not Started")]; 
      startedAmount = [...startedAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Started")];
      issueAmount = [...issueAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Issue")];
      exceptionAmount = [...exceptionAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Exception noted")];
      noExceptionAmount = [...noExceptionAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "No exception noted")];
      noStatusAmount = [...noStatusAmount, ...internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "")];
    });

    policyLoaded = {
      label: "All Policies"
    }
  }
  else{
    policyLoaded = context.policies.find(p => p.id == id);
    let internalControlsLoaded = policyLoaded?.internalControls?.map(currentIc => context.internalControls.find(ic => ic.id == currentIc));
    
    notStartedAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Not Started");
    startedAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Started");
    issueAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Issue");
    exceptionAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "Exception noted");
    noExceptionAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "No exception noted");
    noStatusAmount = internalControlsLoaded?.filter(ic => ic?.testingStatus && ic.testingStatus.length > 0 && ic.testingStatus == "");
  }

  const getStatusColor = (testingStatus) => {
    if(testingStatus === "Not Started") return "#A9A9A9";
    if(testingStatus === "Started") return "#096DD9";
    if(testingStatus === "Issue") return "#FEEA97";
    if(testingStatus === "Exception noted") return "#CF1322";
    if(testingStatus === "No exception noted") return "#389E0D";
    return "#F0B24F";
  };
  
  data = context.selectedFramework && context.selectedFramework.policies ? [
    {type:"Not Started", amount:notStartedAmount.length, items: JSON.stringify(notStartedAmount), policies: notStartedAmount.map(i => " "+i.label), valueColor: getStatusColor("Not Started")},
    {type:"Started",amount:startedAmount.length, items: JSON.stringify(startedAmount), policies: startedAmount.map(i => " "+i.label), valueColor: getStatusColor("Started")},
    {type:"Issue",amount:issueAmount.length, items: JSON.stringify(issueAmount), policies: issueAmount.map(i => " "+i.label), valueColor: getStatusColor("Issue")},
    {type:"Exception noted",amount:exceptionAmount.length, items: JSON.stringify(exceptionAmount), policies: exceptionAmount.map(i => " "+i.label), valueColor: getStatusColor("Exception noted")},
    {type:"No exception noted",amount:noExceptionAmount.length, items: JSON.stringify(noExceptionAmount), policies: noExceptionAmount.map(i => " "+i.label), valueColor: getStatusColor("No exception noted")},
    {type:"No status",amount:noStatusAmount.length, items: JSON.stringify(noStatusAmount), policies: noStatusAmount.map(i => " "+i.label), valueColor: getStatusColor("No status")}
  ]:[];
  
   
  const config = {
    appendPadding: 8,
    data,
    angleField: 'amount',
    colorField: 'valueColor',
    radius: 0.75,
    legend: false,
    tooltip: {
      showMarkers: false,
      fields: ['amount','policies'],
    },
    pieStyle: ({ valueColor }) => {
      return {
        fill: valueColor,
      };
    },
    label: {
      type: 'spider',
      labelHeight: 60,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        const parsedData = JSON.parse(data.items);
        mappingData.color = getStatusColor(data.type);
        group.addShape({
          type: 'circle',
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: 'text',
          attrs: {
            x: 10,
            y: 7,
            text: `${data.type} (${data.amount})`,
            fill: mappingData.color,
          },
        });
        parsedData.forEach((i,ix) => {
          if(ix < 12){
            group.addShape({
              type: 'text',
              attrs: {
                x: parseInt(ix/4)*60+10,
                y: (ix%4)*12+24,
                text: `${i.label}`,
                fill: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 700
              },
            });
          } 
          else if(ix >= 12 && ix <= 15){
            group.addShape({
              type: 'text',
              attrs: {
                x: parseInt(ix/4)*60+10,
                y: (ix%4)*12+20,
                text: `...`,
                fill: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 700,
              },
            });
          }         
        });        
        return group;
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  return <div>
    {context.selectedFramework && <Card title="Testing Status" className='chart-card'>
    <Select onChange={onPolicyChange} value={selectedPolicy} style={{ width: 200, marginBottom: 16 }}>
        <Option key={'all-items'} value={'all-items'}>
          All Policies
        </Option>
        {availablePolicies.map((policy) => (
          <Option key={policy.id} value={policy.id}>
            {policy.label}
          </Option>
        ))}
      </Select>
      {policyLoaded && <div title={policyLoaded.label} style={{height:400, margin:32}}>
        <div style={{fontWeight:"bold", textAlign:'left', fontSize:32}}>{policyLoaded.label}</div>
        <Pie {...config}/>
      </div>}
      </Card>}
    </div>
};

export default PieChart;
