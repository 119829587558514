import { Form, Input, Modal, Select, Alert } from "antd";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import UserAllocation from "../../users/allocation/UserAllocation";
import UserPool from "../../../UserPool";
import LoadingSpinner from "../../LoadingSpinner";

function RiskEditor(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState(props.item);
  const [newUser,setNewUser] = useState(null);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [allImpacts, setAllImpacts] = useState(["High","Medium","Low"]);
  const [allStatuses, setAllStatuses] = useState(["Draft", "Reviewed", "Final", "Issue", "Up for review"]);
  const [allPriorities, setAllPriorities] = useState(["High","Medium","Low"]);
  const [allReviewFrequencies, setAllReviewFrequencies] = useState(["Yearly", "Quaterly", "Weekly", "Daily"]);
  const [allLikelihoods, setAllLikelihoods] = useState(["High","Medium","Low"]);
  const [allResponseTypes, setAllResponseType] = useState(["Accept" , "Transfer" , "Mitigate" , "Avoid"]);
  const [allRiskTolerance, setAllRiskTolerance] = useState(["Very High" , "High" , "Medium" , "Low" , "Very Low"]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
    let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
    setIsAdmin(currentIsAdmin);
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = async (value) => {
    if (typeof props?.onClose == "function") {
      await props?.onClose(value);
    }
    if(form?.resetFields){
      form.resetFields();
    }
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    return pass;
  }

  const onSubmit = async (event) => {
    try{
      setLoading(true);
      let newItem = {...event, ...item};
      if(newUser){
        newUser.id = newUser.email;
        UserPool.signUp(newUser.email, newUser.password, [], null, async (err, data) => {
          setErrorMessage(null);
          if (err) {
              console.error(JSON.stringify(err.message, null, 2));
              if (err.message) {
                  setErrorMessage(err.message);
              }
          }
          if(data) {
            setNewUser({...newUser,userSub: data.userSub});
            if (item){
              item.owner = {...newUser,userSub: data.userSub};
            }
  
            newItem = {...event,owner: {...newUser,userSub: data.userSub}, id: item?.id};
            if(!validate(newItem)){            
              setErrorMessage("Please fill out all required fields");
              setLoading(false);
              return;
            }          
            await onClose(newItem);
          }
        })
      }
      else{
        if(!validate(newItem)){            
            setErrorMessage("Please fill out all required fields");
            setLoading(false);
            return;
        }
        await onClose(newItem);      
      }
    }
    finally{
      setLoading(false);
    }  
}

  const onFinishedFailed = (value) => {};

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose()
        }
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          onSubmit(values,form);
        });
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} Risk {props.item?.template != null ? props.item.template == true ? "Template" : "Instance" : isAdmin ? "Template" : "Instance"}</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
  {loading && <LoadingSpinner/>}
  {!loading && <Form
        form={form}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      
      <Form.Item
          label="Name"
          name="label"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input placeholder="Name..." onChange={(event)=>{
            if(item){
              setItem({...item,label:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <Input placeholder="Description..." onChange={(event)=>{
            if(item){
              setItem({...item,description:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Version Number"
          name="versionNumber"
          rules={[
            {
              required: true,
              message: "Version Number is required",
            },
          ]}
        >
          <Input placeholder="Version Number..." onChange={(event)=>{
            if(item){
              setItem({...item,versionNumber:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          rules={[
            {
              required: true,
              message: "Priority is required",
            },
          ]}
        >
          <Select
              value={props.model?.priority}
              onChange={(value) => {
                if(item){
                  setItem({...item,priority:value});
                }
              }}
              key={props.model?.priority}
            >
              {allPriorities?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Likelihood"
          name="likelihood"
          rules={[
            {
              required: true,
              message: "Likelihood is required",
            },
          ]}
        >
          <Select
              value={props.model?.likelihood}
              onChange={(value) => {
                if(item){
                  setItem({...item,likelihood:value});
                }
              }}
              key={props.model?.likelihood}
            >
              {allLikelihoods?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Impact"
          name="impact"
          rules={[
            {
              required: true,
              message: "Impact is required",
            },
          ]}
        >
          <Select
              value={props.model?.impact}
              onChange={(value) => {
                if(item){
                  setItem({...item,impact:value});
                }
              }}
              key={props.model?.impact}
            >
              {allImpacts?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Response Type"
          name="responseType"
          rules={[
            {
              required: true,
              message: "Response Type is required",
            },
          ]}
        >
          <Select
              value={props.model?.responseType}
              onChange={(value) => {
                if(item){
                  setItem({...item,responseType:value});
                }
              }}
              key={props.model?.responseType}
            >
              {allResponseTypes?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          
          <Form.Item
            label="Response Description"
            name="responseDescription"
            rules={[
              {
                required: true,
                message: "Response Description is required",
              },
            ]}
          >
            <Input placeholder="Response Description..." onChange={(event)=>{
              if(item){
                setItem({...item,responseDescription:event.currentTarget.value});
              }
            }}/>
          </Form.Item>
        
        <Form.Item
          label="Risk Tolerance"
          name="riskTolerance"
          rules={[
            {
              required: true,
              message: "Risk Tolerance is required",
            },
          ]}
        >
          <Select
              value={props.model?.riskTolerance}
              onChange={(value) => {
                if(item){
                  setItem({...item,riskTolerance:value});
                }
              }}
              key={props.model?.riskTolerance}
            >
              {allRiskTolerance?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Status is required",
            },
          ]}
        >
          <Select
              value={props.model?.status}
              onChange={(value) => {
                if(item){
                  setItem({...item,status:value});
                }
              }}
              key={props.model?.status}
            >
              {allStatuses?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Review Frequency"
          name="reviewFrequency"
          rules={[
            {
              required: true,
              message: "Review Frequency is required",
            },
          ]}
        >
          <Select
              value={props.model?.reviewFrequency}
              onChange={(value) => {
                if(item){
                  setItem({...item,reviewFrequency:value});
                }
              }}
              key={props.model?.reviewFrequency}
            >
              {allReviewFrequencies?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>

        <Form.Item label="Users">
          <UserAllocation model={item} config={{multiple:true}}/>
        </Form.Item>

        <Form.Item name="owner" label="Owner">
        <UserAllocation model={item?.owner} disabled={item?.id && !isAdmin} change={(newValue)=>{
          setItem({...item,owner:{id:newValue.id}})
          }}/>
          </Form.Item> 
          
          <Form.Item
            label="Body"
            name="body"
            rules={[
              {
                required: true,
                message: "Body is required",
              },
            ]}
          >
            <Input.TextArea placeholder="Body..." onChange={(event)=>{
              if(item){
                setItem({...item,body:event.currentTarget.value});
              }
            }}/>
          </Form.Item>
      </Form>}
      
    </Modal>
  );
}

export default RiskEditor;
