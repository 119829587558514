
import {v4 as uuid} from "uuid";
import { RedoOutlined, UserOutlined } from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Tag,
  Modal,
  Popover
} from "antd";

import React, { useEffect, useState, useCallback, useRef } from "react";

import "./FrameworkCrud.css";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { handleError } from "../../utils/general.util";
import LoadingSpinner from "../../LoadingSpinner";
import FrameworkEditor from "./editor/FrameworkEditor";
import UserDisplay from "../../users/display/UserDisplay";
import PolicyDisplay from "../../policies/PolicyDisplay";
import RiskDisplay from "../../risks/RiskDisplay";
import moment from "moment";
import { updateEntity } from "../../utils/context-util";
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HistoryDisplay from '../../history/display/HistoryDisplay';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
const type = 'DraggableBodyRow';

function FrameworkCrud(props) {
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const context = useOutletContext();
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortChanged, setSortChanged] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  let currentUser = JSON.parse(localStorage.getItem("user"));
  let loadingItems = false;

  useEffect(() => {
    if (context.accessToken) {
      axios.defaults.headers.common["Authorization"] = context.accessToken;
      if (!items && !loadingItems) {
        loadItems();
      }
    }
  });

  const loadItems = async (currentContext = context) => {
    if (currentContext.backendURL) {
      setLoading(true);
      loadingItems = true;
      try {
        let frameworks = currentContext.frameworks;
        let user = currentContext.user;

        let currentIsAdmin = user.roles.indexOf('admin') >= 0;
        let currentIsBusiness = user.roles.indexOf('business') >= 0;
        let currentIsClient = user.roles.indexOf('client') >= 0;
        setIsAdmin(currentIsAdmin);

        if(currentIsClient){
          frameworks = frameworks?.filter(f => f.owner?.id == user.id || 
            f.approvers?.filter(a => a.id == user.id)?.length > 0 || 
            f.auditors?.filter(a => a.id == user.id)?.length > 0 || 
            f.reviewers?.filter(a => a.id == user.id)?.length > 0 || 
            f.testers?.filter(a => a.id == user.id)?.length > 0)
          frameworks = frameworks?.filter(f => f.template == false)
        }

        if(currentIsBusiness){
          let clients = currentContext.clients.filter(c => c.business == currentContext.user.id);
          frameworks = frameworks.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == user.id);
        }
        frameworks = frameworks.map(i => {
          return {...i,key:i.id}
        })
        frameworks = frameworks.sort((a, b) => {
          if(a.index == null || b.index == null){
            return 0;
          }
          return a.index - b.index;
        })

        setItems(frameworks);;
      } catch (err) {
        handleError("Frameworks", err, "loaded", setErrorMessage);
      }
      finally{
        loadingItems = false;
        setLoading(false);
      }
    }
  };

  const adminColumns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Type",
      dataIndex: "template",
      key: "template",
      render: (item) => {
        if(item){
          return <Tag color="purple">Template</Tag>
        }
        return <Tag color="orange">Instance</Tag>
      },
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (item) => (
        moment(item).format("Do MMMM YYYY")
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (item) => (
        moment(item).format("Do MMMM YYYY")
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => (
        <UserDisplay model={item} config={{ multiple: false }} />
      )
    },
    {
      title: "Policies",
      dataIndex: "policies",
      key: "policies",
      render: (items) => (
        items?.map(item => {
          let loadedItem = context?.policies?.find(r => r.id == item);
          if(!loadedItem){
            return null;
          }
          return <Popover content={<PolicyDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Policy"} trigger="click">
              <Tag color={context?.policies?.find(p => p.id == item)?.template == true ? "purple" : "orange"}>{context?.policies?.find(p => p.id == item)?.label}</Tag>
            </Popover>
        })
      )
    },
    {
      title: "Risks",
      dataIndex: "risks",
      key: "risks",
      render: (items) => (
        items?.map(item => {
          let loadedItem = context?.risks?.find(r => r.id == item);
          if(!loadedItem){
            return null;
          }
          return <Popover content={<RiskDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Risk"} trigger="click">
              <Tag color={context?.risks?.find(p => p.id == item)?.template == true ? "purple" : "orange"}>{context?.risks?.find(r => r.id == item)?.label}</Tag>
            </Popover>
        })
      )
    },
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (item) => (
        moment(item).format("Do MMMM YYYY")
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (item) => (
        moment(item).format("Do MMMM YYYY")
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => (
        <UserDisplay model={item} config={{ multiple: false }} />
      )
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{multiple:true}}/>
      ),
    },
    {
      title: "Policies",
      dataIndex: "policies",
      key: "policies",
      render: (items) => (
        items?.map(item => {
          let loadedItem = context?.policies?.find(r => r.id == item);
          if(!loadedItem){
            return null;
          }
          return <Popover content={<PolicyDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Policy"} trigger="click">
              <Tag color={context?.policies?.find(p => p.id == item)?.template == true ? "purple" : "orange"}>{context?.policies?.find(p => p.id == item)?.label}</Tag>
            </Popover>
        })
      )
    },
    {
      title: "Risks",
      dataIndex: "risks",
      key: "risks",
      render: (items) => (
        items?.map(item => {
          let loadedItem = context?.risks?.find(r => r.id == item);
          if(!loadedItem){
            return null;
          }
          return <Popover content={<RiskDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Risk"} trigger="click">
              <Tag color={context?.risks?.find(p => p.id == item)?.template == true ? "purple" : "orange"}>{context?.risks?.find(r => r.id == item)?.label}</Tag>
            </Popover>
        })
      )
    },
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  
  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          cursor: 'move',
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const saveSort = async () => {    
    setLoading(true);
    try{
      for(let index in items){
        let item = items[index];
        await axios.post(`${context.backendURL}/control-framework`, item);
      }
      setSortChanged(false);    
    }
    finally{
      await updateEntity("control-framework", "controlFrameworks", context, props.setOutletContext);
      setLoading(false);
    } 
  }

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
        const dragRow = items[dragIndex];
        let newData = update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }); 
        
        for(let index in newData){
          let nData = newData[index];
          nData.index = index;
        }
        setItems(newData);
        await updateEntity("control-framework", "controlFrameworks", context, props.setOutletContext);
        setSortChanged(true);     
    },
    [items],
  );

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            onModify(item);
          }}
        >
          <BsPencilSquare style={{ marginRight: ".25em" }} /> Update
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            onRemove(item);
          }}
        >
          <MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  const onSearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items?.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };
  
  const onClose = async (value) => {
    let currentIsClient = context.user.roles.indexOf('client') >= 0;
    if (value) {
      setLoading(true);
      let newItems = [...items];
      
      if (items?.length >= 0) {
        var index = newItems?.findIndex((item) => value?.id === item?.id);
        try {
          if (!value.id) {
            value.id = uuid();
            value.owner = {
              id: context.user.id
            }
            if(currentIsClient){
              value.template = false;
            }
          }
          await axios.post(`${context.backendURL}/control-framework`, value);
          if (index > -1) {
            const item = newItems[index];
            newItems.splice(index, 1, { ...item, ...value });
          } else newItems?.push(value);

          setItems(newItems);
          setModalVisible(false);
          setSelected(null);
          let newContext = await updateEntity("control-framework", "frameworks", context, props.setOutletContext);
          loadItems(newContext);
          message.success("Item saved!");
        } catch (error) {
          handleError("Framework", error, "saved", setErrorMessage);
        }
        finally{
          setLoading(false);
        }
      }
    } else {
      //on a cancel
      setModalVisible(false);
      setSelected(null);
    }
  };

  const onRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/control-framework`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("Task",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("control-framework", "frameworks", context, props.setOutletContext);
        loadItems(newContext);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div style={{ height: "3em" }}>
        <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
          <Space align="center">
            <Input
              size="medium"
              placeholder="Search...."
              style={{ width: "25vw" }}
              onChange={(e) => {
                console.log(e);
                setSearchVal(e.target.value);
              }}
              onKeyDown={(e) => onSearch(e)}
            />
          </Space>
        </div>
        <div style={{ float: "right", marginTop: ".15em" }}>
          <Button
            type="link"
            className="control"
            size="large"
            icon={
              <AiOutlinePlus
                style={{ fontSize: "1.5em" }}
                onClick={() => onModify(null)}
              />
            }
          />
          <Button
            type="link"
            className="control"
            size="large"
            icon={<AiOutlineHistory style={{ fontSize: "1.5em" }}  
            onClick={()=>{
              setShowHistory(true);
            }}/>}
          />
          <Button
        onClick={async () => {
          setLoading(true);
          try{
            let newContext = await updateEntity("control-framework", "frameworks", context, props.setOutletContext);
            loadItems(newContext);
          }
          finally{
            setLoading(false);
          }
        }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
        </div>
      </div>
      {errorMessage && (
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      {items && !loading ? (
        <DndProvider backend={HTML5Backend}>
        {sortChanged && <Button 
                style={{marginBottom:16, float:"left"}}
                onClick={async ()=>{
                  await saveSort();
                }}>Save Sort Order</Button>}
        <Table 
          dataSource={items} 
          components={components}
          onRow={(_, index) => {
            _.index = index;                
            const attr = {
              index,
              moveRow,
            };
            return attr;
          }}
          columns={isAdmin ? adminColumns : columns} />
      </DndProvider>
      ) : (
        <LoadingSpinner />
      )}
      <FrameworkEditor
        isAdmin={isAdmin}
        isVisible={modalVisible}
        onClose={onClose}
        errorMessage={errorMessage}
        item={selected}
        setItem={setSelected}
      />
      <Modal 
        title={"Framework History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false)
          }
        }}      
      >
        <HistoryDisplay context={context} group={"control framework"} />
      </Modal>
    </>
  );
}

export default FrameworkCrud;
