import './MixGraph.css';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Mix } from '@ant-design/plots';

import { each } from '@antv/util';
import { useOutletContext } from "react-router-dom";
import { Card, Select } from 'antd';

function MixGraph(props) {
  const context = useOutletContext();
  const [selectedUser, setSelectedUser] = useState(null);
  const { Option } = Select;

  let availableUsers = context?.users;
  let currentIsClient = context?.user.roles.indexOf('client') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;
  if(currentIsClient){
    availableUsers = availableUsers.filter(u => (u.company != null && u.company != "") || u.id == context.user.id).filter(u => u.company == context.user.company)
  }
  if(currentIsBusiness){
    let clients = context.clients.filter(c => c.business == context.user.id);
    availableUsers = availableUsers.filter(u => clients.map(c => c.userAccount.id).includes(u.id) || u.id == context.user.id);
  }

  useEffect(() => {
    if (!selectedUser && availableUsers && availableUsers.length > 0) {
      setSelectedUser(availableUsers[0].id); 
    }
  }, [availableUsers]);

  const onUserChange = (value) => {
    setSelectedUser(value);
  };

  const processData = () => {
    if (!selectedUser || !context.policies) return [];

    const summary = {
      owner: 0,
      auditor: 0,
      approver: 0,
      reviewer: 0,
      tester: 0,
    };

    let owned = [];
    let audited = [];
    let approved = [];
    let reviewed = [];
    let tested = [];

    context.selectedFramework?.policies.forEach((policy) => {
      let polId = policy.id ? policy.id : policy;
      let loadedPolicy = context.policies.find(p => p.id == polId);
      loadedPolicy?.internalControls.forEach((control) => {
        let id = control.id ? control.id : control;
        let loadedControl = context.internalControls.find(ic => ic.id == id);
        if(loadedControl){
          if (loadedControl.owner?.id === selectedUser) {
            summary.owner++;
            owned.push(" "+loadedControl.label);
          }
          if (loadedControl.auditors?.some((auditor) => auditor.id === selectedUser)) {
            summary.auditor++;
            audited.push(" "+loadedControl.label);
          }
          if (loadedControl.approvers?.some((approver) => approver.id === selectedUser)) {
            summary.approver++;
            approved.push(" "+loadedControl.label);
          }
          if (loadedControl.reviewers?.some((reviewer) => reviewer.id === selectedUser)) {
            summary.reviewer++;
            reviewed.push(" "+loadedControl.label);
          } 
          if (loadedControl.testers?.some((tester) => tester.id === selectedUser)) {
            summary.tester++;
            tested.push(" "+loadedControl.label);
          }
        }
      });
    });

    return [
      {
        userType: 'Owner',
        type: selectedUser,
        amount: summary.owner,
        controls: owned
      },
      {
        userType: 'Approver',
        type: selectedUser,
        amount: summary.approver,
        controls: approved
      },
      {
        userType: 'Auditor',
        type: selectedUser,
        amount: summary.auditor,
        controls: audited
      },
      {
        userType: 'Reviewer',
        type: selectedUser,
        amount: summary.reviewer,
        controls: reviewed
      },
      {
        userType: 'Tester',
        type: selectedUser,
        amount: summary.tester,
        controls: tested
      },
    ];
  };

  const datas = [processData()];

  const plots = [];
  const total = datas.length;
  each(datas, (data, idx) => {
    const startX = idx / total;
    plots.push({
      type: 'column',
      region: {
        start: {
          x: startX,  
          y: 0,
        },
        end: {
          x: (idx + 1) / total,
          y: 1,
        },
      },
      options: {
        data,
        xField: 'userType',
        yField: 'amount',
        seriesField: 'userType',
        isGroup: true,
        meta: {
          value: {
            sync: true,
          },
        },
        xAxis: {
          label: {
            autoRotate: true,
          },
        },
        yAxis:
          idx === 0
            ? {
                tickCount: 10,
              }
            : {
                label: {
                  formatter: (v) => '',
                },
                tickCount: 10,
              },
        tooltip: {
          showMarkers: false,
          fields: ['amount', 'controls'],
        },
        minColumnWidth: 24,
        appendPadding: [20, 0],
        annotations: [
          {
            type: 'text',
            content: data.type,
            position: ['50%', '0%'],
            offsetY: -15,
            style: {
              textAlign: 'center',
            },
          },
        ],
      },
    });
  });
  const config = {
    plots,
    syncViewPadding: true,
    tooltip: true,
    legend: {},
  };
  return <div>
    {context.selectedFramework && <Card title="Internal Control User Allocation" className='chart-card'>
      <Select onChange={onUserChange} value={selectedUser} style={{ width: 200, marginBottom: 16 }}>
        {availableUsers.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.name}
          </Option>
        ))}
      </Select>
          <Mix {...config} />
    </Card>}
  </div>
};

export default MixGraph;