import {
  Tooltip,
  Avatar,
} from "antd";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./UserDisplay.css";

const UserDisplay = (props) => {
  let context = useOutletContext();
  if(!context){
    context = props.context;
  }
  const [allUsers, setAllUsers] = useState(context?.users);

  const userAvatar = () => {
    let out = props.model?.id ? 
      <Tooltip title={allUsers?.find(u => u.id == props.model.id)?.alias} placement="top">
            <Avatar onClick={()=>{
        }} style={{ backgroundColor: `#111111` }} src={allUsers?.find(u => u.id == props.model.id)?.imageURL} />
        </Tooltip>: 
       null      
        return out;
  }

  const userAvatars = (users) => {
    let out = (
      <div
        onClick={() => {
        }}
      >
        <Avatar.Group maxCount={2} >
          {users?.map((us) => (
            <Tooltip title={`${allUsers.find(u => u.id == us.id)?.alias} : ${us.type}`} placement="top">
              <Avatar onClick={()=>{
              }} style={{ backgroundColor: `#111111` }} src={allUsers.find(u => u.id == us.id)?.imageURL} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    );
    return out;
  };
  let users = [];
  if (props.model?.approvers) {
    users = [
      ...users,
      ...props.model.approvers?.map((u) => {
        return { ...u, type: "Approver" };
      }),
    ];
  }
  if (props.model?.auditors) {
    users = [
      ...users,
      ...props.model.auditors?.map((u) => {
        return { ...u, type: "Auditor" };
      }),
    ];
  }
  if (props.model?.reviewers) {
    users = [
      ...users,
      ...props.model.reviewers?.map((u) => {
        return { ...u, type: "Reviewer" };
      }),
    ];
  }
  if (props.model?.testers) {
    users = [
      ...users,
      ...props.model.testers?.map((u) => {
        return { ...u, type: "Tester" };
      }),
    ];
  }

  return (
    <div style={{...props.style}}>
      {props.config?.multiple ? userAvatars(users) : userAvatar()}     
    </div>
  );
};
export default UserDisplay;
