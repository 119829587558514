import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Select, Upload, Alert, Card, Checkbox, Tabs } from "antd";
import UserAllocation from "../users/allocation/UserAllocation";
import LoadingSpinner from '../LoadingSpinner';
import ImgCrop from 'antd-img-crop';

function BusinessDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [newUser,setNewUser] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [activeTab, setActiveTab] = useState("existing");    
  const [editing, setEditing] = useState(false);

  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
                <Form.Item
        label="Name"
        name="label"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        >
        <Input disabled={!editing} placeholder="Name..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Address is required",
            },
          ]}
        >
          <Input disabled={!editing} placeholder="Address..." onChange={(event)=>{
            if(item){
              setItem({...item,address:event.currentTarget.value});
            }
          }}
          />
        </Form.Item>
        <Form.Item
          label="Contact Person Name"
          name="contactPersonName"
          rules={[
            {
              required: true,
              message: "Contact Person Name is required",
            },
          ]}
        >
          <Input disabled={!editing} placeholder="Contact Person Name..." onChange={(event)=>{
            if(item){
                setItem({...item,contactPersonName:event.currentTarget.value});
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Contact Person Email"
          name="contactPersonEmail"
          rules={[
            {
              required: true,
              message: "Contact Person Email is required",
            },
          ]}
        >
          <Input placeholder="Contact Person  Email..." onChange={(event)=>{
            if(item){
              setItem({...item,contactPersonEmail:event.currentTarget.value});
            }
          }}
          disabled={!editing}/>
        </Form.Item>
        <Form.Item
          label="Contact Person Phone Number"
          name="contactPersonPhoneNumber"
          rules={[
            {
              required: true,
              message: "Contact Person Phone Number is required",
            },
          ]}
        >
          <Input placeholder="Contact Person Phone Number..." onChange={(event)=>{
            if(item){
              setItem({...item,contactPersonPhoneNumber:event.currentTarget.value});
            }
          }}
          disabled={!editing}/>
        </Form.Item>

        <Form.Item name="userAccount" label="User Account">
          {!item?.id ? 
          <Tabs activeKey={activeTab} onChange={e=>{
            setActiveTab(e);
            if(e == 'existing'){
              setNewUser(null);
            }
          }}>
            <Tabs.TabPane tab="Existing" key="existing">
            <UserAllocation ownerLabel={"User Account"} model={item?.userAccount} disabled={!editing} change={(newValue)=>{
          setItem({...item,userAccount:{id:newValue.id}})
          }}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="New" key="new">
              <Card title="User Account">
              <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      <Form.Item
        label="Alias"
        name="alias"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input placeholder="Alias..." onChange={(event)=>{
          if(newUser){
            setNewUser({...newUser,alias:event.currentTarget.value});
          }
          else{
            setNewUser({alias:event.currentTarget.value});
          }
        }}
        disabled={!editing}/>
      </Form.Item> 
      {!item?.id && <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input.Password placeholder="" onChange={(event)=>{
          if(newUser){
            setNewUser({...newUser,password:event.currentTarget.value});
          }
          else{
            setNewUser({password:event.currentTarget.value});
          }
        }}
        disabled={!editing}/>
      </Form.Item>} 
        <Form.Item
          label="Image"
          name="imageURL"          
        >
          <ImgCrop rotate>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          disabled={!editing}
          
          showUploadList={false}
          fileList={fileList}
          headers={{'Authorization':context.accessToken}}
          customRequest={req => {
            const reader = new FileReader();
            reader.readAsBinaryString(req.file);
            reader.onload = function() {
                const base64 = btoa(reader.result);
                // Force re-render of this component
                let imageURL = `data:${req.file.type};base64, ${base64}`;                
                if(newUser){
                  setNewUser({...newUser,imageURL});
                }
                else{
                  setNewUser({imageURL});
                }
                req.onSuccess(imageURL);
            };
          }}
        >
          {newUser?.imageURL ? <img src={newUser.imageURL} alt="avatar" style={{ width: '100%' }} /> : "Upload an image"}
        </Upload>
        </ImgCrop>
        </Form.Item>  
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input 
          readOnly={item?.id}
          placeholder="Email..." onChange={(event)=>{             
            if(newUser){
              setNewUser({...newUser,email:event.currentTarget.value});
            }
            else{
              setNewUser({email:event.currentTarget.value});
            }
          }}
          disabled={!editing}/>
        </Form.Item>  
      <Form.Item
        label="Active"
        name="active"       
      >
        <Checkbox checked={newUser?.active} onChange={(event)=>{
            if(newUser){
              setNewUser({...newUser,active:event.target.checked});
            }
            else{
              setNewUser({active:event.target.checked});
            }
        }}
        disabled={!editing}/>
      </Form.Item>  
        <Form.Item label="Roles" name="roles"
          rules={[
            {
              required: true,
              message: "Roles are required",
            },
          ]}>
          <Select mode="multiple" placeholder="Please select roles" onChange={(value)=>{
                if(newUser){
                  setNewUser({...newUser,roles:value});
                }
                else{
                  setNewUser({roles:value});
                }
              }}
              disabled={!editing}>
            {context?.roles?.filter(r => r.id == "business").map((role) => {
              return (
                <Select.Option value={role.id}>{role.label}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>     
      </Form>

              </Card>
            
            </Tabs.TabPane>
          </Tabs>
        :
        <UserAllocation ownerLabel={"User Account"} model={item?.userAccount} disabled={!editing} 
          change={(newValue)=>{
            setItem({...item,userAccount:{id:newValue.id}})
          }}          
          filter={(users) => {
            return users.filter(u => u.roles.includes('business'));
          }}
        />}
        </Form.Item>
      </Form>}
    </div>

  );
}

export default BusinessDisplay;
