import './Workspace.css';
import axios from 'axios';
import React, { useState, useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Form, Layout, Card, Button, Alert } from "antd";
import Status from './account/Status';
import { AccountContext } from "./account/Account";
import { Navigation } from "./navigation/Navigation";
import ClientSelection from "./frameworkSelection/ClientSelection";
import { useOutletContext } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner';
import { updateEntity } from "./utils/context-util";

const { Header, Content, Footer, Sider } = Layout;


const Workspace = (props) => {
    const context = useOutletContext();
    const backendURL = props.backendURL;
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("user"));
    const { getSession, logout } = useContext(AccountContext);
    const [showFrameworkSelection, setShowFrameworkSelection] = useState(true);
    const [menuCollapsed, setMenuCollapsed] = useState(true);    
    const [frameworkSelectionCollapsed, setFrameworkSelectionCollapsed] = useState(true);    
    const [currentSession, setCurrentSession] = useState(null);    
    const [loading, setLoading] = useState(true);    
    const [isAdmin, setIsAdmin] = useState(false);
    
    let loadingItems = false;

    useEffect(() => {
        try {
            getSession().then(async (session) => {
                if (session?.credentials.accessToken != currentSession?.credentials.accessToken) {
                    setLoggedIn(true);
                    if(props.context){
                        props.context.label = null;
                        axios.defaults.headers.common['Authorization'] = session?.credentials.accessToken;                        
                        await loadItems(session);
                    }
                    setCurrentSession(session);
                }
                else if(!session){
                    setLoggedIn(false);
                }
            }).catch(err => {
                setLoggedIn(false);
            });
        }
        catch (error) {
            setLoggedIn(false);
        }
    },[loggedIn]);    

    if (!localStorage.getItem("user")) {
        return <Navigate to="/login" />;
    }

    const loadItems = async (session) => {
        try{
            loadingItems = true;
            setLoading(true);
            let userResponse = await axios.get(`${backendURL}/user?id=${session.user.email}`);
            let allResponse = await axios.get(`${backendURL}/get-all`);
            let newContext = await updateEntity("user", "users", props.context, props.setOutletContext);
              
            let items = allResponse.data;
            let user = userResponse?.data?.Item;
            
            let currentIsAdmin = user.roles.indexOf('admin') >= 0;
            let currentIsClient = user.roles.indexOf('client') >= 0;
            let currentIsBusiness = user.roles.indexOf('business') >= 0;
            setIsAdmin(currentIsAdmin);

            if(currentIsBusiness){
                let newItem = items.businesses.find(b => b.email == user.id);
                if(newItem){
                    props.updateGlobalStyles(newItem.primaryColor.hex,
                        newItem.secondaryColor.hex,
                        newItem.tersiaryColor.hex,
                        newItem.alternatingColor.hex,
                        newItem.whiteColor.hex,
                        newItem.bodyColor.hex,
                        newItem.linkTextColor.hex,
                        newItem.linkTextHoverColor.hex,
                        newItem.textColor.hex,
                        newItem.dangerColor.hex,
                        newItem.dangerHoverColor.hex,
                        newItem.logoURL,
                        newItem.iconURL);
                }               
            }
            else if(currentIsClient){
                let businessID = items.clients?.find(c => c.userAccount?.id == user.id)?.business;
                if(businessID){
                    let newItem = items.businesses.find(b => b.email == businessID);
                    if(newItem){
                        props.updateGlobalStyles(newItem.primaryColor.hex,
                            newItem.secondaryColor.hex,
                            newItem.tersiaryColor.hex,
                            newItem.alternatingColor.hex,
                            newItem.whiteColor.hex,
                            newItem.bodyColor.hex,
                            newItem.linkTextColor.hex,
                            newItem.linkTextHoverColor.hex,
                            newItem.textColor.hex,
                            newItem.dangerColor.hex,
                            newItem.dangerHoverColor.hex,
                            newItem.logoURL,
                            newItem.iconURL);
                    }
                }
            }
            else{
                props.updateGlobalStyles();
            }

            let newOutletContext = {...newContext, accessToken:session?.credentials.accessToken,user,...items};
            if(!props.context?.label){
                newOutletContext.label = "";
                newOutletContext.showFrameworkSelection=true; 
            }
            props.setOutletContext(newOutletContext);
            return ;
        }
        catch(err){
            throw err
        }
        finally{
            setLoading(false);
            loadingItems = false;
        }
    }
    
    const handleFrameworkChange = (key) => {
        let framework = props.context?.frameworks?.find(f => f.id == key);
        props.setOutletContext({...props.context,
            selectedFramework: framework,
            label: framework.label
        });
    }

    return (
        <div className="App">
            {!loggedIn ? <Navigate to="/login" /> : null}
            {loading && <LoadingSpinner topPadding={"15%"}/>}
            {!loading && <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsed={menuCollapsed}  onCollapse={(value) => 
                    setMenuCollapsed(value)
                    }>
                    <Navigation setFrameworkSelectionCollapsed={setFrameworkSelectionCollapsed} collapsed={menuCollapsed} setCollapsed={setMenuCollapsed} context={props.context} setOutletContext={props.setOutletContext} setShowFrameworkSelection={setShowFrameworkSelection}/>
                </Sider>
                <Sider hidden={frameworkSelectionCollapsed} >
                    <ClientSelection isAdmin={isAdmin} context={props.context} collapsed={frameworkSelectionCollapsed} updateFramework={handleFrameworkChange} setCollapsed={setFrameworkSelectionCollapsed}/>
                </Sider>
                <Layout>
                    <Header style={{ paddingLeft: "0px" }}>
                        <Status 
                            allRoles={context?.roles} 
                            setLoggedIn={setLoggedIn}
                            collapsed={frameworkSelectionCollapsed}
                            context={props.context}
                            setCollapsed={setFrameworkSelectionCollapsed}
                             />
                    </Header>
                    <Layout>
                        <Outlet context={props.context}/>
                    </Layout>
                    <Footer style={{height:"1.25em"}} >footer</Footer>
                </Layout>
            </Layout>}
            
        </div>

    );
};

export default Workspace;