import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useOutletContext } from "react-router-dom";
import Pool from "../../UserPool";
import axios from "axios";

const AccountContext = createContext();

const Account = (props) => {
    const context = useOutletContext();

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            localStorage.removeItem("user");
        }
    }

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, result) => {
                    if (err) {
                        reject();
                    }
                    else {
                        const session = {
                            credentials: {
                              accessToken: result.accessToken.jwtToken,
                              idToken: result.idToken.jwtToken,
                              refreshToken: result.refreshToken.token
                            },
                            user: {
                              userName: result.idToken.payload['cognito:username'],
                              email: result.idToken.payload.email
                            }
                          }
                        resolve(session);
                    }
                });
            }
            else {
                reject();
            }
        });
    };


    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });

            const authDetails = new AuthenticationDetails({
                Username,
                Password
            });

            user.authenticateUser(authDetails, {
                onSuccess:async (data) => {
                    axios.defaults.headers.common['Authorization'] = data.accessToken.jwtToken;   
                    let userResponse = await axios.get(`${props.backendURL}/user?id=${data.idToken.payload.email}`);
                    if(userResponse.data.Item.active != true){
                        reject({message:"User not active"});
                        return;
                    }
                    data = {...data,imageURL:userResponse.data.Item.imageURL}
                    let userData = JSON.stringify(data);
                    localStorage.setItem("user", userData)
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error(err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    resolve(data);
                }
            });
        });
    }


    const confirmRegistration = async (Username, ConfirmationCode) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });
            
            var cognitoUser = new CognitoUser(user);
            cognitoUser.confirmRegistration(ConfirmationCode, true, function(err, result) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                console.log('call result: ' + result);
            });
        });
    }


    const resendConfirmationCode = async (Username  ) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username,
                Pool
            });

            user.resendConfirmationCode(function(err, result) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                console.log('call result: ' + result);
            });

        });
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout, resendConfirmationCode, confirmRegistration }}>
            {props.children}
        </AccountContext.Provider>
    );
}

export { Account, AccountContext };