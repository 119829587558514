import React from "react";
import { Button, Layout, Menu, Typography, Row, Col, Card, Space } from "antd";
import { Link } from "react-router-dom";
import "./LandingPage.css"; // Import your custom CSS here

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const LandingPage = () => {
  const coreFeatures = [
    {
      title: "Preconfigured Framework Templates",
      description: "Jumpstart your compliance journey with our extensive collection of ready-to-use policies, standards, and procedure templates. Effortlessly design, build, and operate internal controls that connect with compliance requirements for smooth implementation and progress tracking",
    },
    {
      title: "Policy Management",
      description: "Centralize your policies, standards, and procedures for easy access and timely review. Assign policies and procedures to relevant internal controls, ensuring clear expectations for testers and control owners"
    },
    {
      title: "Internal Audit Management",
      description: "Revolutionize your internal audit processes by reducing time and costs associated with compliance management. Connect control owners, testers, and auditors on a single platform for seamless collaboration and efficiency"
    },
    {
      title: "Internal Control Management",
      description: "Monitor responsibilities for executing, testing, and auditing controls. Document the activities performed to address risks and compliance requirements, along with the governing policies, standards, and procedures"
    },
    {
      title: "Sustainability",
      description: "Partnering with AWS Cloud, LeafGRC helps clients reduce their carbon footprint by 88% (compared to surveyed enterprise data centers). We're working towards introducing carbon offsetting features and enabling carbon footprint measurement directly from your dashboard"
    },
    {
      title: "Security",
      description: "We prioritize the confidentiality of your data by implementing a comprehensive range of security services, including secure access management, automated security checks, encryption during data transfer and at rest, and real-time incident response management. Visit our security page for more details"
    },
    {
      title: "Cloud-First Design",
      description: "Our service combines Infrastructure as a Service, Platform as a Service, and Infrastructure as Code, offering you the flexibility to access your GRC from anywhere, at any time"
    },
    {
      title: "Always Up-to-Date",
      description: "Eliminate the need for endless progress meetings. Choose your framework, assign roles, and track progress on a single platform for a more efficient and transparent GRC experience"
    },
    // Add more features here
  ];

  const bubbleInfo = [
    {
      title: "Governance",
      description: "Streamline your governance process with LeafGRC's extensive library of customizable templates for policies, standards, and procedures. Ensure the systematic design, construction, and operation of internal controls that seamlessly align with compliance requirements and risk mitigation strategies",
    },
    {
      title: "Risk",
      description: "Efficiently manage and monitor your organization's risks with LeafGRC's user-friendly interface. Effortlessly identify, categorize, and assess risks while connecting them to relevant internal controls. Stay ahead of the curve with an integrated approach to risk management",
    },
    {
      title: "Compliance",
      description: "Simplify compliance management across diverse requirements, including cross-industry regulations, regulatory engagements, best practices, and international standards. With LeafGRC, experience a unified, comprehensive approach to compliance for a more resilient and adaptable organization",
    },
    // Add more bubble info here
  ];

  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" className="header-menu" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">Products</Menu.Item>
          <Menu.Item key="2">Company</Menu.Item>
          <Menu.Item key="3" className="login-item">
            <Link to="/workspace">
              Login
            </Link>
          </Menu.Item>         
        </Menu>
      </Header>
      <Content className="antd-content">
        <div className="home-logo"></div>
        <Title style={{textAlign:"center"}}>Empower Compliance, Drive Sustainability. Discover the Future of GRC with LeafGRC</Title>
        <Paragraph style={{textAlign:"center"}}>Innovative GRC Solutions for CO2 Compliance Frameworks and Beyond. Schedule a demo today and experience the difference</Paragraph>
        <Paragraph>Experience the power of a serverless, cloud-driven GRC solution tailored to your organization's unique needs. Reduce your compliance workload and access real-time reporting, anytime, anywhere. Join the LeafGRC community and unlock the full potential of your governance, risk, and compliance efforts</Paragraph>
        <Row style={{display:"block ruby"}}>
          <Col span={12}>
            <Link to="/request-demo">
            < Button type="primary" className="demo-button">Request a Demo</Button>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/contact-us">
            <Button type="primary" className="contact-button">Contact Us</Button>
            </Link>
          </Col>
        </Row>
        <Row className="bubble-row" gutter={[16, 16]}>
          {bubbleInfo.map((info, index) => (
            <Col span={24} key={index}>
              <div className={`bubble-container ${index % 2 === 0 ? "left" : "right"}`}>
                <div className="bubble-description">
                  <Typography.Title level={4}>{info.title}</Typography.Title>
                  <Typography.Paragraph>{info.description}</Typography.Paragraph>
                </div>
                <Card className="bubble-card">{info.title}</Card>
              </div>
            </Col>
          ))}
        </Row>
        <Title level={2}>Core Features</Title>
        <Row className="core-features-row" gutter={[16, 16]}>
          {coreFeatures.map((feature, index) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6} key={index}>
              <Card className="feature-card">
                <div className="feature-card-title">{feature.title}</div>
                <div className="feature-card-description">{feature.description}</div>
              </Card>
            </Col>
          ))}
        </Row>
      </Content>
      <Footer className="antd-footer">
        <Space direction="vertical">
          <Paragraph>Copyright &copy; 2023 LeafGRC. All rights reserved.</Paragraph>
          <Paragraph>Terms of Service | Privacy Policy | Contact Us</Paragraph>
        </Space>
      </Footer>
    </Layout>
  );
};

export default LandingPage;
