import { Upload, Button, Alert, message } from "antd";
import React, { useState } from "react";
import "./InternalControlUpload.css";
import { useOutletContext } from "react-router-dom";
import axios from 'axios';
import fileDownload from 'js-file-download';
import { InboxOutlined } from "@ant-design/icons";
import {v4 as uuid} from "uuid";
import { timeout } from "../../utils/general.util";
import Papa from 'papaparse';
import { updateEntity } from "../../utils/context-util";
import jschardet from 'jschardet';

const results = [];

const { Dragger } = Upload;

function InternalControlUpload(props) {
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const context = useOutletContext();

  return (
    <>            
      {errorMessage && <Alert type="error" description={errorMessage}/>}
      <Button
      onClick={() => {
        fileDownload("Title,Owner,Testers,Reviewers,Auditors,Objectives,Success Criteria,Audit Step,Description,Control Frequency,Testing Status,Review Status,Group", "internal-control-upload-template.csv")
      }}
      >Download Template</Button>
        <Dragger
          multiple={true}
          fileList={fileList}
          action={async (file) => {
            return `${context.backendURL}/file`;
          }}
          customRequest={async (req) => {
            const parseCsv = (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const encoding = jschardet.detect(e.target.result).encoding;
                  Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: encoding,
                    complete: resolve,
                    error: reject,
                  });
                };
                reader.readAsText(file);
              });
            };
            

            try {
              const { data } = await parseCsv(req.file);
              let newControls = [];

              for (let i = 0; i < data.length; i++) {
                if (i > 0) {
                  await timeout(2000);
                }
                let values = data[i];

                try {
                  axios.defaults.headers.common['Authorization'] = context.accessToken;                

                  let workingPaper = {
                    "id": uuid(),
                    "label":`WP: ${values['Title']}.1`,
                    "status": "Not started",
                    "reviewStatus": "Not started",
                    "isDeleted": false,
                    "owner": {"id": values['Owner']},
                  }
                  try {
                    await axios.post(`${context.backendURL}/working-paper`,workingPaper);
                  } catch (err) {
                    return err;
                  }

                  let newItem = {
                    "id":uuid(),
                    "label": values['Title'],
                    "owner": values['Owner'].length > 0 ? {"id": values['Owner']} : null,
                    "testers": values['Testers'].length > 0 ? values['Testers']?.split(",").map(u => {return {id:u}}) : [],
                    "reviewers": values['Reviewers'].length > 0 ? values['Reviewers']?.split(",").map(u => {return {id:u}}) : [],
                    "auditors": values['Auditors'].length > 0 ? values['Auditors']?.split(",").map(u => {return {id:u}}) : [],
                    "objective": values['Objectives'],
                    "successCriteria": values['Success Criteria'],
                    "auditStep": values['Audit Step'],
                    "description": values['Description'],
                    "controlFrequency": [
                      values['Control Frequency'] ? values['Control Frequency'] : 'Yearly',
                    ],
                    "testingStatus": values['Testing Status'] ? values['Testing Status'] : 'Not Started',
                    "reviewStatus": values['Review Status'] ? values['Review Status'] : 'Not Started',
                    "workingPapers": [workingPaper],
                    "policies": [{
                        "id":props.model?.id
                      }],
                    "group": values['Group'],
                    "history": [],
                    "risks": [],
                    "template": props.isTemplate,
                    "isDeleted": false
                  }
                  let response = await axios.post(`${context.backendURL}/internal-control`,newItem);
                  console.log(`Saved IC: ${response.status} ${JSON.stringify(values)}`)
                  newControls.push(newItem);
                  setFileList([...fileList, req.file]);
                } catch (err) {
                  let errMessage = err.message;
                  if (err.response?.data?.dynamoResponse?.message) {
                    errMessage = err.response.data.dynamoResponse.message;
                  }
                  console.error(errMessage);
                  setErrorMessage(errMessage);
                  break;
                }
              }

              if (props.model.internalControls && props.model.internalControls.length > 0) {
                await props.onChange({ ...props.model, internalControls: [...props.model.internalControls, ...newControls.map((c) => c.id)] });
              } else {
                await props.onChange({ ...props.model, internalControls: [...newControls.map((c) => c.id)] });
              }
              await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
              await updateEntity("working-paper", "workingPapers", context, props.setOutletContext);
              
            } catch (err) {
              console.error(err.message);
              setErrorMessage(err.message);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
              <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Drag an drop your file here to upload and link internal controls.
          </p>
        </Dragger>
    </>
  );
}


export default InternalControlUpload;
