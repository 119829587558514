import { RedoOutlined } from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";
import { updateEntity } from "../utils/context-util";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Avatar,
  Tag,
  Modal,
  Tooltip,
  Collapse
} from "antd";

import React, { useEffect, useState } from "react";

import "./UserCrud.css";
import UserEditor from "./editor/UserEditor";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { handleError } from "../utils/general.util";
import LoadingSpinner from "../LoadingSpinner";
import HistoryDisplay from '../history/display/HistoryDisplay';
import _ from "lodash";
const { Panel } = Collapse;

function UserCrud(props) {
  const context = useOutletContext();
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState(context?.users);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  let currentIsClient = context?.user.roles.indexOf('client') >= 0;
  let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;

  useEffect(() => {
    setIsAdmin(currentIsAdmin);
    setFilteredItems();
  },[]);

  const setFilteredItems = async () => {
    let user = context?.user;
    let users = context.users;
    if(currentIsClient){
      users = users.filter(u => (u.company != null && u.company != "") || u.id == user.id).filter(u => u.company == user.company).filter(u => u.roles.includes('client'))
    }
    if(currentIsBusiness){
      let clients = context.clients.filter(c => c.business == context.user.id);
      let my_users = users.filter(u => (u.company != null && u.company != "")).filter(u => u.company == user.company && u.id != user.id);
      my_users = my_users ? my_users : [];
      users = [...users.filter(u => clients.map(c => c.userAccount.id).includes(u.id) || u.id == user.id), ...my_users];
    }
    setItems(users);
  }

  const admin_columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
    },
    {
      title: "Image",
      dataIndex: "imageURL",
      key: "imageURL",
      render: (item) => (
        <Avatar  src={item}/>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (item) => (
          item.join(", ")
      )
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];

  const columns = [
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
    },
    {
      title: "Image",
      dataIndex: "imageURL",
      key: "imageURL",
      render: (item) => (
        <Avatar  src={item}/>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (item) => (
          item?.map(p => { return <Tag style={{marginLeft:8}} key={p}>{p}</Tag> })
      )
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];

  const updateUsers = async () => {
    let newContext = await updateEntity("user", "users", context, props.setOutletContext);
    newContext.user = newContext.users.find(u => u.id == newContext.user.id);
    props.setOutletContext(newContext);
    await setFilteredItems();
  }

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
    </Menu>;
  };

  const onSearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };

  const onClose = async () => {
      setLoading(true);
      try{
        setModalVisible(false);
        setSelected({});
      }
      finally{
        await updateUsers();
        setLoading(false);
      }
  };

  const onRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/user`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("User",error,"deleted",setErrorMessage);    
      }
      finally{
        await updateUsers();
        setLoading(false);        
      }
    }
  };
  let addNewNotAllowed = currentIsClient && (context.user?.company == undefined || context.user?.company == "");
  return <>
  <div style={{ height: "3em" }}>
    <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
      <Space align="center">
        <Input
          size="medium"
          placeholder="Search...."
          style={{ width: "25vw" }}
          onChange={(e) => {
            console.log(e);
            setSearchVal(e.target.value);
          }}
          onKeyDown={(e) => onSearch(e)}
        />
      </Space>
    </div>
    <div style={{ float: "right", marginTop: ".15em" }}>
      {addNewNotAllowed && <Tooltip title="You are not able to add users if you are not part of a company, ask your administrator to add you to a company">
        <Button
        type="link"
        size="large"
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
          />
        }
      />
      </Tooltip>}
      {!addNewNotAllowed && <Button
        type="link"
        className="control"
        size="large"
        disabled={currentIsClient && (context.user?.company == undefined || context.user?.company == "")}
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
            onClick={() => onModify(null)}
          />
        }
      />}
      
      <Button
        type="link"
        className="control"
        size="large"
        icon={<AiOutlineHistory style={{ fontSize: "1.5em" }} 
        onClick={()=>{
          setShowHistory(true);
        }}/>}
      />
      <Button
        onClick={async () => {
          setLoading(true);
          try{
            await updateUsers();
          }
          finally{
            setLoading(false);
          }
        }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
    </div>
  </div>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {currentIsClient ? loading ? <LoadingSpinner/> : <Table dataSource={items} columns={isAdmin? admin_columns : columns} />
  : _.uniq(items?.map(i => i.company))?.map(i => {
    return <Collapse style={{background:"var(--secondary-color)", border:0}}><Panel header={<div style={{ float: "left", color:"var(--white-color)" }}>{i ? i : "No Company"}</div>}>
        {items && !loading?<Table dataSource={items.filter(j => j.company == i)} columns={isAdmin? admin_columns : columns} />: <LoadingSpinner/>}
      </Panel>
    </Collapse>
  })}
    
  <UserEditor
    isVisible={modalVisible}
    onClose={onClose}
    errorMessage={errorMessage}
    item={selected}
  />
  <Modal 
        title={"User History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false)
          }
        }}      
      >
        <HistoryDisplay context={context} group={"user"} />
      </Modal>
</>
   
  
}

export default UserCrud;
