export function getBackendUrl(frontendUrl = window.location.origin) {
    if(frontendUrl.includes("www.leafgrc.com")){
        return "https://qjmby70g0g.execute-api.eu-west-1.amazonaws.com/prod"
    }
    else if(frontendUrl.includes("demo.leafgrc.com")){
        return "https://qjmby70g0g.execute-api.eu-west-1.amazonaws.com/demo"
    }
    else if(frontendUrl.includes("uat.leafgrc.com")){
        return "https://qjmby70g0g.execute-api.eu-west-1.amazonaws.com/uat"
    }
    return "https://qjmby70g0g.execute-api.eu-west-1.amazonaws.com/staging"
  }
  