import {  Form, Input, Modal, Select, Alert, Upload, message, Checkbox } from "antd";
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import UserPool from "../../../UserPool";
import LoadingSpinner from "../../LoadingSpinner";

function UserEditor(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState(props.item);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;
  let currentIsClient = context?.user.roles.indexOf('client') >= 0;

  useEffect(() => {
    let user = localStorage.getItem("user");
    if(user){
      user = JSON.parse(user);
    }

    axios.defaults.headers.common['Authorization'] = context?.accessToken ? context.accessToken : user?.accessToken?.jwtToken ;  
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
    else{
      if(!currentIsAdmin){
        setItem({company: context?.user.company});
      }
      else{
        setItem({});
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = async (item) => {
    if (typeof props?.onClose == "function") {
      await props?.onClose(item);
    }
    if(form?.resetFields){
      form.resetFields();
    }
  };

  const validate = () =>   {     
    let values =  form.getFieldsValue();
    let pass = false;
    if(item?.id){
      pass = values.email != null &&  values.alias != null &&  values.roles.length != 0;
    }
    else{
      pass = values.email != null &&  values.alias != null &&  values.roles.length != 0 &&  values.password != null;
    }
    return pass;
  }

  const createUser = async (data,event) => {
    let values =  form.getFieldsValue();
    let currentContext = context;
    if(!currentContext){
      currentContext = props.context;
    }
    await axios.post(`${currentContext.backendURL}/user`,{
        id: values.email,
        alias: values.alias,
        email: values.email,
        userSub: data.userSub,
        imageURL: item.imageURL,
        roles: values.roles,
        active: values.active,
        company: event.company
    });
    message.success("User Created!");
}

const updateUser = async (event) => {
  let currentContext = context;
  if(!currentContext){
    currentContext = props.context;
  }
  await axios.post(`${currentContext.backendURL}/user`,event);
  message.success("User Updated!");
}

const onSubmit = async (event) => {
  try{
    setErrorMessage(null);
    setLoading(true);
    if(!validate()){            
      setErrorMessage("Please fill out all required fields");
      setLoading(false);
      return;
    }

    if(!item?.id){
      UserPool.signUp(event.email, event.password, [], null, async (err, data) => {
        setErrorMessage(null);
        if (err) {
            console.error(JSON.stringify(err.message, null, 2));
            if (err.message) {
                setErrorMessage(err.message);
            }
        }
        if(data) {
            await createUser(data,event);
            await onClose(item);
        }
      })
    }
    else{
      await updateUser(item);
      await onClose(item);
    }
  }
  finally{
    setLoading(false);
  }    
}

  const onFinishedFailed = (value) => {};

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose(item)
        }
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          if(!values.company){
            let currentContext = context;
            if(!currentContext){
              currentContext = props.context;
            }
            values.company = currentContext.user.company;
          }
          onSubmit(values,form);
        });
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} User</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
  {loading && <LoadingSpinner/>}
  {!loading && <Form
        form={form}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      <Form.Item
        label="Company"
        name="company"        
      >
        <Input defaultValue={currentIsAdmin ? null : context?.user.company} disabled={currentIsClient} placeholder="Company..." onChange={(event)=>{
          if(item){
            setItem({...item,company:event.currentTarget.value});
          }
        }}/>
      </Form.Item>
      <Form.Item
        label="Alias"
        name="alias"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input placeholder="Alias..." onChange={(event)=>{
          if(item){
            setItem({...item,alias:event.currentTarget.value});
          }
        }}/>
      </Form.Item> 
      {!item?.id && <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input.Password placeholder="" onChange={(event)=>{
          if(item){
            setItem({...item,password:event.currentTarget.value});
          }
        }}/>
      </Form.Item>} 
        <Form.Item
          label="Image"
          name="imageURL"          
        >
          <ImgCrop rotate>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          
          showUploadList={false}
          fileList={fileList}
          onChange={handleChange}
          headers={{'Authorization':context?.accessToken}}
          customRequest={req => {
            const reader = new FileReader();
            reader.readAsBinaryString(req.file);
            reader.onload = function() {
                const base64 = btoa(reader.result);
                // Force re-render of this component
                let imageURL = `data:${req.file.type};base64, ${base64}`;
                setItem({...item, imageURL});
                req.onSuccess(imageURL);
            };
        }}
        >
          {item?.imageURL ? <img src={item.imageURL} alt="avatar" style={{ width: '100%' }} /> : "Upload an image"}
        </Upload>
        </ImgCrop>
        </Form.Item>  
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input 
          readOnly={item?.id}
          placeholder="Email..." onChange={(event)=>{
            if(item){
              setItem({...item,email:event.currentTarget.value});
            }
          }}/>
        </Form.Item>  
      <Form.Item
        label="Active"
        name="active"       
      >
        <Checkbox checked={item?.active} onChange={(event)=>{
          if(item){
            setItem({...item,active:event.target.checked});
          }
        }}/>
      </Form.Item>  
        <Form.Item label="Roles" name="roles"
          rules={[
            {
              required: true,
              message: "Roles are required",
            },
          ]}>
          <Select mode="multiple" placeholder="Please select roles" onChange={(value)=>{
                if(item){
                  setItem({...item,roles:value});
                }
              }}              
              filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
              }>
            {context?.roles?.filter(r => currentIsAdmin || r.id == "client").map((role) => {
              return (
                <Select.Option value={role.id}>{role.label}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>     
      </Form>}      
    </Modal>
  );
}

export default UserEditor;
