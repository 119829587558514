import { HomeFilled } from "@ant-design/icons";
import { AutoComplete, Button, Col, Input, Alert, Menu, Row } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import UserDisplay from "../users/display/UserDisplay";

//STYLES
import "./FrameworkSelection.css";

export default function FrameworkSelection(props) {
  let frameworks = props.items;
  let user = props.context?.user;
  let currentIsAdmin = user?.roles.indexOf('admin') >= 0;
  let currentIsClient = user?.roles.indexOf('client') >= 0;
  let currentIsBusiness = user?.roles.indexOf('business') >= 0;
  if(user && currentIsClient){
    frameworks = frameworks?.filter(f => f.owner?.id == user.id || 
      f.approvers?.filter(a => a.id == user.id)?.length > 0 || 
      f.auditors?.filter(a => a.id == user.id)?.length > 0 || 
      f.reviewers?.filter(a => a.id == user.id)?.length > 0 || 
      f.testers?.filter(a => a.id == user.id)?.length > 0)
    frameworks = frameworks?.filter(f => f.template == false)
  }
  if(user && currentIsBusiness){
    let clients = props.context.clients.filter(c => c.business == user.id);
    frameworks = frameworks.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == user.id);
  }
  frameworks = frameworks?.filter(f => f.template == false)
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="action-framework-buttons">
            <Menu
              items={frameworks?.map((e) => {
                e.key = e.id;
                return {...e, key: e.id};
              })}
              mode="inline"
              theme="dark"
              onClick={({ key }) => {
                if (typeof props?.updateFramework == "function") {
                  props?.updateFramework(key);
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
