import './Reporting.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';
import MixGraph from './charts/MixGraph';
import PieChart from './charts/PieChart';
import BarGraph from './charts/BarGraph';

function Reporting(props) {
  return <div class="reporting-screen">
    <PieChart/>
    <MixGraph/>
    <BarGraph />
  </div>
}

export default Reporting;
