import { RedoOutlined } from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Popover,
  Tag,
  Modal
} from "antd";

import React, { useEffect, useState } from "react";

import "./ClientCrud.css";
import ClientEditor from "./editor/ClientEditor";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { handleError } from "../utils/general.util";
import LoadingSpinner from "../LoadingSpinner";
import UserDisplay from "../users/display/UserDisplay";
import { updateEntity } from "../utils/context-util";
import HistoryDisplay from '../history/display/HistoryDisplay';
import FrameworkDisplay from "../framework/FrameworkDisplay";
import BusinessDisplay from "../businesses/BusinessDisplay";

function UserCrud(props) {
  const context = useOutletContext();
  let currentIsAdmin = context.user.roles.indexOf('admin') >= 0;
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState(context?.clients.filter(c => currentIsAdmin || c.business == context.user.id));
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
            


  let columns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact Person Name",
      dataIndex: "contactPersonName",
      key: "contactPersonName",
    },
    {
      title: "Contact Person Email",
      dataIndex: "contactPersonEmail",
      key: "contactPersonEmail",
    },
    {
      title: "Contact Person Phone Number",
      dataIndex: "contactPersonPhoneNumber",
      key: "contactPersonEmail",
    },
    {
      title: "User Account",
      dataIndex: "userAccount",
      key: "userAccount",
      render: (item) => {
        return <UserDisplay model={item}/>
      }
    },
    {
      title: "Frameworks",
      dataIndex: "framework",
      key: "framework",
      render: (items) => items?.map((m) => {
        let loadedItem = context?.frameworks?.find(i => i.id == m);
        if(!loadedItem){
          return null;
        }
        return <Popover content={<FrameworkDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Framework"} trigger="click">
          <Tag style={loadedItem.notApplicable ? {textDecoration: 'line-through'} : null}>
            {loadedItem.label}
          </Tag>
        </Popover>
      })
    },
    {
      title: "Business",
      dataIndex: "business",
      key: "business",
      render: (item) => {
        let loadedItem = context?.businesses?.find(r => r.email == item);
        if(!loadedItem){
          return null;
        }
        return <Popover content={<BusinessDisplay item={loadedItem} setOutletContext={props.setOutletContext}/>} title={"Framework"} trigger="click">
          <Tag style={loadedItem.notApplicable ? {textDecoration: 'line-through'} : null}>
            {loadedItem.label}
          </Tag>
        </Popover>
      }
    },
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
    </Menu>;
  };

  const onSearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };

  const onClose = async () => {
    try{
      setLoading(true);
      setModalVisible(false);
      setSelected({});
    }
    catch(error){
        throw error;  
    }
    finally{
      let newContext = await updateEntity("client", "clients", context, props.setOutletContext);
      setItems(newContext.clients.filter(c => currentIsAdmin || c.business == context.user.id));
      setLoading(false);
    }
  };

  const onRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/client`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("Client",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("client", "clients", context, props.setOutletContext);
        setItems(newContext.clients.filter(c => currentIsAdmin || c.business == context.user.id));
        setLoading(false);
      }
    }
  };

  return <>
  <div style={{ height: "3em" }}>
    <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
      <Space align="center">
        <Input
          size="medium"
          placeholder="Search...."
          style={{ width: "25vw" }}
          onChange={(e) => {
            console.log(e);
            setSearchVal(e.target.value);
          }}
          onKeyDown={(e) => onSearch(e)}
        />
      </Space>
    </div>
    <div style={{ float: "right", marginTop: ".15em" }}>
      <Button
        type="link"
        className="control"
        size="large"
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
            onClick={() => onModify(null)}
          />
        }
      />
      <Button
        type="link"
        className="control"
        size="large"
        icon={<AiOutlineHistory style={{ fontSize: "1.5em" }} 
        onClick={()=>{
          setShowHistory(true);
        }}/>}
      />
      <Button
        onClick={async () => {
          setLoading(true);
          try{
            let newContext = await updateEntity("client", "clients", context, props.setOutletContext);
            setItems(newContext.clients.filter(c => currentIsAdmin || c.business == context.user.id));
          }
          finally{
            setLoading(false);
          }
        }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
    </div>
  </div>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {items && !loading  ?<Table dataSource={items} columns={columns.filter(col => currentIsAdmin || col.key != 'business')} />: <LoadingSpinner/>}  
  <ClientEditor
    isVisible={modalVisible}
    onClose={onClose}
    errorMessage={errorMessage}
    setOutletContext={props.setOutletContext}
    item={selected}
  ></ClientEditor>
  <Modal 
        title={"Client History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false)
          }
        }}      
      >
        <HistoryDisplay context={context} group={"client"} />
      </Modal>
</>
   
  
}

export default UserCrud;
