import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../utils/general.util";
import UserAllocation from "../../users/allocation/UserAllocation";
import { Form, Input, Modal, Select, DatePicker, Alert, message, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {v4 as uuid} from "uuid";
import FileUpload from "../../upload/FileUpload";
import LoadingSpinner from '../../LoadingSpinner';
import moment from "moment";

function WorkingPaperEditor(props) {
  const context = useOutletContext();
  const [form] = Form.useForm();
  const [item,setItem] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTester, setIsTester] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  
  useEffect(() => { 
    if(props.item && !props.item.id && props.item.label == null){
      let newName = props.item.parent.label + "-" + (props.item.parent.workingPapers.length + 1).toString();
      setItem({...props.item, label: newName});
      form.setFieldsValue({...props.item, label: newName});
      props.item.label =  newName;
    }
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
    let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
    setIsAdmin(currentIsAdmin);  
    setIsTester(props.item?.testers ? props.item.testers.map(i => i.id).includes(context.user.id) : false);
    setIsReviewer(props.item?.reviewers ? props.item.reviewers.map(i => i.id).includes(context.user.id) : false);
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = async (newItem) => {
    try{
      if (typeof props?.onClose == "function") {
        await props?.onClose(newItem);
      }
      if(form?.resetFields){
        form.resetFields();
      }
      setItem(null);
    }
    finally {      
      setLoading(false);
    }
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    pass = values.label != null;
    return pass;
  }

  const createItem = async (event) => {
    try{
      let itemResponse = await axios.post(`${context.backendURL}/working-paper`,{...event});
      onClose(JSON.parse(itemResponse.data.body));
      message.success("WorkingPaper Created!");
    } 
    catch(err){
      handleError("WorkingPaper",err,"created",setErrorMessage);
    }
}

const updateItem = async (event) => {
  try{
    let itemResponse = await axios.post(`${context.backendURL}/working-paper`,{...event});
    onClose(JSON.parse(itemResponse.data.body));
    message.success("WorkingPaper Updated!");
  } 
  catch(err){
    handleError("WorkingPaper",err,"updated",setErrorMessage);
  }
}

  const onSubmit = async (event) => {
    setLoading(true);
    let newItem = {...item, ...event};
    if(!validate(newItem)){            
      setErrorMessage("Please fill out all required fields");
      setLoading(false);
      return;
    }
    if(!newItem?.id){    
      newItem.id = uuid(); 
      newItem.template = props.isAdmin ? props.isAdmin : false;
      newItem.isDeleted = false;
      await createItem(newItem);
    }
    else{
      await updateItem(newItem);
    }  
  }

  const onFinishedFailed = (value) => {};

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose()
        }
      }}
      onOk={async () => {        
        try{
          setErrorMessage(null);
          let values = await form.validateFields();
          await onSubmit(values,form);
        }
        catch(err){
          setErrorMessage(`Please fill out all fields:${err.errorFields?.map(e => e.name).join(", ")}`);
          setLoading(false);
        }
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} WorkingPaper {props.item?.template != null ? props.item.template == true ? "Template" : "Instance" : isAdmin ? "Template" : "Instance"}</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
  {loading ? <LoadingSpinner/> : <Form
        form={form}
        name="basic"
        onFinish={async () => {
          try{
            setErrorMessage(null);
            let values = await form.validateFields();
            await onSubmit(values,form);
          }
          catch(err){
            setErrorMessage(`Please fill out all fields:${err.errorFields?.map(e => e.name).join(", ")}`);
            setLoading(false);
          }
        }}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Name" name="label" required={true}>
          <Input
            placeholder={"Please enter a name..."}
            onChange={(event) => {
              if (item) {            
                setItem({ ...item, label: event.target.value });
              }
            }}
          />
        </Form.Item>        
        <Form.Item
          label="Start Date"
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
        >
          <DatePicker
          value={item?.startDate ? moment(item.startDate): null}
            onChange={(event) => {
              if (item) {            
                setItem({ ...item, startDate: event });
                form.setFieldValue("startDate",event.toISOString());
              }
            }}/>
        </Form.Item>
        <Form.Item
          label="End  Date"
          rules={[
            {
              required: true,
              message: "End  Date is required",
            },
          ]}
        >
          <DatePicker
          value={item?.endDate ? moment(item.endDate): null}
            onChange={(event) => {
              if (item) {
                setItem({ ...item, endDate: event });
                form.setFieldValue("endDate", event.toISOString());
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserAllocation model={item?.owner} change={(newValue)=>{
            if(item){
              setItem({...item,owner:{id:newValue.id}});
              form.setFieldValue("owner",{id:newValue.id});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserAllocation model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item label="Input" name="input">
          <TextArea placeholder="Please enter input here..." />
        </Form.Item>
        <Form.Item label="Analysis" name="analysis">
          <TextArea placeholder="Please enter analysis..." />
        </Form.Item>
        <Form.Item label="Output" name="output">
          <TextArea placeholder="Please enter output here..." />
        </Form.Item>
        <Form.Item label="Evidence" name="evidence">
          <FileUpload model={item} onChange={async (value) => {
                setItem(value);
              }}/>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select mode="multiple" placeholder="Please select status..." disabled={!isTester}>
            <Select.Option key="Started">Started</Select.Option>
            <Select.Option key="Not Started">Not Started</Select.Option>
            <Select.Option key="Issue">Issue</Select.Option>
            <Select.Option key="Exception Noted">Exception Noted</Select.Option>
            <Select.Option key="No Exception Noted">
              No Exception Noted
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Review Status" name="reviewStatus">
          <Select mode="multiple" placeholder="Please select review status..." disabled={!isReviewer}>
            <Select.Option key="Started">Started</Select.Option>
            <Select.Option key="Not Started">Not Started</Select.Option>
            <Select.Option key="Issue">Issue</Select.Option>
            <Select.Option key="Exception Noted">Exception Noted</Select.Option>
            <Select.Option key="No Exception Noted">
              No Exception Noted
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>}
    </Modal>
  );
}

export default WorkingPaperEditor;
