import { RedoOutlined} from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { MdDeleteOutline } from "react-icons/md";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Button,
  Tag,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";

import "./WorkingPaperCrud.css";
import WorkingPaperEditor from "./editor/WorkingPaperEditor";
import UserDisplay from "../users/display/UserDisplay";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import {v4 as uuid} from "uuid";
import { handleError } from "../utils/general.util";
import LoadingSpinner from "../LoadingSpinner";
import { updateEntity } from "../utils/context-util";

function WorkingPaperCrud(props) {
  const [items, setItems] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useOutletContext();


  useEffect(() => {
    if (!items) {
      loadItems(context);
    }
  }); 
  
  const loadItems = async (currentContext = context) => {    
    if(currentContext.backendURL){     
      try{
        setLoading(true);
        let currentIsAdmin = currentContext.user.roles.indexOf('admin') >= 0;
        setIsAdmin(currentIsAdmin);

        let workingPapers = props.items.map(i => {
          let paper = currentContext.workingPapers.find(w => w.id == i.id);
          return paper
        });
        workingPapers = workingPapers.filter(w => w != undefined);
        setItems(workingPapers);
      } 
      catch(err){
        handleError("Working Paper",err,"loaded",setErrorMessage);
      }
      finally{
        setLoading(false);
      }
    }
  } 

  const columns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (item) => {
        if(item){
          return moment(item).format("Do MMMM YYYY");
        }
        return null;
      }
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (item) => {
        if(item){
          return moment(item).format("Do MMMM YYYY");
        }
        return null;
      }
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => (
        <span>
          <UserDisplay model={item} />
        </span>
      ),
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{ multiple: true }} />
      ),
    },
    {
      title: "Input",
      dataIndex: "input",
      key: "input",
    },
    {
      title: "Analysis",
      dataIndex: "analysis",
      key: "analysis",
    },
    {
      title: "Output",
      dataIndex: "output",
      key: "output",
    },
    {
      title: "File / Attachment",
      dataIndex: "fileList",
      key: "fileList",
      render: (item) => item?.map((i) => {
      return <Tag><a href={i.url} target="_blank">{i.name}</a></Tag>
    }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (statusses) => {
        if (statusses && typeof statusses !== "string" && statusses.length) {
          return (
            <>
              {statusses.join(", ")}
            </>
          );
        } else return <>
          {statusses}
        </>;
      },
    },
    {
      title: "Review",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      render: (reviews) => {
        if (reviews && typeof reviews !== "string" && reviews.length) {
          return (
            <>
              {reviews.join(", ")}
            </>
          );
        } else return <>
          {reviews}
        </>;
      },
    },
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onWorkingPaperModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onWorkingPaperRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
    </Menu>;
  };

  const onWorkingPaperModify = (e) => {
    if (e) setSelected(e);
    else setSelected(null);
    setModalVisible(true);
  };

  const onWorkingPaperClose = async (value) => {
    if (value && JSON.stringify(value,null,2) != '{}') {
      let newItems = [...items];
      if (items?.length) {
        var index = newItems?.findIndex((item) => value?.id === item?.id);
        try{
          setLoading(true);
          if(!value.id || value.id == 'NaN' || value.new){
            if(!value.id || value.id == 'NaN' ){
              value.id =  uuid();
            }
            if(!value.owner){
              value.owner = {
                id:context?.user.id
              }
            }
            if(!value.hasOwnProperty("template")){
              value.template = isAdmin;
            }
          }
          await axios.post(`${context.backendURL}/working-paper`,value);
          if (index > -1) {
            const item = newItems[index];
            newItems.splice(index, 1, { ...item, ...value });
          } else newItems?.push(value);
          let newContext = await updateEntity("working-paper", "workingPapers", context, props.setOutletContext);
          await loadItems(newContext);
          setItems(newItems);
        }
        catch(error){
          handleError("WorkingPaper",error,"saved",setErrorMessage);          
        } 
        finally{
          setLoading(false);
        }       
      }
    }
    setModalVisible(false);
    setSelected(null);
  };

  const onWorkingPaperRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/working-paper`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("WorkingPaper",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("working-paper", "workingPapers", context, props.setOutletContext);
        await loadItems(newContext);
        setLoading(false);
      }
    }
  };

  return <>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {items && !loading?
  <>
  <Table dataSource={items} columns={columns} />
  
  </> : <LoadingSpinner/>}  
  <WorkingPaperEditor
    isVisible={modalVisible}
    isAdmin={isAdmin}
    onClose={onWorkingPaperClose}
    errorMessage={errorMessage}
    setOutletContext={props.setOutletContext}
    item={selected}
  ></WorkingPaperEditor>
</>
   
  
}

export default WorkingPaperCrud;
