import { RedoOutlined } from "@ant-design/icons";

import {v4 as uuid} from "uuid";
import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Tag,
  Modal
} from "antd";

import React, { useEffect, useState, useCallback, useRef } from "react";

import "./RiskCrud.css";
import RiskEditor from "./editor/RiskEditor";
import UserDisplay from "../users/display/UserDisplay";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { handleError } from "../utils/general.util";
import LoadingSpinner from "../LoadingSpinner";
import { updateEntity } from "../utils/context-util";
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HistoryDisplay from '../history/display/HistoryDisplay';
const type = 'DraggableBodyRow';

function RiskCrud(props) {
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const context = useOutletContext();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortChanged, setSortChanged] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  let loadingItems = false;

  useEffect(() => {
    if(context.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!items && !loadingItems) {
        loadItems();
      }
    }
  }); 

  const loadItems = async (currentContext = context) => {    
    if(currentContext.backendURL){     
      setLoading(true);
      loadingItems = true;
      try{
        let risks = currentContext.risks;
        let user =  currentContext.user;        

        let currentIsAdmin = currentContext.user.roles.indexOf('admin') >= 0;
        let currentIsClient = currentContext.user.roles.indexOf('client') >= 0;
        let currentIsBusiness = currentContext.user.roles.indexOf('business') >= 0;
        setIsAdmin(currentIsAdmin);
        if(currentIsClient){
          risks = risks?.filter(f => f.owner.id == user.id || 
            f.approvers?.filter(a => a.id == user.id)?.length > 0 || 
            f.auditors?.filter(a => a.id == user.id)?.length > 0 || 
            f.reviewers?.filter(a => a.id == user.id)?.length > 0 || 
            f.testers?.filter(a => a.id == user.id)?.length > 0)
          risks = risks?.filter(f => f.template == false)
        }
        if(currentIsBusiness){
          let clients = currentContext.clients.filter(c => c.business == currentContext.user.id);
          risks = risks.filter(f => clients.map(c => c.userAccount.id).includes(f.owner.id) || f.owner.id == currentContext.user.id);
        }
        risks = risks.map(i => {
          return {...i,key:i.id}
        })
        risks = risks.sort((a, b) => {
          if(a.index == null || b.index == null){
            return 0;
          }
          return a.index - b.index;
        })
        setItems(risks);
      } 
      catch(err){
        handleError("Risks",err,"loaded",setErrorMessage);
      }
      finally{
        loadingItems = false;
        setLoading(false);
      }
    }
  } 

  const adminColumns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label"
    },
    {
      title: "Type",
      dataIndex: "template",
      key: "template",
      render: (item) => {
        if(item){
          return <Tag color="purple">Template</Tag>
        }
        return <Tag color="orange">Instance</Tag>
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Version Nr",
      dataIndex: "versionNumber",
      key: "versionNumber"
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority"
    },
    {
      title: "Likelihood",
      dataIndex: "likelihood",
      key: "likelihood"
    },
    {
      title: "Impact",
      dataIndex: "impact",
      key: "impact"
    },
    {
      title: "Response Type",
      dataIndex: "responseType",
      key: "responseType"
    },
    {
      title: "Response Description",
      dataIndex: "responseDescription",
      key: "responseDescription"
    },
    {
      title: "Risk Tolerance",
      dataIndex: "riskTolerance",
      key: "riskTolerance"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Review Frequency",
      dataIndex: "reviewFrequency",
      key: "reviewFrequency"
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{multiple:true}}/>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      render: (item) => (
        <UserDisplay model={item.owner}/>
      ),
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ]

  const columns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "label"
    },    
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Version Nr",
      dataIndex: "versionNumber",
      key: "versionNumber"
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority"
    },
    {
      title: "Likelihood",
      dataIndex: "likelihood",
      key: "likelihood"
    },
    {
      title: "Impact",
      dataIndex: "impact",
      key: "impact"
    },
    {
      title: "Response Type",
      dataIndex: "responseType",
      key: "responseType"
    },
    {
      title: "Response Description",
      dataIndex: "responseDescription",
      key: "responseDescription"
    },
    {
      title: "Risk Tolerance",
      dataIndex: "riskTolerance",
      key: "riskTolerance"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Review Frequency",
      dataIndex: "reviewFrequency",
      key: "reviewFrequency"
    },
    {
      title: "Users",
      key: "users",
      render: (item) => (
        <UserDisplay model={item} config={{multiple:true}}/>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      render: (item) => (
        <UserDisplay model={item.owner}/>
      ),
    },    
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];
  
  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          cursor: 'move',
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const saveSort = async () => {    
    setLoading(true);
    try{
      for(let index in items){
        let item = items[index];
        await axios.post(`${context.backendURL}/risk`, item);
      }
      setSortChanged(false);    
    }
    finally{
      await updateEntity("risk", "risks", context, props.setOutletContext);
      setLoading(false);
    } 
  }

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
        const dragRow = items[dragIndex];
        let newData = update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }); 
        
        for(let index in newData){
          let nData = newData[index];
          nData.index = index;
        }
        setItems(newData);
        await updateEntity("risk", "risks", context, props.setOutletContext);
        setSortChanged(true);     
    },
    [items],
  );

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
    </Menu>;
  };

  const onSearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };

  const onClose = async (value) => {
    if (value) {
      setLoading(true);
      let newItems = [...items];
      var index = newItems?.findIndex((item) => value?.id === item?.id);
      try{
        if(!value.id){
          value.id = uuid();
          value.owner = {
            id:context?.user.id
          }
        }
        
        if(!value.hasOwnProperty("template")){
          value.template = isAdmin;
        }
        
        if(!value.hasOwnProperty("owner")){
          value.owner = {
            id: context?.user.id
          }
        }
          
        await axios.post(`${context.backendURL}/risk`,value);
        if (index > -1) {
          const item = newItems[index];
          newItems.splice(index, 1, { ...item, ...value });
        } else newItems?.push(value);

        setItems(newItems);
        setModalVisible(false);
        setSelected({});
        let newContext = await updateEntity("risk", "risks", context, props.setOutletContext);
        loadItems(newContext);
        message.success("Item saved!");
      }
      catch(error){
        handleError("Risk",error,"saved",setErrorMessage);          
      }     
      finally{
        setLoading(false);        
      }   
    }
    else{
      //on a cancel
      setModalVisible(false);
      setSelected({});
      loadItems();
    }    
  };

  const onRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/risk`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("Risk",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("risk", "risks", context, props.setOutletContext);
        loadItems(newContext);
        setLoading(false);
      }
    }
  };

  return <>
  <div style={{ height: "3em" }}>
    <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
      <Space align="center">
        <Input
          size="medium"
          placeholder="Search...."
          style={{ width: "25vw" }}
          onChange={(e) => {
            console.log(e);
            setSearchVal(e.target.value);
          }}
          onKeyDown={(e) => onSearch(e)}
        />
      </Space>
    </div>
    <div style={{ float: "right", marginTop: ".15em" }}>
      <Button
        type="link"
        className="control"
        size="large"
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
            onClick={() => onModify(null)}
          />
        }
      />
      <Button
        type="link"
        className="control"
        size="large"
        icon={<AiOutlineHistory style={{ fontSize: "1.5em" }}  
        onClick={()=>{
          setShowHistory(true);
        }}/>}
      />
      <Button
        onClick={async () => {
          setLoading(true);
          try{
            let newContext = await updateEntity("risk", "risks", context, props.setOutletContext);
            loadItems(newContext);
          }
          finally{
            setLoading(false);
          }
        }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
    </div>
  </div>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {items && !loading?
  <DndProvider backend={HTML5Backend}>
    {sortChanged && <Button 
            style={{marginBottom:16, float:"left"}}
            onClick={async ()=>{
              await saveSort();
            }}>Save Sort Order</Button>}
    <Table 
      dataSource={items} 
      components={components}
      onRow={(_, index) => {
        _.index = index;                
        const attr = {
          index,
          moveRow,
        };
        return attr;
      }}
      columns={isAdmin ? adminColumns : columns} />
  </DndProvider>: <LoadingSpinner/>}  
  <RiskEditor
    isVisible={modalVisible}
    onClose={onClose}
    errorMessage={errorMessage}
    item={selected}
  ></RiskEditor>
  <Modal 
        title={"Risk History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false);
          }
        }}      
      >
        <HistoryDisplay context={context} group={"risk"} />
      </Modal>
</>
   
  
}

export default RiskCrud;
