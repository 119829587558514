import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Select, Upload, Alert, Button, Checkbox } from "antd";
import { updateEntity } from "../utils/context-util";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from '../LoadingSpinner';

const { Dragger } = Upload;
const { TextArea } = Input;

function InternalControlDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [fileList, setFileList] = useState([]);
  const [editing, setEditing] = useState(false);

  const statusses = ["Draft", "Reviewed", "Final", "Issue", "Up for review"];
  const testingStatusses = ["Not Started", "Started", "Issue" , "Exception noted", "No exception noted"];
  const frequencies = ["Yearly", "Quaterly", "Weekly", "Daily"];
  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}  
      {!editing ? (
        <Button onClick={() => {
          setEditing(true); 
          props.setEditing(true);
        }}>Edit</Button>
      ) : (
        <Button disabled={loading} onClick={async () => {
          setLoading(true);
          try{
            await axios.post(`${context.backendURL}/internal-control`,{...item});
            await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
            setEditing(false);
            props.setEditing(false);
          }
          finally{
            setLoading(false);
          }
        }}>Save</Button>
      )}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
                <Form.Item
        label="Title"
        name="label"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
        >
        <Input disabled={!editing} placeholder="Title..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
        </Form.Item>
        <Form.Item
          label="Group"
          name="group"          
        >
          <Input disabled={!editing} placeholder="Group..." onChange={(event)=>{
            if(item){
              setItem({...item,group:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserDisplay model={item?.owner} />
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserDisplay model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item
          label="Objective"
          name="objective"
          rules={[
            {
              required: true,
              message: "Objective is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!editing} placeholder="Objective..." onChange={(event)=>{
            if(item){
              setItem({...item,objective:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Success Criteria"
          name="successCriteria"
          rules={[
            {
              required: true,
              message: "Success Criteria is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!editing} placeholder="Success Criteria..." onChange={(event)=>{
            if(item){
              setItem({...item,successCriteria:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Audit Step"
          name="auditStep"
          rules={[
            {
              required: true,
              message: "Audit Step is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!editing} placeholder="Audit Step Criteria..." onChange={(event)=>{
            if(item){
              setItem({...item,auditStep:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!editing} placeholder="Description..." onChange={(event)=>{
            if(item){
              setItem({...item,description:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item label="Control Frequency" name="controlFrequency"  
          rules={[
            {
              required: true,
              message: "Control Frequency is required",
            },
          ]}>
          <Select disabled={!editing} mode="multiple" placeholder="Please select control frequency" onChange={(value)=>{
                if(item){
                  setItem({...item,controlFrequency:value});
                }
              }}>
            {frequencies?.map((frequency) => {
              return (
                <Select.Option value={frequency}>{frequency}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Testing Status" name="testingStatus" initialValue={item?.testingStatus} 
          rules={[
            {
              required: true,
              message: "Testing Status is required",
            },
          ]}>
          <Select disabled={!editing} onChange={(value)=>{
                if(item){
                  setItem({...item,testingStatus:value});
                }
              }}>
            {testingStatusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Review Status" name="reviewStatus" initialValue={item?.reviewStatus} 
          rules={[
            {
              required: true,
              message: "Review Status is required",
            },
          ]}>
          <Select disabled={!editing} onChange={(value)=>{
                if(item){
                  setItem({...item,reviewStatus:value});
                }
              }}>
            {testingStatusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Not Applicable"
          name="notApplicable"    
        >
          <Checkbox disabled={!editing} checked={item?.notApplicable} onChange={(event)=>{
            if(item){
              setItem({...item,notApplicable:event.target.checked});
            }
            else{
              setItem({notApplicable:event.target.checked});
            }
        }}/>
        </Form.Item>
      </Form>}
    </div>

  );
}

export default InternalControlDisplay;
