import './Login.css';
import React, { useState, useContext } from "react";
import { Form, Input, Card, Button, Alert, Image } from "antd";
import { AccountContext } from "./account/Account";
import { Navigate } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner';

const Signup = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [succcessMessage, setSucccessMessage] = useState(null);
  const [gotoSignup, setGotoSignup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authenticate } = useContext(AccountContext);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      setSucccessMessage(null);
      setErrorMessage(null);
      setInfoMessage(null);
      await authenticate(email, password);
      setSucccessMessage("Login success!");
    }
    catch (err) {
      setErrorMessage(`Login failed: ${err.message}`);
    }
    finally{      
      setLoading(false);
    }
  }

  const signup = async (event) => {
    event.preventDefault();
    setGotoSignup(true);
  }

  if (succcessMessage) {
    return <Navigate to="/workspace" />
  }

  if (gotoSignup) {
    return <Navigate to="/signup" />
  }

  return (
    <div>
      {loading && <LoadingSpinner topPadding={"15%"}/>}
      {!loading && <Card title={<div className="login-logo"/>} style={{ width: '100%', maxWidth:'500px', margin: "auto", top: 200 }}>
        {infoMessage && <Alert message={infoMessage} type="info" style={{ padding: 8, margin: "8px 0px" }} />}
        {errorMessage && <Alert message={errorMessage} type="error" style={{ padding: 8, margin: "8px 0px" }} />}
        {succcessMessage && <Alert message={succcessMessage} type="success" style={{ padding: 8, margin: "8px 0px" }} />}
        <Form size="small">
          <Form.Item label="Email">
            <Input value={email} onChange={(event) => {
              let value = event.target.value;
              setEmail(value);
            }} />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password value={password} onChange={(event) => {
              let value = event.target.value;
              setPassword(value);
            }} />
          </Form.Item>
          <Button className="login-button" type="primary" style={{ float: "right" }} onClick={onSubmit}>
            Login
          </Button>
          <Button className="signup-button" style={{ float: "right" }} onClick={signup}>
            Signup
          </Button>
        </Form>
      </Card>}
    </div>

  );
};

export default Signup;