import { Checkbox, Form, Input, Modal, Select, Alert,Upload,message, Tabs, Card, Image } from "antd";
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../utils/general.util";
import UserAllocation from "../../users/allocation/UserAllocation";
import UserPool from "../../../UserPool";
import { updateEntity } from "../../utils/context-util";
import LoadingSpinner from '../../LoadingSpinner';
import { Colorpicker } from 'antd-colorpicker';

import {v4 as uuid} from "uuid";

function BusinessEditor(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState(props.item);
  const [newUser,setNewUser] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [fileList, setFileList] = useState([]);
  const [activeTab, setActiveTab] = useState("existing");      
  const initialThemeValues = { 
    primaryColor: { r: 19, g: 153, b: 1, a: 1 },
    secondaryColor: { r: 80, g: 75, b: 58, a: 1 },
    tersiaryColor: { r: 49, g: 46, b: 36, a: 1 },
    alternatingColor: { r: 79, g: 141, b: 88, a: 1 },
    whiteColor: { r: 255, g: 255, b: 255, a: 1 },
    bodyColor: { r: 245, g: 248, b: 247, a: 1 },
    linkTextColor: { r: 90, g: 139, b: 32, a: 1 },
    linkTextHoverColor: { r: 19, g: 153, b: 1, a: 1 },
    textColor: { r: 90, g: 139, b: 32, a: 1 },
    dangerColor: { r: 156, g: 0, b: 0, a: 1 },
    dangerHoverColor: { r: 207, g: 0, b: 0, a: 1 }
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    if(user){
      user = JSON.parse(user);
    }
    axios.defaults.headers.common['Authorization'] = context?.accessToken ? context.accessToken : user?.accessToken?.jwtToken ;  
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = () => {
    if (typeof props?.onClose == "function") {
      props?.onClose();
    }
    if(form?.resetFields){
      form.resetFields();
    }
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    pass = values.address != null && 
    values.contactPersonEmail != null && 
    values.contactPersonName != null &&
    values.contactPersonPhoneNumber != null &&
    values.label != null &&
    values.userAccount != null ;
    return pass;
  }

  const updateReferences = async (event) => {
    event.framework = event.framework?.map(f => context.frameworks.find(r => r.id == f)).filter(f => f != undefined);
    event.policies = event.policies?.map(f => context.policies.find(r => r.id == f)).filter(f => f != undefined);
    event.risks = event.risks?.map(f => context.risks.find(r => r.id == f)).filter(f => f != undefined);
    let updateRisk = false;
    let updateFramework = false;
    let updatePolicy = false;
    let updateInternalControls = false;
    for(let index in event.risks){
      let item = event.risks[index];
      if(item.template == true){
        updateRisk = true;
        let newItem = item;
        newItem.template = false;
        newItem.id = uuid(); 
        newItem.owner = event.userAccount;
        await axios.post(`${context.backendURL}/risk`,newItem);
        event.risks[index] = newItem.id;
      }
    }
    for(let index in event.framework){
      let item = event.framework[index];
      if(item.risks?.length > 0){
        item.risks = item.risks.map(i => context.risks.find(c => c.id == i)).filter(i => i != undefined);
        for(let riskIndex in  item.risks){
          let risk = item.risks[riskIndex];
          if(risk.template == true){
            let newRisk = risk;
            newRisk.template = false;
            newRisk.id = uuid(); 
            newRisk.owner = event.userAccount;
            await axios.post(`${context.backendURL}/risk`,newRisk);
            updateRisk = true;
            item.risks[riskIndex] = newRisk.id;
          }
        }
      }
      if(item.policies?.length > 0){
        item.policies = item.policies.map(i => context.policies.find(c => c.id == i)).filter(i => i != undefined);
        for(let index in item.policies){
          let policy = item.policies[index];

          if(policy.internalControls?.length > 0){
            policy.internalControls = policy.internalControls.map(i => context.internalControls.find(c => c.id == i)).filter(i => i != undefined);
            for(let icIndex in  policy.internalControls){
              let ic = policy.internalControls[icIndex];
              if(ic.template == true){
                let newIc = ic;
                newIc.template = false;
                newIc.id = uuid(); 
                newIc.owner = event.userAccount;
                await axios.post(`${context.backendURL}/internal-control`,newIc);
                updateInternalControls = true;
                policy.internalControls[icIndex] = newIc.id;
              }
            }            
          }
          if(policy.template == true){
            let newPolicy = policy;
            newPolicy.template = false;
            updatePolicy = true;
            newPolicy.id = uuid(); 
            newPolicy.owner = event.userAccount;
            await axios.post(`${context.backendURL}/policy`,newPolicy);
            item.policies[index] = newPolicy.id;
          }
        }
      }
      if(item.template == true){
        let newItem = item;
        updateFramework = true;
        newItem.template = false;
        newItem.id = uuid(); 
        newItem.owner = event.userAccount;
        await axios.post(`${context.backendURL}/control-framework`,newItem);
        event.framework[index] = newItem.id;
      }
    }
    for(let index in event.policies){
      let item = event.policies[index];
      if(item.internalControls?.length > 0){
        item.internalControls = item.internalControls.map(i => context.internalControls.find(c => c.id == i));
        for(let icIndex in  item.internalControls){
          let ic = item.internalControls[icIndex];
          if(ic?.template == true){
            let newIc = ic;
            newIc.template = false;
            newIc.id = uuid(); 
            newIc.owner = event.userAccount;
            await axios.post(`${context.backendURL}/internal-control`,newIc);
            updateInternalControls = true;
            item.internalControls[icIndex] = newIc.id;
          }
        }
      }
      if(item.template == true){
        let newItem = item;
        newItem.template = false;
        updatePolicy = true;
        newItem.id = uuid(); 
        newItem.owner = event.userAccount;
        await axios.post(`${context.backendURL}/policy`,newItem);
        event.policies[index] = newItem.id;
      }
    }
    
    if(updateRisk){
      await updateEntity("risk", "risks", context, props.setOutletContext);
    }
    if(updatePolicy){
      await updateEntity("policy", "policies", context, props.setOutletContext);
    }
    if(updateFramework){
      await updateEntity("control-framework", "controlFrameworks", context, props.setOutletContext);
    }
    if(updateInternalControls){
      await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
    }
     await updateEntity("business", "businesses", context, props.setOutletContext);
    event.framework = event.framework?.map(f => f.id ? f.id : f);
    event.policies = event.policies?.map(f => f.id ? f.id : f);
    event.risks = event.risks?.map(f => f.id ? f.id : f);
  }

  const createItem = async (event) => {
    try{
      await updateReferences(event);
      if(activeTab == "new"){
        await axios.post(`${context.backendURL}/user`,event.userAccount);
        await updateEntity("user", "users", context, props.setOutletContext);
      }
      await axios.post(`${context.backendURL}/business`,{...event});
      await updateEntity("business", "businesses", context, props.setOutletContext);
      message.success("Business Created!");
      onClose();
      setNewUser(null);
    } 
    catch(err){
      handleError("Business",err,"created",setErrorMessage);
    }
}

const updateItem = async (event) => {     
  try{
    await updateReferences(event);
    await axios.post(`${context.backendURL}/business`,{...event});
    await updateEntity("business", "businesses", context, props.setOutletContext);
    message.success("Business Updated!");
    onClose();
    setNewUser(null);
  } 
  catch(err){
    handleError("Business",err,"updated",setErrorMessage);
  }
}

  const onSubmit = async (form) => {
    let newItem = {...item};
    setErrorMessage(null);
    if(newUser && !newUser.id){
      let values = await form.validateFields();
      newItem = {...values, ...item};
      newUser.id = newUser.email;
      UserPool.signUp(newUser.email, newUser.password, [], null, async (err, data) => {
        setErrorMessage(null);
        if (err) {
            console.error(JSON.stringify(err.message, null, 2));
            if (err.message) {
                setErrorMessage(err.message);
            }
        }
        if(data) {
          setNewUser({...newUser,userSub: data.userSub});
          if (item){
            item.userAccount = {...newUser,userSub: data.userSub};
          }

          newItem = {...values,userAccount: {...newUser,userSub: data.userSub}, id: item?.id};
          if(!validate(newItem)){            
            setErrorMessage("Please fill out all required fields");
            setLoading(false);
            return;
          }
          if(!newItem?.id){    
            newItem.id = uuid(); 
            await createItem(newItem);
          }
          else{
            await updateItem(item);
          }
        }
      })
    }
    else{
      let values = form.getFieldsValue();
      newItem = {...values, ...item};
      if(!validate(newItem)){            
          setErrorMessage("Please fill out all required fields");
          setLoading(false);
          return;
      }
      if(!newItem?.id){    
        newItem.id = uuid(); 
        await createItem(newItem);
      }
      else{
        let logoURL = values.logoURL?.file ? values.logoURL.file.response : values.logoURL;
        let iconURL = newItem.iconURL?.file ? newItem.iconURL.file.response : newItem.iconURL;
        newItem = {...item,
          primaryColor:values.primaryColor,
          secondaryColor:values.secondaryColor,
          tersiaryColor:values.tersiaryColor,
          alternatingColor:values.alternatingColor,
          whiteColor:values.whiteColor,
          bodyColor:values.bodyColor,
          linkTextColor:values.linkTextColor,
          linkTextHoverColor:values.linkTextHoverColor,
          textColor:values.textColor,
          dangerColor:values.dangerColor,
          dangerHoverColor:values.dangerHoverColor,
          logoURL,
          iconURL
          };
        await updateItem(newItem);
      }
    }    
    if (typeof props?.updateGlobalStyles == "function") {
      if(context.user.roles.indexOf("business") >= 0){
        let logoURL = newItem.logoURL.file ? newItem.logoURL.file.response : newItem.logoURL;
        let iconURL = newItem.iconURL.file ? newItem.iconURL.file.response : newItem.iconURL;
        props.updateGlobalStyles(newItem.primaryColor.hex,
          newItem.secondaryColor.hex,
          newItem.tersiaryColor.hex,
          newItem.alternatingColor.hex,
          newItem.whiteColor.hex,
          newItem.bodyColor.hex,
          newItem.linkTextColor.hex,
          newItem.linkTextHoverColor.hex,
          newItem.textColor.hex,
          newItem.dangerColor.hex,
          newItem.dangerHoverColor.hex,
          logoURL,
          iconURL);
      }
    }
}

  const onFinishedFailed = (value) => {};

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() =>  {      
        if(!loading){
          onClose();
        }
      }}
      onOk={async () => {          
        setLoading(true);
        try{
          setErrorMessage(null);
          await onSubmit(form);
        }
        finally{
          setLoading(false);
        }
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} Business</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
      {loading ? <LoadingSpinner/> : <Form
        form={form}
        name="basic"
        onFinish={async () => {          
          setLoading(true);
          try{
            await onSubmit(form);
          }
          finally{
            setLoading(false);
          }
        }}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
        initialValues={initialThemeValues}
      >
      
      <Form.Item
          label="Name"
          name="label"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input placeholder="Name..." onChange={(event)=>{
            if(item){
              setItem({...item,label:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Address is required",
            },
          ]}
        >
          <Input placeholder="Address..." onChange={(event)=>{
            if(item){
              setItem({...item,address:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>
        <Form.Item
          label="Contact Person Name"
          name="contactPersonName"
          rules={[
            {
              required: true,
              message: "Contact Person Name is required",
            },
          ]}
        >
          <Input placeholder="Contact Person Name..." onChange={(event)=>{
            if(item){
              setItem({...item,contactPersonName:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>
        <Form.Item
          label="Contact Person Email"
          name="contactPersonEmail"
          rules={[
            {
              required: true,
              message: "Contact Person Email is required",
            },
          ]}
        >
          <Input placeholder="Contact Person  Email..." onChange={(event)=>{
            if(item){
              setItem({...item,contactPersonEmail:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>
        <Form.Item
          label="Contact Person Phone Number"
          name="contactPersonPhoneNumber"
          rules={[
            {
              required: true,
              message: "Contact Person Phone Number is required",
            },
          ]}
        >
          <Input placeholder="Contact Person Phone Number..." onChange={(event)=>{
            if(item){
              setItem({...item,contactPersonPhoneNumber:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>

        <Form.Item name="userAccount" label="User Account">
          {!item?.id ? 
          <Tabs activeKey={activeTab} onChange={e=>{
            setActiveTab(e);
            if(e == 'existing'){
              setNewUser(null);
            }
          }}>
            <Tabs.TabPane tab="Existing" key="existing">
            <UserAllocation ownerLabel={"User Account"} model={item?.userAccount} disabled={item?.id || loading} change={(newValue)=>{
          setItem({...item,userAccount:{id:newValue.id}})
          }}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="New" key="new">
              <Card title="User Account">
              <Form
        form={form}
        name="basic"
        onFinish={async () => {          
          setLoading(true);
          try{
            await onSubmit(form);
          }
          finally{
            setLoading(false);
          }
        }}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      <Form.Item
        label="Alias"
        name="alias"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input placeholder="Alias..." onChange={(event)=>{
          if(newUser){
            setNewUser({...newUser,alias:event.currentTarget.value});
          }
          else{
            setNewUser({alias:event.currentTarget.value});
          }
        }}
        disabled={loading}/>
      </Form.Item> 
      {!item?.id && <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Alias is required",
          },
        ]}
      >
        <Input.Password placeholder="" onChange={(event)=>{
          if(newUser){
            setNewUser({...newUser,password:event.currentTarget.value});
          }
          else{
            setNewUser({password:event.currentTarget.value});
          }
        }}
        disabled={loading}/>
      </Form.Item>} 
        <Form.Item
          label="Image"
          name="imageURL"          
        >
          <ImgCrop rotate>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          disabled={loading}
          
          showUploadList={false}
          fileList={fileList}
          onChange={handleChange}
          headers={{'Authorization':context.accessToken}}
          customRequest={req => {
            const reader = new FileReader();
            reader.readAsBinaryString(req.file);
            reader.onload = function() {
                const base64 = btoa(reader.result);
                // Force re-render of this component
                let imageURL = `data:${req.file.type};base64, ${base64}`;                
                if(newUser){
                  setNewUser({...newUser,imageURL});
                }
                else{
                  setNewUser({imageURL});
                }
                req.onSuccess(imageURL);
            };
          }}
        >
          {newUser?.imageURL ? <img src={newUser.imageURL} alt="avatar" style={{ width: '100%' }} /> : "Upload an image"}
        </Upload>
        </ImgCrop>
        </Form.Item>  
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input 
          readOnly={item?.id}
          placeholder="Email..." onChange={(event)=>{             
            if(newUser){
              setNewUser({...newUser,email:event.currentTarget.value});
            }
            else{
              setNewUser({email:event.currentTarget.value});
            }
          }}
          disabled={loading}/>
        </Form.Item>  
      <Form.Item
        label="Active"
        name="active"       
      >
        <Checkbox checked={newUser?.active} onChange={(event)=>{
            if(newUser){
              setNewUser({...newUser,active:event.target.checked});
            }
            else{
              setNewUser({active:event.target.checked});
            }
        }}
        disabled={loading}/>
      </Form.Item>  
        <Form.Item label="Roles" name="roles"
          rules={[
            {
              required: true,
              message: "Roles are required",
            },
          ]}>
          <Select mode="multiple" placeholder="Please select roles" onChange={(value)=>{
                if(newUser){
                  setNewUser({...newUser,roles:value});
                }
                else{
                  setNewUser({roles:value});
                }
              }}
              disabled={loading}>
            {context?.roles?.filter(r => r.id == "business").map((role) => {
              return (
                <Select.Option value={role.id}>{role.label}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>     
      </Form>

              </Card>
            
            </Tabs.TabPane>
          </Tabs>
        :
        <UserAllocation ownerLabel={"User Account"} model={item?.userAccount} disabled={loading || item?.id && context.user.roles.indexOf('admin') < 0} 
          change={(newValue)=>{
            setItem({...item,userAccount:{id:newValue.id}})
          }}          
          filter={(users) => {
            return users.filter(u => u.roles.includes('business'));
          }}
        />}
        </Form.Item> 

        <Card title="Theme">
          
          <Form.Item
            label="Logo"
            name="logoURL"          
          >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            
            showUploadList={false}
            fileList={fileList}
            onChange={handleChange}
            headers={{'Authorization':context?.accessToken}}
            customRequest={req => {
              const reader = new FileReader();
              reader.readAsBinaryString(req.file);
              reader.onload = function() {
                  const base64 = btoa(reader.result);
                  // Force re-render of this component
                  let logoURL = `data:${req.file.type};base64, ${base64}`;
                  setItem({...item, logoURL});
                  req.onSuccess(logoURL);
              };
          }}
          >
            {item?.logoURL ? <img src={item.logoURL} alt="avatar" style={{ width: '100%' }} /> : "Upload an image"}
          </Upload>
          </Form.Item> 

        <Form.Item
          label="Icon"
          name="iconURL"          
        >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          
          showUploadList={false}
          fileList={fileList}
          onChange={handleChange}
          headers={{'Authorization':context?.accessToken}}
          customRequest={req => {
            const reader = new FileReader();
            reader.readAsBinaryString(req.file);
            reader.onload = function() {
                const base64 = btoa(reader.result);
                // Force re-render of this component
                let iconURL = `data:${req.file.type};base64, ${base64}`;
                setItem({...item, iconURL});
                req.onSuccess(iconURL);
            };
        }}
        >
          {item?.iconURL ? <img src={item.iconURL} alt="avatar" style={{ width: '100%' }} /> : "Upload an image"}
        </Upload>
        </Form.Item> 
        
        <Form.Item
          label="Primary Color"
          name="primaryColor"
        >
          <Colorpicker popup value={item?.primaryColor ? item.primaryColor : {"hex":"#C21919"}} />
        </Form.Item>

        <Form.Item
          label="Secondary Color"
          name="secondaryColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Tersiary Color"
          name="tersiaryColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Alternating Color"
          name="alternatingColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="White Color"
          name="whiteColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Body Color"
          name="bodyColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Link Text Color"
          name="linkTextColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Link Text Hover Color"
          name="linkTextHoverColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Text Color"
          name="textColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Danger Color"
          name="dangerColor"
        >
          <Colorpicker popup />
        </Form.Item>

        <Form.Item
          label="Danger Hover Color"
          name="dangerHoverColor"
        >
          <Colorpicker popup />
        </Form.Item>
        </Card>        
      </Form>}
    </Modal>
  );
}

export default BusinessEditor;
