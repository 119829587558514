import axios from 'axios';

export const updateEntity = async (entityType, key, context, setOutletContext) => {
    let response = await axios.get(`${context.backendURL}/${entityType}`);

    let entities = response.data.Items;    
    let newOutletContext = {...context};
    newOutletContext[key] = entities;
    context[key] = entities;
    if(setOutletContext){
        setOutletContext(newOutletContext);
    }
    return newOutletContext;
}