import {
    message,
  } from "antd";
import Pool from "../../UserPool";

const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
        user.signOut();
        localStorage.removeItem("user");
    }
}

export const timeout = async (delay) => {
    return new Promise( res => setTimeout(res, delay) );
}


export const handleError = (itemName,error,action,setErrorMessage) => {
    console.log(error);
    message.error(`${itemName} could not be ${action}!`);
    
    if(error?.response?.data?.dynamoResponse?.message){
        setErrorMessage(`${itemName} could not be ${action}: ${error.response.data.dynamoResponse.message}`);
    }
    else if(error.response?.data?.message){
        setErrorMessage(`${itemName} could not be ${action}: ${error.response.data.message}`);
    }
    else if(error.message){
        setErrorMessage(`${itemName} could not be ${action}: ${error.message}`);
    }
    if(error.code == "ERR_NETWORK"){
        // logout();
        // window.location.href = "/login";
    }
}
