import { CheckOutlined, RedoOutlined } from "@ant-design/icons";

import { GiHamburgerMenu } from "react-icons/gi";

import { BsPencilSquare } from "react-icons/bs";

import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";

import { MdDeleteOutline } from "react-icons/md";
import { updateEntity } from "../utils/context-util";

import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Input,
  Button,
  Modal
} from "antd";

import React, { useEffect, useState, useCallback, useRef } from "react";

import "./TaskCrud.css";
import TaskEditor from "./editor/TaskEditor";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { handleError } from "../utils/general.util";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from "../LoadingSpinner";
import moment from "moment";
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HistoryDisplay from '../history/display/HistoryDisplay';
const type = 'DraggableBodyRow';

function UserCrud(props) {
  const context = useOutletContext();
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sortChanged, setSortChanged] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (!items) {
      loadItems(context);
    }
  }); 

  const loadItems = async (currentContext = context) => {    
    if(currentContext.backendURL){     
      try{
        let tasks = currentContext.tasks;

        let currentIsAdmin = currentContext.user.roles.indexOf('admin') >= 0;
        let currentIsClient = currentContext.user.roles.indexOf('client') >= 0;
        let currentIsBusiness = currentContext.user.roles.indexOf('business') >= 0;
        setIsAdmin(currentIsAdmin);
        if(currentIsClient){
          tasks = tasks?.filter(f => f.owner?.id === currentContext.user.id || 
            f.approvers?.filter(a => a.id === currentContext.user.id)?.length > 0 || 
            f.auditors?.filter(a => a.id === currentContext.user.id)?.length > 0 || 
            f.reviewers?.filter(a => a.id === currentContext.user.id)?.length > 0 || 
            f.testers?.filter(a => a.id === currentContext.user.id)?.length > 0)
        }
        if(currentIsBusiness){
          let clients = currentContext.clients.filter(c => c.business == currentContext.user.id);
          tasks = tasks.filter(t => clients.map(c => c.userAccount.id).includes(t.owner.id) || t.owner.id == currentContext.user.id);
        }
        tasks = tasks.sort((a, b) => {
          if(a.index == null || b.index == null){
            return 0;
          }
          return a.index - b.index;
        })
        setItems(tasks);
      } 
      catch(err){
        handleError("Tasks",err,"loaded",setErrorMessage);
      }
    }
  } 

  const columns = [
    {
      title: "Task",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item) => {
        return <UserDisplay model={item} />
      },
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (item) => (
        moment(item).format("Do MMMM YYYY")
      )
    },
    {
      title: "Completed",
      dataIndex: "isCompleted",
      key: "isCompleted",
      render: (item) => (<div>{item?<CheckOutlined />:""}</div>)
    },
    {
      title: "Actions",
      render: (item) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <GiHamburgerMenu style={{ fontSize: "2em" }} />
            </Space>
          </a>
        </Dropdown>
      ),
    }
  ];
  
  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          cursor: 'move',
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const saveSort = async () => {    
    setLoading(true);
    try{
      for(let index in items){
        let item = items[index];
        await axios.post(`${context.backendURL}/task`, item);
      }
      setSortChanged(false);    
    }
    finally{
      await updateEntity("task", "tasks", context, props.setOutletContext);
      setLoading(false);
    } 
  }

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
        const dragRow = items[dragIndex];
        let newData = update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }); 
        
        for(let index in newData){
          let nData = newData[index];
          nData.index = index;
        }
        setItems(newData);
        await updateEntity("task", "tasks", context, props.setOutletContext);
        setSortChanged(true);     
    },
    [items],
  );

  const menu = (item) => {
    return <Menu>
      <Menu.Item onClick={() => { onModify(item);}}><BsPencilSquare style={{ marginRight: ".25em" }} /> Update</Menu.Item>
      <Menu.Item onClick={() => { onRemove(item);}}><MdDeleteOutline style={{ marginRight: ".25em" }} /> Delete</Menu.Item>
    </Menu>;
  };

  const onSearch = (e) => {
    if (e.code?.toLowerCase() === "enter") {
      if (!searchVal) setItems(items);
      else {
        var tempItems = items.filter((obj) => {
          var found = false;
          for (var key in obj) {
            if (
              `${obj[key]}`.toLowerCase()?.indexOf(searchVal?.toLowerCase()) >
              -1
            )
              found = true;
            if (found) break;
          }
          return found;
        });
        setItems(tempItems);
      }
    }
  };

  const onModify = (e) => {
    if (e) setSelected(e);
    else setSelected({});
    setModalVisible(true);
  };

  const onClose = async () => {
      let newContext = await updateEntity("task", "tasks", context, props.setOutletContext);
      let tasks = newContext.tasks;
      setItems(tasks);
      setModalVisible(false);
      setSelected({});
  };

  const onRemove = async (item) => {
    if (item) {
      try{
        setLoading(true);
        await axios.delete(`${context.backendURL}/task`,{data:{id:item.id}});
        message.success("Item deleted!");
      }
      catch(error){
        handleError("Task",error,"deleted",setErrorMessage);    
      }
      finally{
        let newContext = await updateEntity("task", "tasks", context, props.setOutletContext);
        let tasks = newContext.tasks;
        setItems(tasks);
        setLoading(false);
      }
    }
  };

  return <>
  <div style={{ height: "3em" }}>
    <div style={{ float: "left", marginTop: ".5em", marginLeft: ".5em" }}>
      <Space align="center">
        <Input
          size="medium"
          placeholder="Search...."
          style={{ width: "25vw" }}
          onChange={(e) => {
            console.log(e);
            setSearchVal(e.target.value);
          }}
          onKeyDown={(e) => onSearch(e)}
        />
      </Space>
    </div>
    <div style={{ float: "right", marginTop: ".15em" }}>
      <Button
        type="link"
        className="control"
        size="large"
        icon={
          <AiOutlinePlus
            style={{ fontSize: "1.5em" }}
            onClick={() => onModify(null)}
          />
        }
      />
      <Button
        type="link"
        className="control"
        size="large"
        icon={<AiOutlineHistory style={{ fontSize: "1.5em" }} 
        onClick={()=>{
          setShowHistory(true);
        }}/>}
      />
      <Button
        onClick={async () => {
          setLoading(true);
          try{
            let newContext = await updateEntity("task", "tasks", context, props.setOutletContext);
            await loadItems(newContext);
          }
          finally{
            setLoading(false);
          }
        }}
        type="link"
        className="control"
        size="large"
        icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
      />
    </div>
  </div>
  {errorMessage && <Alert
  message="Error"
  description={errorMessage}
  type="error"
  showIcon
/>}
  {items && !loading?
  <DndProvider backend={HTML5Backend}>
  {sortChanged && <Button 
          style={{marginBottom:16, float:"left"}}
          onClick={async ()=>{
            await saveSort();
          }}>Save Sort Order</Button>}
  <Table 
    dataSource={items} 
    components={components}
    onRow={(_, index) => {
      _.index = index;                
      const attr = {
        index,
        moveRow,
      };
      return attr;
    }}
    columns={columns} />
</DndProvider> 
: <LoadingSpinner/>}  
  <TaskEditor
    isVisible={modalVisible}
    onClose={onClose}
    errorMessage={errorMessage}
    item={selected}
  ></TaskEditor>
  <Modal 
        title={"Task History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false)
          }
        }}      
      >
        <HistoryDisplay context={context} group={"task"} />
      </Modal>
</>
   
  
}

export default UserCrud;
