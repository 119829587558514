import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../utils/general.util";
import UserAllocation from "../../users/allocation/UserAllocation";
import { Form, Input, Modal, Select, Upload, Alert, message, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {v4 as uuid} from "uuid";
import InternalControlUpload from "../../internalControls/upload/InternalControlUpload";
import FileUpload from "../../upload/FileUpload";
import { updateEntity } from "../../utils/context-util";
import LoadingSpinner from '../../LoadingSpinner';

function PolicyEditor(props) {
  const context = useOutletContext();
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const [fileList, setFileList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [internalControlsToSelect, setInternalControlsToSelect] = useState([]);

  const statusses = ["Draft", "Reviewed", "Final", "Issue", "Up for review"];
  const frequencies = ["Yearly", "Quaterly", "Weekly", "Daily"];

  let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
  let currentIsClient = context?.user.roles.indexOf('client') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;
  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }   
    currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
    setIsAdmin(currentIsAdmin);
    
    let currentIsTemplate = props.item?.template != null ? props.item?.template == true ? true : false : isAdmin ? true : false
    setIsTemplate(currentIsTemplate);

    if(currentIsClient){
      setInternalControlsToSelect(context?.policies?.filter(f => f.owner?.id == context?.user.id || 
        f.template == true ||
        f.approvers?.filter(a => a.id == context?.user.id)?.length > 0 || 
        f.auditors?.filter(a => a.id == context?.user.id)?.length > 0 || 
        f.reviewers?.filter(a => a.id == context?.user.id)?.length > 0 || 
        f.testers?.filter(a => a.id == context?.user.id)?.length > 0)
        .flatMap(i => i.internalControls)
        .map(c => context?.internalControls.find(ic => ic.id ==c)))
    } else if(currentIsAdmin || currentIsBusiness){
      let testOwner = props.item?.owner;     
      if(testOwner){
        setInternalControlsToSelect(context?.policies?.filter(f => f.owner?.id == testOwner.id || 
          f.template == true ||
          f.approvers?.filter(a => a.id == testOwner.id)?.length > 0 || 
          f.auditors?.filter(a => a.id == testOwner.id)?.length > 0 || 
          f.reviewers?.filter(a => a.id == testOwner.id)?.length > 0 || 
          f.testers?.filter(a => a.id == testOwner.id)?.length > 0)
          .flatMap(i => i.internalControls)
          .map(c => context?.internalControls.find(ic => ic.id ==c)));
      }      
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const onClose = async (newItem) => {
    if (typeof props?.onClose == "function") {
      await props?.onClose(newItem);
    }
    if(form?.resetFields){
      form.resetFields();
    }
    setItem({});
    setLoading(false);
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    pass = values.label != null && 
    values.owner != null && 
    values.body != null &&
    values.reviewFrequency != null &&
    values.shortDescription != null &&
    values.status != null &&
    values.versionNumber != null;
    return pass;
  }

  const createItem = async (event) => {
    try{
      let itemResponse = await axios.post(`${context.backendURL}/policy`,{...event});
      onClose(JSON.parse(itemResponse.data.body));
      message.success("Policy Created!");
    } 
    catch(err){
      handleError("Policy",err,"created",setErrorMessage);
    }
}

const updateItem = async (event) => {
  try{
    let itemResponse = await axios.post(`${context.backendURL}/policy`,{...event});
    onClose(JSON.parse(itemResponse.data.body));
    message.success("Policy Updated!");
  } 
  catch(err){
    handleError("Policy",err,"updated",setErrorMessage);
  }
}

  const onSubmit = async (event) => {
    setLoading(true);
    let templatesToGenerate = isTemplate ? [] : item.internalControls.map(i => context.internalControls.find(c => c.id == i) ? context.internalControls.find(c => c.id == i) : i).filter(i => i?.template);
    let icsToSave = isTemplate ? item.internalControls : item.internalControls.map(i => context.internalControls.find(c => c.id == i) ? context.internalControls.find(c => c.id == i) : i).filter(i => i?.template != true);
    icsToSave = icsToSave ? icsToSave : []
    let newIcs = templatesToGenerate.map(ic => {
      let newIC = {...ic} 
      newIC.id = uuid();
      newIC.owner = item.owner;
      newIC.template = isTemplate;
      return newIC
    });

    for(let icIndex in newIcs){
      let newIc = newIcs[icIndex];
      let icResponse = await axios.post(`${context.backendURL}/internal-control`,{...newIc});
      newIcs[icIndex] = icResponse;
    }
    icsToSave = [...icsToSave ,...newIcs];
    
    item.internalControls = icsToSave.map(i => i.id ? i.id : i);
    
    let newItem = {...event, ...item};

    if(!validate(newItem)){            
      setErrorMessage("Please fill out all required fields");
      setLoading(false);
      return;
    }
    
    try{
      if(!newItem?.id){    
        newItem.id = uuid(); 
        newItem.template = isTemplate;
        newItem.new = true;
        await createItem(newItem);
      }
      else{
        await updateItem(item);
      }
    }
    finally{
      let newContext = await updateEntity("policy", "policies", context, props.setOutletContext);
      await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
    }
   
  }

  const onFinishedFailed = (value) => {};

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose()
        }
      }}
      onOk={async () => {        
        try{
          setLoading(true);
          setErrorMessage(null);
          let values = await form.validateFields();
          await onSubmit(values,form);
        }
        catch(err){
          if(err.errorFields){
            setErrorMessage(`Please fill out all fields:${err.errorFields?.map(e => e.name).join(", ")}`);
            setLoading(false);
          }
          else{
            setErrorMessage(err.message);
            setLoading(false);
          }
        }
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} Policy {isTemplate ? "Template" : "Instance"}</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
      {loading && <LoadingSpinner topPadding={"15%"}/> }
      {!loading && <Form
        form={form}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
      <Form.Item
        label="Title"
        name="label"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input placeholder="Title..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
      </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserAllocation model={item?.owner} change={(newValue)=>{
            if(item){
              setItem({...item,owner:{id:newValue.id}});
              form.setFieldValue("owner",{id:newValue.id});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserAllocation model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item
          label="Short Description"
          name="shortDescription"
          rules={[
            {
              required: true,
              message: "Short description is required",
            },
          ]}
        >
          <Input placeholder="Short Description..." onChange={(event)=>{
            if(item){
              setItem({...item,shortDescription:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Version"
          name="versionNumber"
          rules={[
            {
              required: true,
              message: "Version is required",
            },
          ]}
        >
          <Input placeholder="Version..." onChange={(event)=>{
            if(item){
              setItem({...item,versionNumber:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item label="Status" name="status" initialValue={item?.status} 
          rules={[
            {
              required: true,
              message: "Status is required",
            },
          ]}>
          <Select onChange={(value)=>{
                if(item){
                  setItem({...item,status:value});
                }
              }}>
            {statusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Review Frequency" name="reviewFrequency"  
          rules={[
            {
              required: true,
              message: "Review Frequency is required",
            },
          ]}>
          <Select mode="multiple" placeholder="Please select review frequency" onChange={(value)=>{
                if(item){
                  setItem({...item,reviewFrequency:value});
                }
              }}>
            {frequencies?.map((frequency) => {
              return (
                <Select.Option value={frequency}>{frequency}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Body" name="body"  
          rules={[
            {
              required: true,
              message: "Body is required",
            },
          ]}>
          <TextArea rows={4} placeholder="Body...." onChange={(event)=>{
            if(item){
              setItem({...item,body:event.currentTarget.value});
            }
          }}/>
        </Form.Item>        
        <Form.Item label="Internal Controls" name="internalControls">
        <Select
            mode="multiple"
            placeholder="Please select internal controls to link"
            value={item?.internalControls}
            onChange={(value)=>{
              if(item){
                setItem({...item,internalControls:value});
              }
            }}
            filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.title ?? '').toLowerCase().localeCompare((optionB?.title ?? '').toLowerCase())
            }
          >
            {internalControlsToSelect?.filter(control => control != null).map((control) => {
              return (
                <Select.Option value={control.id} title={control.label}>
                    {control.label}{control.template ? " - Template" : ""}
                </Select.Option>
              );
            })}
          </Select>
            <InternalControlUpload setOutletContext={props.setOutletContext} isAdmin={isAdmin} isTemplate={isTemplate} model={item} onChange={async (value) => {
              await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
              setItem(value);
              }}/>
        </Form.Item>
        <Form.Item label="Files" name="files">            
            <FileUpload model={item} onChange={async (value) => {
              setItem(value);
              }}/>
        </Form.Item>
      </Form>}
    </Modal>
  );
}

export default PolicyEditor;
