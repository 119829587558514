import {
  Form,
  Input,
  Modal,
  Alert,
  DatePicker,
  Tabs,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../../utils/general.util";
import UserAllocation from "../../../users/allocation/UserAllocation";
import InternalControlUpload from "../../../internalControls/upload/InternalControlUpload";
import moment from "moment";
import LoadingSpinner from "../../../LoadingSpinner";
import { updateEntity } from "../../../utils/context-util";
import {v4 as uuid} from "uuid";
import axios from "axios";


function FrameworkEditor(props) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const context = useOutletContext();
  let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
  let currentIsBusiness = context?.user.roles.indexOf('business') >= 0;
  let currentIsClient = context?.user.roles.indexOf('client') >= 0;
  let currentIsTemplate = props.item?.template == true ? true : false;
  currentIsTemplate = props.item?.id == null ? !currentIsClient : currentIsTemplate;

  useEffect(() => {
    var framework = {
      version: "",
      label: "",
    };
    setIsAdmin(currentIsAdmin); 

    if (props.item) {
      framework = props.item;
    }

    form.setFieldsValue(framework);
  }, [props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);


  const onClose = async (value) => {
    if (typeof props?.onClose == "function") {
      await props?.onClose(value);
    }
  };

  const onSubmit = async (value) => {
    try{
      setLoading(true);
      if (!value?.owner) {
        value.owner = {
          id: selectedUser?.id,
        };
      }
  
      if(typeof props.item.startDate === 'string'){
        value.startDate = moment(props.item.startDate) 
      }
  
      if(typeof props.item.endDate === 'string'){
        value.endDate = moment(props.item.endDate) 
      }
      let item = {...props.item,...value};
      if(!currentIsTemplate){
        let risksTemplatesToGenerate = item.risks?.map(i => context.risks.find(c => c.id == i)).filter(i => i.template);
        let risksToSave = item.risks?.map(i => context.risks.find(c => c.id == i)).filter(i => i.template != true);
        
        let newRisks = risksTemplatesToGenerate?.map(risk => {
          let newRisk = {...risk} 
          newRisk.id = uuid();
          newRisk.owner = item.owner;
          newRisk.template = false;
          return newRisk
        });
  
        for(let riskIndex in newRisks){
          let newRisk = newRisks[riskIndex];
          await axios.post(`${context.backendURL}/risk`,{...newRisk});
        }
        newRisks = newRisks ? newRisks : []
        risksToSave = risksToSave ? risksToSave : []
        risksToSave = [...risksToSave ,...newRisks];
        
        item.risks = risksToSave.map(i => i.id);
  
  
  
        let policiesTemplatesToGenerate = item.policies?.map(i => context.policies.find(c => c.id == i)).filter(i => i.template);
        let policiesToSave = item.policies?.map(i => context.policies.find(c => c.id == i)).filter(i => i.template != true);
        
        let newPolicies = policiesTemplatesToGenerate?.map(policy => {
          let newPolicy = {...policy} 
          newPolicy.id = uuid();
          newPolicy.owner = item.owner;
          newPolicy.template = false;        
          return newPolicy
        });
  
        for(let policyIndex in newPolicies){
          let newPolicy = newPolicies[policyIndex];
          if(newPolicy.internalControls?.length > 0){
            newPolicy.internalControls = newPolicy.internalControls.map(i => context.internalControls.find(c => c.id == i)).filter(i => i != undefined);
            for(let icIndex in  newPolicy.internalControls){
              let ic = newPolicy.internalControls[icIndex];
              if(ic.template == true){
                let newIc = ic;
                newIc.template = false;
                newIc.id = uuid(); 
                newIc.owner = item.owner;
                await axios.post(`${context.backendURL}/internal-control`,newIc);
                newPolicy.internalControls[icIndex] = newIc.id;
              }
            }            
          }
          await axios.post(`${context.backendURL}/policy`,{...newPolicy});
        }
  
        policiesToSave = policiesToSave == null ? [] : policiesToSave;
        newPolicies = newPolicies == null ? [] : newPolicies;
        policiesToSave = [...policiesToSave ,...newPolicies];
        
        item.policies = policiesToSave.map(i => i.id);
        await updateEntity("risk", "risks", context, props.setOutletContext);
        await updateEntity("policy", "policies", context, props.setOutletContext);
        await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
        await updateEntity("control-framework", "controlFrameworks", context, props.setOutletContext);
      }
      item.template = currentIsTemplate;
      await onClose(item);
    }
    finally{
      setLoading(false);
    }
  };

  const onFinishedFailed = (value) => {};
  
  const frameworkForm = () => {
    return <Form
    form={form}
    name="basic"
    onFinish={onSubmit}
    onFinishFailed={onFinishedFailed}
    autoComplete="off"
    layout="vertical"
  >
    <Form.Item
      label="Name"
      name="label"
      rules={[
        {
          required: true,
          message: "Name is required",
        },
      ]}
    >
      <Input
        placeholder="Name..."
        onChange={(event) => {
          if (props.item) {
            props.setItem({ ...props.item, label: event.currentTarget?.value });
          }
        }}
      />
    </Form.Item>
    <Form.Item
      label="Version"
      name="version"
      rules={[
        {
          required: true,
          message: "Version is required",
        },
      ]}
    >
      <Input
        placeholder="Version..."
        onChange={(event) => {
          if (props.item) {
            props.setItem({ ...props.item,version: event.currentTarget?.value });
          }
        }}
      />
    </Form.Item>
    <Form.Item
      label="Start Date"
      rules={[
        {
          required: true,
          message: "Start Date is required",
        },
      ]}
    >
      <DatePicker
      value={props.item?.startDate ? moment(props.item.startDate): null}
        onChange={(event) => {
          if (props.item) {            
            props.setItem({ ...props.item, startDate: event });
            form.setFieldValue("startDate",event.toISOString());
          }
        }}/>
    </Form.Item>
    <Form.Item
      label="End  Date"
      rules={[
        {
          required: true,
          message: "End  Date is required",
        },
      ]}
    >
      <DatePicker
      value={props.item?.endDate ? moment(props.item.endDate): null}
        onChange={(event) => {
          if (props.item) {
            props.setItem({ ...props.item, endDate: event });
            form.setFieldValue("endDate", event.toISOString());
          }
        }}
      />
    </Form.Item>

    <Form.Item
    label="Owner"
    name="owner"
    rules={[
      {
        required: true,
        message: "Owner is required",
      },
    ]}
  >
  <UserAllocation model={props.item?.owner} change={(newValue)=>{
      if(props.item){
        props.setItem({...props.item, owner:{id:newValue.id}});
        form.setFieldValue("owner",{id:newValue.id});
      }
    }}/>
    </Form.Item>
    <Form.Item
      label="Users"
    >
    <UserAllocation model={props.item} config={{ multiple: true }}/>
    </Form.Item>

    <Form.Item label="Policies" name="policies">
      <Select
          mode="multiple"
          placeholder="Please select internal controls to link"
          value={props.item?.policies}
          onChange={(value)=>{
            if(props.item){
              props.setItem({...props.item,policies:value});
            }
          }}
          filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}            
          filterSort={(optionA, optionB) =>
            (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
          }
        >
          {context?.policies?.filter(c => currentIsTemplate ? c.template : true).map((control) => {
            return (
              <Select.Option value={control.id}>
                  {control.label}{control.template ? " - Template" : ""}
              </Select.Option>
            );
          })}
        </Select>
    </Form.Item>

    <Form.Item label="Risks" name="risks">
      <Select
          mode="multiple"
          placeholder="Please select risks to link"
          value={props.item?.risks}
          onChange={(value)=>{
            if(props.item){
              props.setItem({...props.item,risks:value});
            }
          }}
          filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
          }
        >
          {context?.risks?.filter(c => currentIsTemplate ? c.template : true).map((risk) => {
            return (
              <Select.Option value={risk.id}>
                  {risk.label}{risk.template ? " - Template" : ""}
              </Select.Option>
            );
          })}
        </Select>
    </Form.Item>
  </Form>
  }

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          form.resetFields();
          onClose();
        }
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          form.resetFields();
          onSubmit(values);
        });
      }}
      title={
        <div>
          <h4>{props.item?.id ? "Edit" : "Add"} Framework {currentIsTemplate ? "Template" : "Instance"}</h4>
        </div>
      }
    >
      {errorMessage && (
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}

      {loading && <LoadingSpinner/>}
      {!loading && props.item?.id &&
        frameworkForm()
      }
      {!loading && !props.item?.id &&
        <Tabs>
          <Tabs.TabPane tab="Existing" key={1}>
            <Form
              form={form}
              name="basic"
              onFinish={onSubmit}
              onFinishFailed={onFinishedFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item label="From existing framework">
                <Select
                  placeholder="Select existing framework"
                  onChange={(val) => {
                    let framework = context?.frameworks?.find((f) => f.id == val);
                    if (framework){
                      let item = {...framework,
                        template: currentIsClient ? true : framework.template,
                        owner: {id: context.user.email},
                        id:null,
                        key:uuid()
                      };
                      onSubmit(item);
                    }
                  }}
                  filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
                  }
                >
                  {context?.frameworks.filter(f => !currentIsClient || !f.template)?.map((e) => {
                    return <Select.Option key={e.id}>{e.label}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="New" key={2}>
          {frameworkForm()}
          </Tabs.TabPane>
        </Tabs>
      }
    </Modal>
  );
}

export default FrameworkEditor;
