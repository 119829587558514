import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Select, Upload, Alert } from "antd";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from '../LoadingSpinner';

const { TextArea } = Input;

function PolicyDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [editing, setEditing] = useState(false);

  const statusses = ["Draft", "Reviewed", "Final", "Issue", "Up for review"];
  const frequencies = ["Yearly", "Quaterly", "Weekly", "Daily"];
  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
                <Form.Item
        label="Title"
        name="label"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input  
            disabled={!editing}
            placeholder="Title..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
      </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserDisplay model={item?.owner} change={(newValue)=>{
            if(item){
              setItem({...item,owner:{id:newValue.id}});
              form.setFieldValue("owner",{id:newValue.id});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserDisplay model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item
          label="Short Description"
          name="shortDescription"
          rules={[
            {
              required: true,
              message: "Short description is required",
            },
          ]}
        >
          <Input  
            disabled={!editing}
            placeholder="Short Description..." onChange={(event)=>{
            if(item){
              setItem({...item,shortDescription:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Version"
          name="versionNumber"
          rules={[
            {
              required: true,
              message: "Version is required",
            },
          ]}
        >
          <Input  
            disabled={!editing}
            placeholder="Version..." onChange={(event)=>{
            if(item){
              setItem({...item,versionNumber:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item label="Status" name="status" initialValue={item?.status} 
          rules={[
            {
              required: true,
              message: "Status is required",
            },
          ]}>
          <Select 
            disabled={!editing}
            onChange={(value)=>{
                if(item){
                  setItem({...item,status:value});
                }
              }}>
            {statusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Review Frequency" name="reviewFrequency"  
          rules={[
            {
              required: true,
              message: "Review Frequency is required",
            },
          ]}>
          <Select 
            disabled={!editing}
            mode="multiple" placeholder="Please select review frequency" onChange={(value)=>{
                if(item){
                  setItem({...item,reviewFrequency:value});
                }
              }}>
            {frequencies?.map((frequency) => {
              return (
                <Select.Option value={frequency}>{frequency}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Body" name="body"  
          rules={[
            {
              required: true,
              message: "Body is required",
            },
          ]}>
          <TextArea rows={4}
            disabled={!editing} placeholder="Body...." onChange={(event)=>{
            if(item){
              setItem({...item,body:event.currentTarget.value});
            }
          }}/>
        </Form.Item>        
        <Form.Item label="Internal Controls" name="internalControls">
        <Select
            mode="multiple"
            disabled={!editing}
            placeholder="Please select internal controls to link"
            value={item?.internalControls}
            onChange={(value)=>{
              if(item){
                setItem({...item,internalControls:value});
              }
            }}
            filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.title ?? '').toLowerCase().localeCompare((optionB?.title ?? '').toLowerCase())
            }
          >
            {item?.internalControls?.map((controlId) => {
              let control = context?.internalControls.find(ic => ic.id == controlId)
              if(!control){
                return <Select.Option value={controlId} title={"NOT FOUND"}>
                NOT FOUND
            </Select.Option>
              }
              return (
                <Select.Option value={control.id} title={control.label}>
                    {control.label}{control.template ? " - Template" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Files" name="files">            
            {props.item?.fileList?.map(f => <a href={f.url} target="_blank">{f.name}</a>)}
        </Form.Item>
      </Form>}
    </div>

  );
}

export default PolicyDisplay;
