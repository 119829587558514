import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { Button } from "antd";
import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { useOutletContext } from "react-router-dom";

import "./Status.css";

const Status = (props) => {
    const { logout } = useContext(AccountContext);
    const [frameworkHidden, setFrameworkHidden] = useState(true);
    const context = useOutletContext();

    const onFrameworkClose = () => {
        setFrameworkHidden(!frameworkHidden);
        if (typeof props?.setCollapsed == "function")
            props.setCollapsed(false);
    }

     return <>
        <div style={{ float: "left", position: "absolute" }}>
            <div style={{ paddingLeft:8,color: "black", fontSize: "2em" }}>
            <>
                    {props.context?.showFrameworkSelection == true && <MenuUnfoldOutlined style={{
                        marginRight: ".5em",
                        marginLeft: ".25em",
                        cursor: "pointer",
                        color:"white"
                    }}
                        hidden={!props.collapsed}
                        onClick={() => { onFrameworkClose() }} />}
                    <span className="header-text">{props?.context?.label || ""}</span>
            </>
            </div>
        </div>
        <div style={{ float: "right" }}>
            <div style={{ fontSize: "2em", color: "white" }}>
                {props.context?.user?.alias}
                {props.context?.user?.roles?.map(p => { return <Tag style={{marginLeft:8}} key={p}>{props.context?.roles?.find(r => r.id == p)?.label}</Tag> })}
                <Button type="primary" danger onClick={() => {
                    props.setLoggedIn(false);
                    props.context.selectedFramework = null;
                    props.context.label = null;
                    logout();
                }}>Logout</Button>
            </div>
        </div>
    </>

};
export default Status;