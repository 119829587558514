import { Avatar,  Menu, Layout, Divider, Modal } from 'antd';
import { HomeFilled, ScheduleOutlined, FileProtectOutlined, LineChartOutlined, SafetyCertificateOutlined, ContactsOutlined , ShopOutlined, UserOutlined, FieldTimeOutlined, FileDoneOutlined, CloudSyncOutlined  } from '@ant-design/icons';
import React ,{ useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import HistoryDisplay from '../history/display/HistoryDisplay';
import { handleError } from "../utils/general.util";
import UserEditor from "../users/editor/UserEditor";
import { updateEntity } from "../utils/context-util";
//STYLE
import './Navigation.css';
const { Sider } = Layout;


function getItem(label, key, icon, children, type) {
  return {
    key, icon, children, label, type
  }
}


const footerItems = [
  getItem("History", "history", <FieldTimeOutlined style={{ fontSize: "2em" }} />)
]


const toTitleCase = (str) => {
  str = str.replace("-"," ");
  if(str.length > 1){
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }
  return str.toUpperCase();
}



export const Navigation = (props) => {
  const [showHistory, setShowHistory] = useState(false);
  const [historyId, setHistoryId] = useState(null);
  const [historyGroup, setHistoryGroup] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [userEditorUser, setUserEditorUser] = useState(null);
  const [loading, setLoading] = useState(false);

  let loaded = false;

  let navigate = useNavigate();

  useEffect(() => {
    if (props.context.accessToken) {
      axios.defaults.headers.common["Authorization"] = props.context.accessToken;
      if (!loaded) {
        loadItems();
      }
    }
  });

  const loadItems = async (currentContext = props.context) => {
    if (currentContext.backendURL) {
      setLoading(true);
      loaded = true;
      try {
        let user = currentContext.user;
        let currentIsAdmin = user.roles.indexOf('admin') >= 0;
        setIsAdmin(currentIsAdmin);
        let currentIsBusiness = user.roles.indexOf('business') >= 0;
        setIsBusiness(currentIsBusiness);
      } catch (err) {
        handleError("Frameworks", err, "loaded", setErrorMessage);
      }
      finally{
        setLoading(false);
      }
    }
  };

  const mainItems = () => {  
    let items = [];
    if(isAdmin){
      items = [
        getItem("Home", "", <HomeFilled style={{ fontSize: "2em" }} />),
        getItem("Policies", "policies", <FileDoneOutlined style={{ fontSize: "2em" }} />),
        getItem("Risks", "risks", <SafetyCertificateOutlined style={{ fontSize: "2em" }} />),
        getItem("Frameworks", "frameworks", <FileProtectOutlined   style={{ fontSize: "2em" }} />),
        getItem("Task List", "task-list", <ScheduleOutlined  style={{ fontSize: "2em" }} />),
        getItem("Businesses", "businesses", <ShopOutlined  style={{ fontSize: "2em" }} />),
        getItem("Clients", "clients", <ContactsOutlined  style={{ fontSize: "2em" }} />),
        getItem("Users", "users", <UserOutlined  style={{ fontSize: "2em" }} />),
        getItem("Reporting", "reporting", <LineChartOutlined style={{ fontSize: "2em" }} />)
        // getItem("CO2 Emissions", "co2", <CloudSyncOutlined  style={{ fontSize: "2em" }} />)
      ];
    }
    else if(isBusiness){
      items = [
        getItem("Home", "", <HomeFilled style={{ fontSize: "2em" }} />),
        getItem("Policies", "policies", <FileDoneOutlined style={{ fontSize: "2em" }} />),
        getItem("Risks", "risks", <SafetyCertificateOutlined style={{ fontSize: "2em" }} />),
        getItem("Frameworks", "frameworks", <FileProtectOutlined   style={{ fontSize: "2em" }} />),
        getItem("Task List", "task-list", <ScheduleOutlined  style={{ fontSize: "2em" }} />),
        getItem("Clients", "clients", <ContactsOutlined  style={{ fontSize: "2em" }} />),
        getItem("Users", "users", <UserOutlined  style={{ fontSize: "2em" }} />),
        getItem("Reporting", "reporting", <LineChartOutlined style={{ fontSize: "2em" }} />)
        // getItem("CO2 Emissions", "co2", <CloudSyncOutlined  style={{ fontSize: "2em" }} />)
      ];
    }
    else{
      items = [
        getItem("Home", "", <HomeFilled style={{ fontSize: "2em" }} />),
        getItem("Policies", "policies", <FileDoneOutlined style={{ fontSize: "2em" }} />),
        getItem("Risks", "risks", <SafetyCertificateOutlined style={{ fontSize: "2em" }} />),
        getItem("Frameworks", "frameworks", <FileProtectOutlined   style={{ fontSize: "2em" }} />),
        getItem("Task List", "task-list", <ScheduleOutlined  style={{ fontSize: "2em" }} />),
        getItem("Users", "users", <UserOutlined  style={{ fontSize: "2em" }} />),
        getItem("Reporting", "reporting", <LineChartOutlined style={{ fontSize: "2em" }} />)
        // getItem("CO2 Emissions", "co2", <CloudSyncOutlined  style={{ fontSize: "2em" }} />)
      ];
    }
    
    return items;
  }

  const onMenuClick = (navigate,e) => {
    switch(e.key){      
      case 'history':
        setHistoryId(props.context?.user?.id)
        setShowHistory(true);
        break;
      case '':
        props.setOutletContext({
          ...props.context,
          label: props.context?.selectedFramework ? props.context.selectedFramework.label : '',
          showFrameworkSelection: true
        });
        props.setFrameworkSelectionCollapsed(false);
        break;
      // case 'co2':
      //     props.setOutletContext({
      //       ...props.context,
      //       label:'CO2 Emissions',
      //       showFrameworkSelection: false 
      //     });
      //     break;
      case 'reporting':
          props.setOutletContext({
            ...props.context,
            label:'Reporting',
            showFrameworkSelection: true 
          });
          break;
    default:
      props.setOutletContext({
        ...props.context,
        label:toTitleCase(e.key),
        showFrameworkSelection: false
      });
      props.setFrameworkSelectionCollapsed(true);
    }
    if(e.key != 'history'){
      navigate(e.key);      
    }
  }
  return (
    <>
        <Modal 
          title={"GRC History"}
          visible={showHistory}
          width={1000}
          closable={false}
          onOk={() => {
            setHistoryId(null)
            setHistoryGroup(null)
            setShowHistory(false)
          }}  
          onCancel={() => {
            if(!loading){
              setHistoryId(null)
              setHistoryGroup(null)
              setShowHistory(false)
            }
          }}      
        >
          <HistoryDisplay context={props.context} group={historyGroup} id={historyId} />
        </Modal>
      <div className="leaf-icon"/>
      <Sider collapsible collapsed={props.collapsed} onCollapse={(value) => props.setCollapsed(value)}>
      <Menu
        items={mainItems()}
        mode="inline"
        theme="dark" 
        onClick={(e) => onMenuClick(navigate,e)} />  
        <Divider style={{background:"white"}}/>
      <Menu items={footerItems}
          theme="dark"
          mode='inline'
          // style={{ float: "right" }}
          onClick={(e) => onMenuClick(navigate,e)} />
          <Avatar onClick={() => {
            setUserEditorUser(props.context.user);
          }}
          size={{
            xs: 50,
            sm: 50,
            md: 50,
            lg: 50,
            xl: 50,
            xxl: 70,
          }}
            src={props.context.user?.imageURL}
            className='user' />
            <UserEditor
              isVisible={userEditorUser != null}
              context={props.context}
              onClose={async (item) => {
                setLoading(true);
                try{
                  setUserEditorUser(null);
                }
                finally{
                  await updateEntity("user", "users", props.context, props.setOutletContext);
                  let newOutletContext = {...props.context};
                  newOutletContext["user"] = item;
                  props.context["user"] = item;
                  if(props.setOutletContext){
                    props.setOutletContext(newOutletContext);
                  }
                  setLoading(false);
                }
              }}
              errorMessage={errorMessage}
              item={userEditorUser}
            />
        </Sider>

    </>
  )
}
