import './App.css';
import 'antd/dist/antd.css';
import React, { useState } from "react";
import Signup from './components/account/Signup';
import Login from './components/Login';
import Blank from './components/Blank';
import Workspace from './components/Workspace';
import { Account } from './components/account/Account';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Compliance from './components/compliance/Compliance';
import PolicyCrud from './components/policies/PolicyCrud';
import ClientCrud from './components/clients/ClientCrud';
import BusinessCrud from './components/businesses/BusinessCrud';
import TaskCrud from './components/tasks/TaskCrud';
import UserCrud from './components/users/UserCrud';
import RiskCrud from './components/risks/RiskCrud';
import FrameworkCrud from './components/framework/crud/FrameworkCrud';
import { getBackendUrl } from "./backend-util";
import { createGlobalStyle } from 'styled-components';
import Reporting from './components/reporting/Reporting';
import LandingPage from './components/home/landingPage/LandingPage';
import ContactUs from './components/home/contactUs/ContactUs';
import RequestDemo from './components/home/requestDemo/RequestDemo';

const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;


const full = `${protocol}//${domain}:${port? port : ""}`

let GlobalStyles = createGlobalStyle`
:root {
  --primary-color:#139901;
  --secondary-color:#504b3a;
  --tersiary-color:#312e24;
  --alternating-color:#4f8d58;
  --white-color:#ffffff;
  --body-color:#f5f8f7;
  --link-text-color:#5a8b20;
  --link-text-hover-color:#139901;
  --text-color:#5a8b20;
  --danger-color:#9c0000;
  --danger-color-hover:#cf0000;
  --logo-url:url('${full}/logo.png');
  --icon-url:url('${full}/icon.png');
}
`;

const updateGlobalStyles = (
  primaryColor="#139901",
  secondaryColor="#504b3a",
  tersiaryColor="#312e24",
  alternatingColor="#4f8d58",
  whiteColor="#ffffff",
  bodyColor="#f5f8f7",
  linkTextColor="#5a8b20",
  linkTextHoverColor="#139901",
  textColor="#5a8b20",
  dangerColor="#9c0000",
  dangerHoverColor="#cf0000",
  logoURL=`${full}/logo.png`,
  iconURL=`${full}/icon.png`) => {
    GlobalStyles = createGlobalStyle`
    :root {
      --primary-color:${primaryColor};
      --secondary-color:${secondaryColor};
      --tersiary-color:${tersiaryColor};
      --alternating-color:${alternatingColor};
      --white-color:${whiteColor};
      --body-color:${bodyColor};
      --link-text-color:${linkTextColor};
      --link-text-hover-color:${linkTextHoverColor};
      --text-color:${textColor};
      --danger-color:${dangerColor};
      --danger-color-hover:${dangerHoverColor};
      --logo-url:url('${logoURL}');
      --icon-url:url('${iconURL}');
    }
  `
}


function App() {
  const [context, setOutletContext] = useState({label:"",backendURL:getBackendUrl()});  
  return (
    <>
      <GlobalStyles />
      <Account backendURL={getBackendUrl()}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/request-demo" element={<RequestDemo />} />
            <Route path="/workspace" element={<Workspace context={context} updateGlobalStyles={updateGlobalStyles} setOutletContext={setOutletContext} backendURL={getBackendUrl()}/>} >
                <Route path="" element={<Compliance context={context} setOutletContext={setOutletContext} />}/>
                <Route path="frameworks" element={<FrameworkCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="policies" element={<PolicyCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="clients" element={<ClientCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="businesses" element={<BusinessCrud context={context} updateGlobalStyles={updateGlobalStyles} setOutletContext={setOutletContext} />}/>
                <Route path="users" element={<UserCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="reporting" element={<Reporting />}/>
                <Route path="task-list" element={<TaskCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="risks" element={<RiskCrud context={context} setOutletContext={setOutletContext} />}/>
                <Route path="co2" element={<Blank />}/>
            </Route>
          </Routes>
        </Router>
      </Account>
    </>
  );
}

export default App;
