import {
    Table,
    Button,
    Modal,
    Alert,
    message
  } from "antd";
  import React, { useEffect, useState } from "react";
  import moment from "moment";
  import axios from "axios";
  import { handleError } from "../../utils/general.util";
  import _ from "lodash";
  import "./HistoryDisplay.css";
  import LoadingSpinner from "../../LoadingSpinner";

  import DiscussionDisplay from "../../discussion/display/DiscussionDisplay";
import UserDisplay from "../../users/display/UserDisplay";
  
  const HistoryDisplay = (props) => {
    const [items , setItems] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showDiscussions, setShowDiscussions] = useState(null);
    const [id, setId] = useState(null);
    const [group, setGroup] = useState(null);
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasAdmin, setHasAdmin] = useState(false);
    const [user, setUser] = useState(null);
    let loadingItems = false;

    let currentUser = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
      if(props.context?.accessToken){
        axios.defaults.headers.common['Authorization'] = props.context.accessToken;  
        if ((!items || props.id != id || props.group != group) && !loadingItems) {
          loadItems();
          setId(props.id);
          setGroup(props.group);
        }
      }
    }); 
  
    const loadItems = async (currentContext = props.context) => {    
      if(currentContext?.backendURL){     
        try{
          setLoading(true);
          loadingItems = true;
          let historyResponse = await axios.get(`${currentContext.backendURL}/history`);
          let items = historyResponse.data.Items;
          let users = currentContext.users;
          items = _.orderBy(items, ['createDate'],['desc']);
          items = items.map(i => {return {...i, key: i.id}});

          let userResponse = await axios.get(`${currentContext.backendURL}/user?id=${currentUser.idToken.payload.email}`);
          let user = userResponse.data.Item;
          setUser(user);
          let currentHasAdmin = user.roles.indexOf('admin') >= 0;
          let currentHasBusiness = user.roles.indexOf('business') >= 0;
          let currentHasClient = user.roles.indexOf('client') >= 0;
          setHasAdmin(currentHasAdmin);
          
          setUsers(users);
          if(group){
            items = items.filter(i => i.itemType == group);
          }
          if(currentHasClient){
            items = items.filter(i => i.userSub == user.userSub);
          }
          if(currentHasBusiness){
            let clients = currentContext.clients.filter(c => c.business == currentContext.user.id);
            items = items.filter(f => clients.map(c => c.userAccount.userSub).includes(f.userSub) || f.userSub == currentContext.user.userSub);
          }
          setItems(items);
        } 
        catch(err){
          handleError("History",err,"loaded",setErrorMessage);
        }
        finally{
          setLoading(false);
          loadingItems = false;
        }
      }
    } 

    
    const columns = [
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
      },
      {
        title: "Event",
        dataIndex: "event",
        key: "event",
      },
      {
        title: "Date",
        dataIndex: "createDate",
        key: "createDate",
        render: (item) => (
          moment(item).format("Do MMMM YYYY - HH:mm:ss")
        )
      },
      {
        title: "User",
        dataIndex: "userSub",
        key: "userSub",
        render: (item) => (
          <UserDisplay model={users?.find(u => u.userSub == item)} context={props.context}/>
        )
      },
      {
        title: "Actions",
        render: (item) => (
          <div style={{display:"grid"}}>          
          <Button onClick={() => {        
            setShowDiscussions(item.id)
          }}>
            Show Discussion
          </Button>
          {item.event == "deleted" && hasAdmin && <Button style={{marginTop:8}}
          onClick={async () => {
            try{
              setLoading(true);
              await axios.post(`${props.context.backendURL}/restore`,{
                "id":item.itemId,
                "itemType": item.itemType
              });
              loadItems();
            }
            catch(err){
              setErrorMessage(err.message)
            }
            finally{
              setLoading(false);
            }
          }}>
          Restore
          </Button>}
          </div>
        ),
      }
    ];
  
    return (
      <>
      <Modal 
            title={"Discussions"}
            visible={showDiscussions != null}
            closable={false}
            onOk={() => {
              setShowDiscussions(null);
            }}  
            onCancel={() => {
              if(!loading){
                setShowDiscussions(null);
              }
            }}      
          >
            <DiscussionDisplay id={showDiscussions} context={props.context}/>
          </Modal>
      {loading && <LoadingSpinner/>}
      {!loading && <div>
        <Button onClick={() => {        
        loadItems();
       }}>
        Refresh
      </Button>
        {errorMessage && <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
        />}
        {items?<Table dataSource={items} columns={columns} />: <LoadingSpinner/>}  
        </div>}      
      </>
    );
  };
  export default HistoryDisplay;
  