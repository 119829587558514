import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Avatar, Card, Table } from "antd";
import React, { useState } from "react";
import InternalControls from "../internalControls/InternalControls";

//STYLES
import "./ControlGroup.css";

function ControlGroup(props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="control-groups">
      <Card      
        title={
          <>
            <div style={{ float: "left" }}
            onClick={()=>{
              setExpanded(!expanded);
            }}>
              {/* <Avatar icon={<ArrowUpOutlined />} style={{margin:"0em .5em"}}/> */}
              <span>{`${props.controlGroupName}`}</span>
              {expanded ? <MinusCircleOutlined style={{marginLeft:16}}/> : <PlusCircleOutlined style={{marginLeft:16}}/>  }
            </div>
          </>
        }
      >
        {expanded && <InternalControls items={props.items} context={props.context} setOutletContext={props.setOutletContext}/>}
      </Card>
    </div>
  );
}

export default ControlGroup;
