import {
  RedoOutlined
} from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Space,
  Table,
  message,
  Alert,
  Button,
  Modal
} from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import WorkingPaperCrud from "../workingPaper/WorkingPaperCrud";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from "../LoadingSpinner";
import { handleError } from "../utils/general.util";
import { updateEntity } from "../utils/context-util";
import WorkingPaperEditor from "../workingPaper/editor/WorkingPaperEditor";
import InternalControlEditor from "./editor/InternalControlEditor";
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { AiOutlineHistory } from "react-icons/ai";
import HistoryDisplay from '../history/display/HistoryDisplay';
const type = 'DraggableBodyRow';

function InternalControls(props) {
  const [selectedInternalControl, setSelectedInternalControl] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedWorkingPaper, setSelectedWorkingPaper] = useState(null);
  const context = useOutletContext();
  const [data, setData] = useState(null);
  const [sortChanged, setSortChanged] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(()=>{
    if(!sortChanged){
      loadData();
    }    
  },[props.items]);
  
  useEffect(() => {
    let user = context.user;
    let currentIsAdmin = user.roles.indexOf('admin') >= 0;
    setIsAdmin(currentIsAdmin);    
  },[context]);
  
  const loadData = () => {
      let newData = props.items.map(i => {
        return {...i,key: i.id}
      }).sort((a, b) => {
        if(a.index == null || b.index == null){
          return 0;
        }
        return a.index - b.index;
      })
      setData(newData);
  }

  const onWorkingPaperClose = async (item) => {
    try{
      if(item && selected){
        let icToUpdate = selected.parent;      
        icToUpdate.workingPapers.push({id: item.id});
        await axios.post(`${context.backendURL}/internal-control`, icToUpdate);
      }      
    }
    catch(error){
        throw error;  
    }
    finally{
      if(item){
        let newContext = await updateEntity("working-paper", "workingPapers", context, props.setOutletContext);
        await updateEntity("internal-control", "internalControls", newContext, props.setOutletContext);
      }
      setSelected(null);
      setSelectedInternalControl(null);
    }
  };

  const removeWorkingPaper = (row, model) => {
    if (model && row) {
      setSelected(row);
    }
  };

  const updateWorkingPaper = (row, model) => {
    if (model && row) {
      setSelected(row);
      setSelectedWorkingPaper(model);
    }
  };

  const onInternalControlRemove = async (item) => {
    if (item) {
      try{
        await axios.delete(`${context.backendURL}/internal-control`,{data:{id:item.id}});
        await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
        message.success("Item deleted!");
      }
      catch(error){
        handleError("InternalControl",error,"deleted",setErrorMessage);    
      }
    }
  };

  const menu = (item) => {
    return (
      <Menu>
        <Menu.SubMenu key="workingPaper" title="Working Papers">
          <Menu.Item
            key={item.key}
            onClick={(row) => {
              setSelected({parent: context.internalControls.find(ic => ic.id == row.key)});
            }}
          >
            Add
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key="updateInternalControl"
          onClick={() => {
            setSelectedInternalControl(item);
          }}
        >
          Update
        </Menu.Item>
        {isAdmin && <Menu.Item key="deleteInternalControl" onClick={() => {
          onInternalControlRemove(item)
        }}>
          Delete
        </Menu.Item>}
        
      </Menu>
    );
  };  

  //Set columns to display
  const columns = [
    {
      title: "Title",
      dataIndex: "label",
      key: "label",
      render: (text, record) => record.notApplicable ? <del>{text}</del> : text,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (item, record) => (
        <span>
          {record.notApplicable ? <del><UserDisplay model={item} /></del> : <UserDisplay model={item} />}
        </span>
      ),
    },
    {
      title: "Users",
      key: "users",
      render: (item, record) => (
        record.notApplicable ? <del><UserDisplay model={item} config={{ multiple: true }} /></del> : <UserDisplay model={item} config={{ multiple: true }} />
      ),
    },
    {
      title: "Objective",
      dataIndex: "objective",
      key: "objective",
      render: (text, record) => record.notApplicable ? <del>{text}</del> : text,
    },
    {
      title: "Success",
      dataIndex: "successCriteria",
      key: "successCriteria",
      render: (text, record) => record.notApplicable ? <del>{text}</del> : text,
    },
    {
      title: "Status",
      dataIndex: "testingStatus",
      key: "testingStatus",
      render: (text, record) => record.notApplicable ? <del>{text}</del> : text,
    },
    {
      title: "Control",
      dataIndex: "controlFrequency",
      key: "controlFrequency",
      render: (frequencies, record) => {
        return record.notApplicable
          ? <del>
            {frequencies && typeof frequencies !== "string" && frequencies.length 
              ? frequencies.map(frequency => <div>{frequency}</div>)
              : <div>{frequencies}</div>}
            </del>
          : frequencies && typeof frequencies !== "string" && frequencies.length 
              ? frequencies.map(frequency => <div>{frequency}</div>)
              : <div>{frequencies}</div>
      },
    },
    {
      title: "Actions",
      render: (item, record) => (
        <Dropdown overlay={menu(item)}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {record.notApplicable ? <del><GiHamburgerMenu style={{ fontSize: "2em" }} /></del> : <GiHamburgerMenu style={{ fontSize: "2em" }} />}
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];  

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          cursor: 'move',
          ...style,
        }}
        {...restProps}
      />
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const saveSort = async () => {    
    setLoading(true);
    try{
      for(let icIndex in data){
        let icItem = data[icIndex];
        await axios.post(`${context.backendURL}/internal-control`, icItem);
      }
      setSortChanged(false);    
    }
    finally{
      await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
      setLoading(false);
    } 
  }

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
        const dragRow = data[dragIndex];
        let newData = update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }); 
        
        for(let index in newData){
          let nData = newData[index];
          nData.index = index;
        }
        setData(newData);
        await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
        setSortChanged(true);     
    },
    [data],
  );

  return (
    <>
      {errorMessage && (
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <DndProvider backend={HTML5Backend}>
          {sortChanged && <Button 
            style={{marginBottom:16, float:"left"}}
            onClick={async ()=>{
              await saveSort();
            }}>Save Sort Order</Button>}
            <Table
              bordered
              dataSource={data}
              components={components}
              onRow={(_, index) => {
                _.index = index;                
                const attr = {
                  index,
                  moveRow,
                };
                return attr;
              }}
              columns={columns}
              expandable={{
                expandedRowRender: (row) => {
                  return  <WorkingPaperCrud
                  items={row.workingPapers}
                  remove={removeWorkingPaper}
                  update={updateWorkingPaper}
                  setOutletContext={props.setOutletContext}
                />
                },
              }}
            />     
          </DndProvider>     
          <WorkingPaperEditor
            isVisible={selected != null}
            isAdmin={isAdmin}
            onClose={onWorkingPaperClose}
            errorMessage={errorMessage}
            setOutletContext={props.setOutletContext}
            item={selected}
          />
          <InternalControlEditor
            isVisible={selectedInternalControl != null}
            isAdmin={isAdmin}
            onClose={onWorkingPaperClose}
            errorMessage={errorMessage}
            setOutletContext={props.setOutletContext}
            item={selectedInternalControl}
          />
        </div>
      )}
      <Modal 
            title={"Policy History"}
            visible={showHistory}
            width={1000}
            closable={false}
            onOk={() => {
              setShowHistory(false)
            }}  
            onCancel={() => {
              if(!loading){
                setShowHistory(false);
              }
            }}      
          >
            <HistoryDisplay context={context} group={"internal control"} />
          </Modal>
    </>
  );
}

export default InternalControls;