import './BarGraph.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';
import { useOutletContext } from "react-router-dom";
import { Card } from 'antd';
import moment from 'moment';

function BarGraph(props) {
  const context = useOutletContext();
  let data = [];
  let xLabels = [];

  context.selectedFramework?.policies.forEach(p => {
    let pId = p.id ? p.id : p;
    let loadedPol = context.policies.find(pol => pol.id == pId);
    loadedPol.internalControls.forEach(ic => {
      let icId = ic.id ? ic.id : ic;
      let loadedIc = context.internalControls.find(inter => inter.id == icId);

      if(loadedIc){
        loadedIc.workingPapers.forEach(wp => {
          let wpId = wp.id ? wp.id : wp;
          let loadedWp = context.workingPapers.find(workP => workP.id == wpId);       
          if(loadedWp){
            const startDate = new Date(loadedWp.startDate);
            const endDate = new Date(loadedWp.endDate);
            data.push({
              type: loadedWp.label,
              values: [startDate, endDate]
            });
    
            // Generate X axis labels
            const startWeekOfYear = getWeekNumber(startDate);
            const endWeekOfYear = getWeekNumber(endDate);
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            for (let weekOfYear = startWeekOfYear; weekOfYear <= endWeekOfYear; weekOfYear++) {
              const year = (weekOfYear > endWeekOfYear) ? endYear : startYear;
              xLabels.push(weekOfYearToDate(weekOfYear, year));
            }
          }        
        });
      }
    });
  });

  const minDate = data.reduce((min, { values: [start] }) => {
    return start < min ? start : min;
  }, new Date());

  function weekOfYearToDate(weekOfYear, year) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(year, 0, 1);
    date.setDate(1 + (weekOfYear - 1) * 7);
    date.setDate(date.getDate() - date.getDay());
    return date;
  }

  function getWeekNumber(date) {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  }

  const config = {
    data: data.reverse(),
    xField: 'values',
    yField: 'type',
    isRange: true,
    xAxis: {
      min: minDate,
      label: {
        formatter: (date) => {
          return moment(new Date(parseInt(date))).format('D MMMM');
        }
      },
      tickInterval: 7 * 24 * 60 * 60 * 1000 // Set tick interval to one week
    },
    tooltip: {
      customContent: (title, items) => {
        const item = items[0];
        if(!item){
          return `<div>
          <strong></strong><br />
          Start Date: <br />
          End Date: 
        </div>`
        }
        const startDate = moment(item.data.values[0]).format('D MMMM');
        const endDate = moment(item.data.values[1]).format('D MMMM');
        const content = `
          <div>
            <strong>${item.name}</strong><br />
            Start Date: ${startDate}<br />
            End Date: ${endDate}
          </div>
        `;
        return content;
      },
    },
  };

  return <div>
    {context.selectedFramework && <Card title="Internal Control Timelines" className='chart-card'>
      <Bar {...config} />
    </Card>}
  </div>
};

export default BarGraph; 
