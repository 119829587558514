import "./Blank.css";

const Blank = () => {
    
  
    return (
        <div>
            <div className="blank-logo"/>
            <div className="coming-soon">This page is coming soon...</div>  
        </div>
    );
  };
  
  export default Blank;