import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { handleError } from "../../utils/general.util";
import UserAllocation from "../../users/allocation/UserAllocation";
import { Form, Input, Modal, Select, Upload, Alert, message, Checkbox } from "antd";
import {v4 as uuid} from "uuid";
import LoadingSpinner from '../../LoadingSpinner';

const { TextArea } = Input;

function InternalControlEditor(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [fileList, setFileList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const testingStatusses = ["Not Started", "Started", "Issue" , "Exception noted", "No exception noted"];
  const frequencies = ["Yearly", "Quaterly", "Weekly", "Daily"];
  const [internalControls, setInternalControls] = useState(null);
  const [frameworks, setFrameworks] = useState([]);
  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }
    let currentIsAdmin = context?.user.roles.indexOf('admin') >= 0;
    setIsAdmin(currentIsAdmin);

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

    
  const loadInternalControls = async () => {
    try{
      
    }
    catch(error){
      handleError("Internal controls",error,'loaded',setErrorMessage);
    }
  }

  const loadItems = async () => {
    try{
        loadingItems = true;
        setFrameworks(context.frameworks);     
        setInternalControls(context.internalControls);
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
    }
}
  const onClose = async (newItem) => {
    try{
      if (typeof props?.onClose == "function") {
        await props?.onClose(newItem);
      }
      if(form?.resetFields){
        form.resetFields();
      }
      setItem({});
    }
    catch(err){
      console.log(err);
    }
    finally{
      setLoading(false);
    }
  };

  const validate = (values = form.getFieldsValue()) =>   {     
    let pass = true;
    pass = values.label != null && 
    values.owner != null && 
    values.objective != null && 
    values.successCriteria != null &&
    values.auditStep != null &&
    values.description != null &&
    values.controlFrequency != null &&
    values.testingStatus != null &&
    values.reviewStatus != null;
    return pass;
  }

  const createItem = async (event) => {
    try{
      let itemResponse = await axios.post(`${context.backendURL}/internal-control`,{...event});
      onClose(JSON.parse(itemResponse.data.body));
      message.success("InternalControl Created!");
    } 
    catch(err){
      handleError("InternalControl",err,"created",setErrorMessage);
    }
}

const updateItem = async (event) => {
  try{
    let icResponse = await axios.post(`${context.backendURL}/internal-control`,{...event});
    message.success("InternalControl Updated!");
    onClose(JSON.parse(icResponse.data.body));
  } 
  catch(err){
    handleError("InternalControl",err,"updated",setErrorMessage);
  }
}

  const onSubmit = async (event) => {
    let newItem = {...event, ...item};
    setLoading(true);
    if(!validate(newItem)){            
      setErrorMessage("Please fill out all required fields");
      setLoading(false);
      return;
    }
    if(!newItem?.id){    
      newItem.id = uuid(); 
      newItem.template = props.isAdmin ? props.isAdmin : false;
      newItem.new = true;
      if(!newItem.reviewStatus){
        newItem.reviewStatus = 'Not Started';
      }
      if(!newItem.testingStatus){
        newItem.testingStatus = 'Not Started';
      }
      if(!newItem.controlFrequency || newItem.controlFrequency.length == 0){
        newItem.controlFrequency = ['Yearly'];
      }
      await createItem(newItem);
    }
    else{
      await updateItem(item);
    }
  }

  const onFinishedFailed = (value) => {};

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Modal
      bodyStyle={{
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
      visible={props.isVisible}
      onCancel={() => {
        if(!loading){
          onClose()
        }
      }}
      onOk={async () => {        
        try{
          setErrorMessage(null);
          let values = await form.validateFields();
          onSubmit(values,form);
        }
        catch(err){
          setErrorMessage(`Please fill out all fields:${err.errorFields?.map(e => e.name).join(", ")}`);
          setLoading(false);
        }
      }}
      title={
        <div>
          <h4>{item?.id ? "Edit" : "Add"} Internal Control  {props.item?.template != null ? props.item.template == true ? "Template" : "Instance" : isAdmin ? "Template" : "Instance"}</h4>
        </div>
      }
    >
    {errorMessage && <Alert
    message="Error"
    description={errorMessage}
    type="error"
    showIcon
  />}
    {loading ? <LoadingSpinner/> : <Form
        form={form}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
        label="Title"
        name="label"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
        >
        <Input disabled={!isAdmin} placeholder="Title..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
        </Form.Item>
        <Form.Item
          label="Group"
          name="group"          
        >
          <Input placeholder="Group..." onChange={(event)=>{
            if(item){
              setItem({...item,group:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserAllocation model={item?.owner} change={(newValue)=>{
            if(item){
              setItem({...item,owner:{id:newValue.id}});
              form.setFieldValue("owner",{id:newValue.id});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserAllocation model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item
          label="Objective"
          name="objective"
          rules={[
            {
              required: true,
              message: "Objective is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!isAdmin} placeholder="Objective..." onChange={(event)=>{
            if(item){
              setItem({...item,objective:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Success Criteria"
          name="successCriteria"
          rules={[
            {
              required: true,
              message: "Success Criteria is required",
            },
          ]}
        >
          <TextArea rows={4} disabled={!isAdmin} placeholder="Success Criteria..." onChange={(event)=>{
            if(item){
              setItem({...item,successCriteria:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Audit Step"
          name="auditStep"
          rules={[
            {
              required: true,
              message: "Audit Step is required",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Audit Step Criteria..." onChange={(event)=>{
            if(item){
              setItem({...item,auditStep:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <TextArea rows={4} placeholder="Description..." onChange={(event)=>{
            if(item){
              setItem({...item,description:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item label="Control Frequency" name="controlFrequency"  
          rules={[
            {
              required: true,
              message: "Control Frequency is required",
            },
          ]}>
          <Select mode="multiple" placeholder="Please select control frequency" onChange={(value)=>{
                if(item){
                  setItem({...item,controlFrequency:value});
                }
              }}
              filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
              }>
            {frequencies?.map((frequency) => {
              return (
                <Select.Option value={frequency}>{frequency}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Testing Status" name="testingStatus" initialValue={item?.testingStatus} 
          rules={[
            {
              required: true,
              message: "Testing Status is required",
            },
          ]}>
          <Select onChange={(value)=>{
                if(item){
                  setItem({...item,testingStatus:value});
                }
              }}
              filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
              }>
            {testingStatusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Review Status" name="reviewStatus" initialValue={item?.reviewStatus} 
          rules={[
            {
              required: true,
              message: "Review Status is required",
            },
          ]}>
          <Select onChange={(value)=>{
                if(item){
                  setItem({...item,reviewStatus:value});
                }
              }}
              filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase())
              }>
            {testingStatusses?.map((status) => {
              return <Select.Option value={status}>{status}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Not Applicable"
          name="notApplicable"    
        >
          <Checkbox checked={item?.notApplicable} onChange={(event)=>{
            if(item){
              setItem({...item,notApplicable:event.target.checked});
            }
            else{
              setItem({notApplicable:event.target.checked});
            }
        }}/>
        </Form.Item>
      </Form>}
    </Modal>
  );
}

export default InternalControlEditor;
