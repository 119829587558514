import React, { useState } from "react";
import axios from 'axios';
import { Button, Form, Input, Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import "./ContactUs.css"; 
import { useOutletContext } from "react-router-dom";
import { getBackendUrl } from "../../../backend-util";

const { Title } = Typography;
const { Content } = Layout;

const ContactUs = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const context = useOutletContext();

  const onFinish = async (values) => {
    setLoading(true);
    values['subject'] ='Contact Us request on LeafGRC';
    await axios.post(`${getBackendUrl()}/send-email`,values);
    setLoading(false);
    setSent(true);
    form.resetFields();
  };

  return (
    <Layout className="layout">
      <Content className="content">
        <div className="home-logo"></div>
        <Title level={2}>Contact Us</Title>
        {sent ? (
          <div className="sent-message">
            <Title level={4}>Thank you for contacting us!</Title>
            <p>We will get back to you as soon as possible.</p>            
            <Link to="/">
              <Button loading={loading} className="contact-button">
                  Back
              </Button>
            </Link>
          </div>
        ) : (
          <Form form={form} onFinish={onFinish} layout="vertical" className="contact-form">
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter your name" }]}>
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Please enter your email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="message" label="Message" rules={[{ required: true, message: "Please enter your message" }]}>
              <Input.TextArea placeholder="Enter your message" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="contact-button">
                Submit
              </Button>
              <Link to="/">
                <Button loading={loading} className="contact-button">
                    Back
                </Button>
              </Link>
            </Form.Item>
          </Form>
        )}
      </Content>
    </Layout>
  );
};

export default ContactUs;
