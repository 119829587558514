import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Select, Upload, Alert, Button, Checkbox, DatePicker } from "antd";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from '../LoadingSpinner';
import moment from "moment";

const { Dragger } = Upload;
const { TextArea } = Input;

function FrameworkDisplayDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [editing, setEditing] = useState(false);

  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
                <Form.Item
        label="Name"
        name="label"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        >
        <Input disabled={!editing} placeholder="Name..." onChange={(event)=>{
          if(item){
            setItem({...item,label:event.currentTarget.value});
          }
        }}/>
        </Form.Item>
        <Form.Item
          label="Version"
          name="version"          
        >
          <Input disabled={!editing} placeholder="Version..." onChange={(event)=>{
            if(item){
              setItem({...item,version:event.currentTarget.value});
            }
          }}/>
        </Form.Item>
        <Form.Item
          label="Start Date"
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
        >
          <DatePicker
            disabled={!editing}
            value={props.item?.startDate ? moment(props.item.startDate): null}
            onChange={(event) => {
              if (props.item) {            
                props.setItem({ ...props.item, startDate: event });
                form.setFieldValue("startDate",event.toISOString());
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="End  Date"
          rules={[
            {
              required: true,
              message: "End  Date is required",
            },
          ]}
        >
          <DatePicker
            disabled={!editing}
            value={props.item?.endDate ? moment(props.item.endDate): null}
            onChange={(event) => {
              if (props.item) {
                props.setItem({ ...props.item, endDate: event });
                form.setFieldValue("endDate", event.toISOString());
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="owner"
          rules={[
            {
              required: true,
              message: "Owner is required",
            },
          ]}
        >
        <UserDisplay model={item?.owner} />
        </Form.Item>
        <Form.Item
          label="Users"
        >
        <UserDisplay model={item} config={{ multiple: true }}/>
        </Form.Item>
        <Form.Item label="Policies" name="policies">
          <Select
            mode="multiple"
            disabled={!editing}
            placeholder="Please select internal controls to link"
            value={props.item?.policies}
            onChange={(value)=>{
              if(props.item){
                props.setItem({...props.item,policies:value});
              }
            }}
            filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}            
            filterSort={(optionA, optionB) =>
              (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
            }
          >
            {context?.policies?.filter(c => item.template == true ? c.template : true).map((control) => {
              return (
                <Select.Option value={control.id}>
                    {control.label}{control.template ? " - Template" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Risks" name="risks">
          <Select
            disabled={!editing}
            mode="multiple"
            placeholder="Please select risks to link"
            value={props.item?.risks}
            onChange={(value)=>{
              if(props.item){
                props.setItem({...props.item,risks:value});
              }
            }}
            filterOption={(input, option) => (option?.children.join("") ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.children.join("") ?? '').toLowerCase().localeCompare((optionB?.children.join("") ?? '').toLowerCase())
            }
          >
            {context?.risks?.filter(c => item.template == true ? c.template : true).map((risk) => {
              return (
                <Select.Option value={risk.id}>
                    {risk.label}{risk.template ? " - Template" : ""}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>}
    </div>

  );
}

export default FrameworkDisplayDisplay;
