import React, { useState } from 'react'
import ControlGroup from '../controlGroup/ControlGroup'
import { useOutletContext } from "react-router-dom";
import { updateEntity } from "../utils/context-util";
import { Button, Modal } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { AiOutlineHistory } from "react-icons/ai";
import LoadingSpinner from '../LoadingSpinner';
import HistoryDisplay from '../history/display/HistoryDisplay';

function Compliance(props) {
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const context = useOutletContext(); 

  const getControlGroups = (policies) => {
    policies = policies.map(p => props.context?.policies.find(f => f.id == p));
    let internalControls = policies.map(p => p?.internalControls).flat().map(ic => props.context?.internalControls.find(f => f.id == ic));

    let groups = [];
    for(let icIndex in internalControls){
      let ic = internalControls[icIndex];
      let groupName = ic?.group;
      if(!groupName){
        groupName = "NO CONTROL GROUP";
      }
      let group = {
        label: groupName,
        internalControls: ic ? [ic] : []
      }
      if(!groups.find(g => g.label == group.label)){          
        groups.push(group);
      }
      else{
        let group = groups.find(g => g.label == groupName);
        if(ic && !group.internalControls?.filter(f => f != undefined).find(g => g.id == ic.id)){   
          group.internalControls.push(ic);
        }        
      }
    }
    return groups;
  }

  if(props.context?.selectedFramework?.policies){
    let groups = getControlGroups(props.context.selectedFramework.policies);
    return <>
    {loading ? <LoadingSpinner/> : <>
      <Modal 
        title={"Internal Control History"}
        visible={showHistory}
        width={1000}
        closable={false}
        onOk={() => {
          setShowHistory(false)
        }}  
        onCancel={() => {
          if(!loading){
            setShowHistory(false);
          }
        }}      
      >
        <HistoryDisplay context={context} group={"internal control"} />
      </Modal>
      <div style={{ float: "right",}}>
        <Button
            style={{float:"right"}}
            onClick={async () => {
              setLoading(true);
              try{
                await updateEntity("internal-control", "internalControls", context, props.setOutletContext);
              }
              finally{
                setLoading(false);
              }
            }}
            type="link"
            className="control"
            size="large"
            icon={<RedoOutlined style={{ fontSize: "1.5em" }} />}
          />
          <Button
              style={{float:"right"}}
              type="link"
              className="control"
              size="large"
              icon={<AiOutlineHistory style={{ fontSize: "1.5em" }} 
              onClick={()=>{
                setShowHistory(true);
              }}/>}
          />
      </div>
      {
          groups?.map(group => {
              return <ControlGroup controlGroupName={group.label} context={props.context} items={group.internalControls} setOutletContext={props.setOutletContext}/>
          })
      }  
    </>}
        
  </>
  }

  return null
}

export default Compliance
