import { UserOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
  Tooltip,
  Avatar,
  message,
  Modal,
  Table,
  Form,
  Space,
  Select,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import "./UserAllocation.css";

const UserAllocation = (props) => {
  const context = useOutletContext();
  const [editModel, setEditModel] = useState(false);
  const [allUsers, setAllUsers] = useState(context?.users);
  const [userToLink, setUserToLink] = useState(null);
  const [userTypes, setuserType] = useState([ "Approver","Reviewer","Auditor","Tester" ]);


  useEffect(()=>{
    setFilteredItems();
  },[context]);

  const setFilteredItems = async () => {
    const isClient = context?.user.roles.indexOf('client') >= 0;
    const isBusiness = context?.user.roles.indexOf('business') >= 0;
    let users = allUsers;
    if(isClient){
      users = allUsers.filter(u => u.id == context.user.id || (u.company != null && u.company != "") && u.company == context?.user.company);
    }
    if(isBusiness){
      let clients = context.clients.filter(c => c.business == context.user.id);
      let my_users = allUsers.filter(u => (u.company != null && u.company != "")).filter(u => u.company == context.user.company && u.id != context.user.id);
      my_users = my_users ? my_users : [];
      users = [...allUsers.filter(u => clients.map(c => c.userAccount.id).includes(u.id) || u.id == context.user.id), ...my_users];
    }
    if(props.filter){
      users = props.filter(users);
    }
    setAllUsers(users);
  }
  
  const columns = [
    {
      title: "Alias",
      dataIndex: "id",
      key: "id",
      render: (value) => {
        return <div>{allUsers?.find((u) => u.id == value)?.alias}</div>;
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      render: (item) => {
        return <Button disabled={props.disabled}
        onClick={()=>{
          let currentModel = props.model;
          if(currentModel){
            switch(item.type){
              case "Approver":
                currentModel.approvers = currentModel.approvers.filter(u => u.id !== item.id);
                break;
              case "Reviewer":
                currentModel.reviewers = currentModel.reviewers.filter(u => u.id !== item.id);
                break;
              case "Auditor":
                currentModel.auditors = currentModel.auditors.filter(u => u.id !== item.id);
                break;
              case "Tester":
                currentModel.testers = currentModel.testers.filter(u => u.id !== item.id);
                break;
            }   
          }          
          if (props.change && typeof props?.change == "function")
            props.change(currentModel);
          setUserToLink({...userToLink});
        }}
        >Remove Link</Button>;
      }
    }
  ];

  const userAvatar = () => {
    let out = props.model?.id ? 
      <Tooltip title={allUsers.find(u => u.id == props.model.id)?.alias} placement="top">
            <Avatar onClick={()=>{
            setEditModel(true);
        }} style={{ backgroundColor: `#111111` }} src={allUsers.find(u => u.id == props.model.id)?.imageURL} />
        </Tooltip>: 
        <Avatar onClick={()=>{
            setEditModel(true);
        }} style={{ backgroundColor: `#111111` }} icon={<PlusCircleFilled />} />        
        return out;
  }

  const userAvatars = (users) => {
    let out = (
      <div
        onClick={() => {
          setEditModel(true);
        }}
      >
        {users && users.length == 0 && <Button>Link Users</Button>}
        <Avatar.Group maxCount={2} >
          {users?.map((u) => (
            <Tooltip title={`${allUsers.find(us => u.id == us.id)?.alias} : ${u.type}`} placement="top">
              <Avatar
                style={{ backgroundColor: `#111111` }}
                src={allUsers.find(us => u.id == us.id)?.imageURL}
                icon={<UserOutlined />}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    );
    return out;
  };
  let users = [];
  if (props.model?.approvers) {
    users = [
      ...users,
      ...props.model.approvers?.map((u) => {
        return { ...u, type: "Approver" };
      }),
    ];
  }
  if (props.model?.auditors) {
    users = [
      ...users,
      ...props.model.auditors?.map((u) => {
        return { ...u, type: "Auditor" };
      }),
    ];
  }
  if (props.model?.reviewers) {
    users = [
      ...users,
      ...props.model.reviewers?.map((u) => {
        return { ...u, type: "Reviewer" };
      }),
    ];
  }
  if (props.model?.testers) {
    users = [
      ...users,
      ...props.model.testers?.map((u) => {
        return { ...u, type: "Tester" };
      }),
    ];
  }

  return (
    <>
      {props.config?.multiple ? userAvatars(users) : userAvatar()}     
      <Modal 
        visible={editModel} 
        okText="Save"
        onOk={() => {
            message.success("User allocation updated!");
            setEditModel(false);
        }} 
        onCancel={() => {
            setEditModel(false);
        }} 
        closable={true}>
          
       {props.config?.multiple == true ? (
        <Form>
          <Form.Item label="User" style={{paddingTop:32, width:"100%"}}>
          <Select 
            value={userToLink?.id}
            disabled={props.disabled}
            onChange={(value) => {
              let newModel = allUsers?.find((u) => u.id == value);
              setUserToLink({...newModel,type:userToLink?.type});
              if (props.change && typeof props?.change == "function")
                props.change(newModel);
            }}
            key={userToLink?.id}
          >
            {allUsers?.map((u) => {
              return <Select.Option value={u.id}>{u.alias}</Select.Option>;
            })}
          </Select>
          </Form.Item>
          <Form.Item label="link as" style={{paddingTop:32, width:"100%"}}>
          <Select 
            value={userToLink?.type}
            disabled={props.disabled}
            onChange={(value) => {
            setUserToLink({...userToLink,type : value});
            }}
            key={props.model?.id}
          >
            {userTypes?.map((u) => {
              return <Select.Option value={u}>{u}</Select.Option>;
            })}
          </Select>
          </Form.Item>
          <Form.Item style={{paddingTop:32, width:"100%"}}>
          <Button disabled={!userToLink || !userToLink.type || props.disabled} onClick={async ()=>{
            let currentModel = props.model;
            if(currentModel){
              switch(userToLink.type){
                case "Approver":
                  if(!currentModel.approvers){
                    currentModel.approvers = [];
                  }
                  currentModel.approvers.push({id:userToLink.id});
                  break;
                case "Reviewer":
                  if(!currentModel.reviewers){
                    currentModel.reviewers = [];
                  }
                  currentModel.reviewers.push({id:userToLink.id});
                  break;
                case "Auditor":
                  if(!currentModel.auditors){
                    currentModel.auditors = [];
                  }
                  currentModel.auditors.push({id:userToLink.id});
                  break;
                case "Tester":
                  if(!currentModel.testers){
                    currentModel.testers = [];
                  }
                  currentModel.testers.push({id:userToLink.id});
                  break;
              }              
              if (props.change && typeof props?.change == "function")
                props.change(currentModel);
              setUserToLink(null);
            }
          }}>Add User Link</Button>
          </Form.Item>
          <Table dataSource={users} columns={columns} />
        </Form>
        
      ) : (
        <Form style={{ marginTop: 32 }}>
          <Form.Item label={props.ownerLabel? props.ownerLabel:"Owner"}>
            <Select
              value={props.model?.id}
              disabled={props.disabled}
              onChange={(value) => {
                let newModel = allUsers?.find((u) => u.id == value);
                if (props.model) {
                  props.model.id = newModel.id;
                }
                if (props.change && typeof props?.change == "function")
                  props.change(newModel);
              }}
              key={props.model?.id}
            >
              {allUsers?.map((u) => {
                return <Select.Option value={u.id}>{u.alias}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Form>
      )} 
      </Modal>  
    </>
  );
};
export default UserAllocation;
