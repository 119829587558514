import {
    Table,
    Alert,
    Input,
    Button,
    Form,
    message
  } from "antd";
  import React, { useState, useEffect, useRef } from "react";
  import axios from "axios";
  import { useOutletContext } from "react-router-dom";
  import LoadingSpinner from "../../LoadingSpinner";
  import UserDisplay from "../../users/display/UserDisplay";
  import { handleError } from "../../utils/general.util";
  import moment from "moment";
  import _ from "lodash";

  import "./DiscussionDisplay.css";
  
  const DiscussionDisplay = (props) => {
    let context = useOutletContext();
    if(!context){
      context = props.context;
    }
    const [items , setItems] = useState(null);
    const [history, setHistory] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const messageBox = useRef();

    let loadingItems = false;

    useEffect(() => {
      if(context?.accessToken){
        axios.defaults.headers.common['Authorization'] = context.accessToken;  
        if (props.id != history?.id && !loadingItems) {
          loadItems();
        }
      }
    }); 
  
    const loadItems = async (currentContext = context) => {    
      if(currentContext.backendURL){    
        try{
          setLoading(true); 
          loadingItems = true;
          let historyResponse = await axios.get(`${currentContext.backendURL}/history?id=${props.id}`);
          let historyEvent = historyResponse.data.Item;
          let items = historyEvent.discussions;
          items = _.orderBy(items, ['createDate'],['asc']);
          setItems(items);
          setHistory(historyEvent);
        } 
        catch(err){
          handleError("Discussion",err,"loaded",setErrorMessage);
        }
        finally{
          setLoading(false); 
          loadingItems = false;
        }
      }
    } 

    const columns = [
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
      {
        title: "Date",
        dataIndex: "createDate",
        key: "createDate",
        render: (item) => (
          moment(item).format("DD/MM/YYYY HH:mm:ss")
        )
      },
      {
        title: "User",
        dataIndex: "owner",
        key: "owner",
        render: (item) => {
          return <UserDisplay model={item} context={context}/>
        }
      }
    ];
  
    return (
      <>
      {!loading && items? <div>
          <Table dataSource={items} columns={columns} />
          <Form>
            <Form.Item>
              <Input ref={messageBox}/>
              <Button style={{float:"right"}}
              onClick={async () => {               
                try{
                  setLoading(true);
                  let discussion = {
                    message: messageBox.current.input.value
                  }
                  let newDiscussionResponse = await axios.post(`${context.backendURL}/discussion`,discussion);
                  let newDiscussion = newDiscussionResponse?.data?.dynamoResponse
                  if(newDiscussion){
                    let updateHistoryEvent = history;
                    updateHistoryEvent.discussions.push(newDiscussion);
                    let discussionIds = updateHistoryEvent.discussions.map(d => d.id);
                    updateHistoryEvent.discussions = discussionIds;
                    await axios.post(`${context.backendURL}/history`,updateHistoryEvent);
                    await loadItems();
                  }
                }
                catch(err){
                  message.error(err.message)
                }
                finally{
                  setLoading(false);
                }
              }}>Add Message</Button>
            </Form.Item>
          </Form>
          
        </div>: <LoadingSpinner/>}
      {errorMessage && <Alert
        message="Error"
        description={errorMessage}
        type="error"
        showIcon
      />}        
      </>
    );
  };
  export default DiscussionDisplay;
  