import React, { useEffect, useState } from "react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Form, Input, Select, Upload, Alert, Button, Checkbox, DatePicker } from "antd";
import UserDisplay from "../users/display/UserDisplay";
import LoadingSpinner from '../LoadingSpinner';

function RiskDisplay(props) {
  const [form] = Form.useForm();
  const [item,setItem] = useState({});
  const [roles,setRoles] = useState(null);
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(props.errorMessage);
  const context = useOutletContext();
  const [editing, setEditing] = useState(false);
  const [allImpacts, setAllImpacts] = useState(["High","Medium","Low"]);
  const [allStatuses, setAllStatuses] = useState(["Draft", "Reviewed", "Final", "Issue", "Up for review"]);
  const [allPriorities, setAllPriorities] = useState(["High","Medium","Low"]);
  const [allReviewFrequencies, setAllReviewFrequencies] = useState(["Yearly", "Quaterly", "Weekly", "Daily"]);
  const [allLikelihoods, setAllLikelihoods] = useState(["High","Medium","Low"]);
  const [allResponseTypes, setAllResponseType] = useState(["Accept" , "Transfer" , "Mitigate" , "Avoid"]);
  const [allRiskTolerance, setAllRiskTolerance] = useState(["Very High" , "High" , "Medium" , "Low" , "Very Low"]);
  let loadingItems = false;

  useEffect(() => { 
    if(item?.id != props.item?.id){
      setItem(props.item);
      form.setFieldsValue(props.item);
    }

    if(context?.accessToken){
      axios.defaults.headers.common['Authorization'] = context.accessToken;  
      if (!roles && !loadingItems) {
        loadItems();
      }
    }
  },[props.item]);

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  },[props.errorMessage]);

  const loadItems = async () => {
    try{
        loadingItems = true;
        setLoading(true); 
        setRoles(context.roles);
    }
    catch(err){
        console.error(err);
    }
    finally{
      loadingItems = false;
      setLoading(false);
    }
  }

  const onFinishedFailed = (value) => {};

  return (
   <div style={{width: 450, maxHeight: 450, overflow: 'auto'}}>
      {errorMessage && <Alert
      message="Error"
      description={errorMessage}
      type="error"
      showIcon
    />}
      {loading ? <LoadingSpinner/> : 
      <Form
        form={form}
        name="basic"
        onFinishFailed={onFinishedFailed}
        autoComplete="off"
        layout="vertical"
      >
                <Form.Item
          label="Name"
          name="label"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input disabled={!editing} placeholder="Name..." onChange={(event)=>{
            if(item){
              setItem({...item,label:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Description is required",
            },
          ]}
        >
          <Input disabled={!editing} placeholder="Description..." onChange={(event)=>{
            if(item){
              setItem({...item,description:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Version Number"
          name="versionNumber"
          rules={[
            {
              required: true,
              message: "Version Number is required",
            },
          ]}
        >
          <Input disabled={!editing} placeholder="Version Number..." onChange={(event)=>{
            if(item){
              setItem({...item,versionNumber:event.currentTarget.value});
            }
          }}/>
        </Form.Item>

        <Form.Item
          label="Priority"
          name="priority"
          rules={[
            {
              required: true,
              message: "Priority is required",
            },
          ]}
        >
          <Select
              disabled={!editing} 
              value={item?.priority}
              onChange={(value) => {
                if(item){
                  setItem({...item,priority:value});
                }
              }}
              key={item?.priority}
            >
              {allPriorities?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Likelihood"
          name="likelihood"
          rules={[
            {
              required: true,
              message: "Likelihood is required",
            },
          ]}
        >
          <Select
              disabled={!editing} 
              value={item?.likelihood}
              onChange={(value) => {
                if(item){
                  setItem({...item,likelihood:value});
                }
              }}
              key={item?.likelihood}
            >
              {allLikelihoods?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Impact"
          name="impact"
          rules={[
            {
              required: true,
              message: "Impact is required",
            },
          ]}
        >
          <Select
              disabled={!editing} 
              value={item?.impact}
              onChange={(value) => {
                if(item){
                  setItem({...item,impact:value});
                }
              }}
              key={item?.impact}
            >
              {allImpacts?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Response Type"
          name="responseType"
          rules={[
            {
              required: true,
              message: "Response Type is required",
            },
          ]}
        >
          <Select
              disabled={!editing} 
              value={item?.responseType}
              onChange={(value) => {
                if(item){
                  setItem({...item,responseType:value});
                }
              }}
              key={item?.responseType}
            >
              {allResponseTypes?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          
          <Form.Item
            label="Response Description"
            name="responseDescription"
            rules={[
              {
                required: true,
                message: "Response Description is required",
              },
            ]}
          >
            <Input disabled={!editing} placeholder="Response Description..." onChange={(event)=>{
              if(item){
                setItem({...item,responseDescription:event.currentTarget.value});
              }
            }}/>
          </Form.Item>
        
        <Form.Item
          label="Risk Tolerance"
          name="riskTolerance"
          rules={[
            {
              required: true,
              message: "Risk Tolerance is required",
            },
          ]}
        >
          <Select
              disabled={!editing} 
              value={item?.riskTolerance}
              onChange={(value) => {
                if(item){
                  setItem({...item,riskTolerance:value});
                }
              }}
              key={item?.riskTolerance}
            >
              {allRiskTolerance?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Status is required",
            },
          ]}
        >
          <Select
              value={item?.status}
              disabled={!editing} 
              onChange={(value) => {
                if(item){
                  setItem({...item,status:value});
                }
              }}
              key={item?.status}
            >
              {allStatuses?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>
        
        <Form.Item
          label="Review Frequency"
          name="reviewFrequency"
          rules={[
            {
              required: true,
              message: "Review Frequency is required",
            },
          ]}
        >
          <Select
              value={item?.reviewFrequency}
              disabled={!editing} 
              onChange={(value) => {
                if(item){
                  setItem({...item,reviewFrequency:value});
                }
              }}
              key={item?.reviewFrequency}
            >
              {allReviewFrequencies?.map((u) => {
                return <Select.Option value={u}>{u}</Select.Option>;
              })}
            </Select>
        </Form.Item>

        <Form.Item label="Users">
          <UserDisplay model={item} config={{multiple:true}}/>
        </Form.Item>

        <Form.Item name="owner" label="Owner">
        <UserDisplay model={item?.owner}/>
          </Form.Item> 
          
          <Form.Item
            label="Body"
            name="body"
            rules={[
              {
                required: true,
                message: "Body is required",
              },
            ]}
          >
            <Input.TextArea disabled={!editing} placeholder="Body..." onChange={(event)=>{
              if(item){
                setItem({...item,body:event.currentTarget.value});
              }
            }}/>
          </Form.Item>
      </Form>}
    </div>

  );
}

export default RiskDisplay;
