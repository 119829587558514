import React, { useEffect, useState } from "react";
import { Form, Input, Card, Button, Alert, message, Select } from "antd";
import UserPool from "../../UserPool";
import { Navigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import axios from "axios";

import { getBackendUrl } from "../../backend-util";

const backend = axios.create({
    baseURL: getBackendUrl()
});


const Signup = () => {
    const [email, setEmail] = useState(null);
    const [alias, setAlias] = useState(null);
    const [roles, setRoles] = useState([]);
    const [password, setPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [succcessMessage, setSucccessMessage] = useState(null);
    const [gotoHome, setGotoHome] = useState(false);


    const loadRoles = async () => {
        return [{label:'Client', value:'client'}];
    }

    const createUser = async (data) => {
        await backend.post(`/user`,{
            id: email,
            alias,
            email,
            userSub: data.userSub,
            roles: roles?.map(r => r.value)
        });
        message.success("User Created!");
        setSucccessMessage("User Created!");
        setGotoHome(true);
    }

    useEffect(()=>{
    },[]);

    const validate = () =>   {      
        return email != null && alias != null && roles.length != 0 && password != null;
    }

    const onSubmit = (event) => {
        if(!validate()){            
            setErrorMessage("Please fill out all required fields");
            return;
        }
        event.preventDefault();
        UserPool.signUp(email, password, [], null, (err, data) => {
            setSucccessMessage(null);
            setErrorMessage(null);
            if (err) {
                console.error(JSON.stringify(err.message, null, 2));
                if (err.message) {
                    setErrorMessage(err.message);
                }
            }
            if(data) {
                createUser(data);
            }
        })
    }

    if (gotoHome) {
        return <Navigate to="/login" />
    }

    return (
        <div>
            <Card title="Create a new account" style={{ width: 500, margin: "auto", top: 200 }}>
                {errorMessage && <Alert message={errorMessage} type="error" style={{ padding: 8, margin: "8px 0px" }} />}
                {succcessMessage && <Alert message={succcessMessage} type="success" style={{ padding: 8, margin: "8px 0px" }} />}
                <Form size="small">
                    <Form.Item label="Email">
                        <Input value={email} onChange={(event) => {
                            let value = event.target.value;
                            setEmail(value);
                        }} />
                    </Form.Item>
                    <Form.Item label="Alias">
                        <Input value={alias} onChange={(event) => {
                            let value = event.target.value;
                            setAlias(value);
                        }} />
                    </Form.Item>
                    <Form.Item label="Roles">
                        <AsyncSelect value={roles} 
                        loadOptions={loadRoles}
                        defaultOptions
                        isMulti
                        onChange={(value) => {
                            setRoles(value);
                        }} />
                    </Form.Item>
                    <Form.Item label="Password">
                        <Input.Password value={password} onChange={(event) => {
                            let value = event.target.value;
                            setPassword(value);
                        }} />
                    </Form.Item>
                    <Button className="login-button" type="primary" style={{ float: "right" }} onClick={onSubmit}>
                        Sign Up
                    </Button>
                    <Button className="login-button" style={{ float: "right" }} onClick={() => { setGotoHome(true) }}>
                        Back
                    </Button>
                </Form>
            </Card>
        </div >

    );
};

export default Signup;